import React, { Component } from "react";

import "../../App.css";

class StripeOnboardingReturn extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = async () => {
  }

  render = () => {
    if (window.ReactNativeWebView) {
      var data = {
        type: "onboarding_return",
      };
      window.ReactNativeWebView.postMessage(JSON.stringify(data));
    }

    return (
      <div className="App">
      </div>
    );
  }
}

export default StripeOnboardingReturn;
