import React, { Component } from "react";
import { Link } from 'react-router-dom';
import ClickAwayListener from "react-click-away-listener";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from "@fortawesome/free-solid-svg-icons";

import "./styles.css";

import headerLogoImage from "../../../res/images/icon-global-logo.png";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPopup: false,
    };
  }

  componentDidMount = async () => {
    await this.refresh();
  }

  refresh = async () => {
  }

  onShowMenu = () => {
    var showPopup = !this.state.showPopup;
    this.setState({
      showPopup: showPopup,
    });
  }

  onCloseMenu = () => {
    this.setState({
      showPopup: false,
    });
  }

  navigate = (to) => {
    window.open(to);
  }

  renderMobile = () => {
    return (
      <div className="mobileHeader">
        <div className="mobileHeaderLogo">
          <img src={headerLogoImage} className="mobileHeaderLogoImage"/>
        </div>
        <p className="mobileHeaderTitle">enviromates.earth</p>
        <div className="seperator"></div>
        <div className="mobileHeaderMenuButton" onClick={this.onShowMenu}>
          <FontAwesomeIcon className="mobileHeaderMenuButtonIcon" icon={faBars}/>
        </div>
        { this.state.showPopup &&
          <ClickAwayListener onClickAway={this.onCloseMenu}>
            <div className="mobileHeaderPopup">
              <div className={this.props.screen == "home" ? "mobileHeaderPopupMenuSelected" : "mobileHeaderPopupMenu"}>
                <Link className="mobileHeaderPopupMenuText" to="/">Enviromates</Link>
              </div>
              <div className={this.props.screen == "challenges" ? "mobileHeaderPopupMenuSelected" : "mobileHeaderPopupMenu"}>
                <Link className="mobileHeaderPopupMenuText" to="/Challenges">Challenges</Link>
              </div>
              <div className={this.props.screen == "advertising" ? "mobileHeaderPopupMenuSelected" : "mobileHeaderPopupMenu"}>
                <Link className="mobileHeaderPopupMenuText" to="/Advertising">Advertising</Link>
              </div>
              <div className={this.props.screen == "brands" ? "mobileHeaderPopupMenuSelected" : "mobileHeaderPopupMenu"}>
                <Link className="mobileHeaderPopupMenuText" to="/Brands">Brands</Link>
              </div>
              <div className="mobileHeaderPopupMenubrowser">
                <img src={headerLogoImage} className="mobileHeaderPopupMenubrowserLogo"/>
                <Link className="mobileHeaderPopupMenubrowserText" to="/Browser">Eco Browser</Link>
              </div>
            </div>
          </ClickAwayListener>
        }
      </div>
    );
  }

  render = () => {
    if (this.props.isMobile == true) {
      return this.renderMobile();
    }

    return (
      <div className="header">
        <img
          className="headerBackgroundImage"
          src={this.props.backgroundImage}
          style={{
            height: this.props.screenSize.height,
            position: "absolute",
            top: this.props.backgroundMarginTop == 0 ? 0 : -(this.props.backgroundMarginTop - 100),
            width: this.props.screenSize.width,
          }}
        />
        <div className="headerLogo">
          <img src={headerLogoImage} className="headerLogoImage"/>
        </div>
        <p className="headerTitle">enviromates.earth</p>
        <div className="seperator"></div>
        <div className={this.props.screen == "home" ? "headerMenuSelected" : "headerMenu"}>
          <Link className="headerMenuText" to="/">Enviromates</Link>
        </div>
        <div className={this.props.screen == "challenges" ? "headerMenuSelected" : "headerMenu"}>
          <Link className="headerMenuText" to="/Challenges">Challenges</Link>
        </div>
        <div className={this.props.screen == "advertising" ? "headerMenuSelected" : "headerMenu"}>
          <Link className="headerMenuText" to="/Advertising">Advertising</Link>
        </div>
        <div className={this.props.screen == "brands" ? "headerMenuSelected" : "headerMenu"}>
          <Link className="headerMenuText" to="/Brands">Brands</Link>
        </div>
        <div className="browser">
          <img src={headerLogoImage} className="browserLogo"/>
          <Link className="browserText" to="/Browser">Eco Browser</Link>
        </div>
      </div>
    );
  }
}

export default Header;
