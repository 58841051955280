import React, { Component } from "react";

import { Player, BigPlayButton, ControlBar } from "video-react";
import "video-react/dist/video-react.css";

import Header from "../components/header";
import Footer from "../components/footer";

import AppStoreButton from "../components/buttons/appstore";
import GoogleStoreButton from "../components/buttons/googlestore";

import "../../App.css";
import "./styles.css";

import backgroundImage from "../../res/images/img-home-background.png";
import beeLeftImage from "../../res/images/img-global-bee-left.png";
import beeRightImage from "../../res/images/img-global-bee-right.png";
import cashbackPhoneImage from "../../res/images/img-adv-cashback-phone.png";
import customersPhoneImage from "../../res/images/img-adv-customers-phone.png";
import managerPhoneImage from "../../res/images/img-adv-manager-phone.png";
import reachbackPhoneImage from "../../res/images/img-adv-reachback-phone.png";
import surveyPhoneImage from "../../res/images/img-adv-survey-phone.png";
import widgetMobileImage from "../../res/images/img-adv-widget-mobile@2x.png";
import widgetPhoneImage from "../../res/images/img-adv-widget-phone.png";
import winkbackPhoneImage from "../../res/images/img-adv-winkback-phone.png";

import { ReactComponent as BeeHappyImage } from "../../res/images/img-global-bee-happy.svg";

const VIDEO_URL = "https://firebasestorage.googleapis.com/v0/b/yamu-dev.appspot.com/o/web%2Fvideos%2FAdvertisers.mp4?alt=media&token=f3bb46f6-6a24-4364-a31c-28fb8dd8f257";

const BACKGROUND_SIZE = {
  height: 3286,
  width: 2861,
};

const SCREEN_HEIGHT = 3960;
const SCREEN_WIDTH = 1440;
const SCREEN_ASPECTRATIO = BACKGROUND_SIZE.width / BACKGROUND_SIZE.height;

const MOBILE_SCREEN_HEIGHT = 3040;
const MOBILE_SCREEN_WIDTH = 1440;
const MOBILE_SCREEN_ASPECTRATIO = MOBILE_SCREEN_WIDTH / MOBILE_SCREEN_HEIGHT;

const BACKGROUND_MARGINTOP = 0;
const VIDEO_WIDTH = 333;

class Advertising extends Component {
  constructor(props) {
    super(props);

    var screenWidth = window.innerWidth;

    this.state = {
      screenWidth: screenWidth,
    };

    this.player = null;
  }

  componentDidMount = async () => {
    window.addEventListener("resize", this.onWindowResized);

    await this.refresh();

    if (this.player != null) {
      // this.player.load();
    }
  }

  refresh = async () => {
  }

  onWindowResized = () => {
    var screenWidth = window.innerWidth;
    this.setState({
      screenWidth: screenWidth,
    });
  }

  renderMobile = () => {
    // body size
    var screenWidth = this.state.screenWidth - this.props.scrollbarWidth;
    var marginTop = BACKGROUND_MARGINTOP / (BACKGROUND_SIZE.width / this.state.screenWidth);
    var screenHeight = MOBILE_SCREEN_HEIGHT * (screenWidth / MOBILE_SCREEN_WIDTH);
    screenHeight = MOBILE_SCREEN_HEIGHT / MOBILE_SCREEN_ASPECTRATIO; //screenWidth / SCREEN_ASPECTRATIO - marginTop;

    // body margin
    // var bodyMarginHorizontal = (screenWidth - VIDEO_WIDTH * 4) / 2;

    // video width
    var videoWidth = screenWidth - 48;

    return (
      <div className="App page">
        <Header
          // backgroundImage={backgroundImage}
          // backgroundMarginTop={marginTop}
          isMobile={true}
          screen="advertising"
          screenSize={{
            height: screenWidth / SCREEN_ASPECTRATIO,
            width: screenWidth,
          }}
        />
        <div
          className="body"
          style={{
            backgroundColor: "rgb(0, 95, 176)",
            backgroundImage: `url(${backgroundImage})`,
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            height: screenHeight,
            width: screenWidth,
          }}
        >
          <div className="mobileHomeTitle">
            <div className="mobileHomeTitleText" style={{fontSize:80, lineHeight:0.7}}>Join the</div>
            <div className="mobileHomeTitleText">revolution!</div>
            <img className="mobileHomeBeeLeft" src={beeRightImage} />
            <img className="mobileHomeBeeRight" src={beeLeftImage} />
            <div className="chalMobileTitleDescription">
              <div className="chalMobileTitleDescriptionText">
                Know that all your advertising spend will be supporting eco projects that matter to your prospects and customers and increase your sales conversion to boot’ Now what’s not to like?
              </div>
            </div>
          </div>
          <AppStoreButton isMobile={true} style={{marginTop: 40}}/>
          <GoogleStoreButton isMobile={true} style={{marginTop: 15}}/>
          <div className="bodyContentLeft" style={{marginTop: 40}}>
            <Player
              autoPlay={true}
              className="bodyVideo"
              fluid={false}
              height={videoWidth / (607 / 1080)}
              muted={false}
              playsInline={true}
              ref={player => {this.player = player}}
              src={VIDEO_URL}
              width={videoWidth}
            >
              <BigPlayButton className="bodyVideoBigButton" position="center"/>
              <ControlBar autoHide={true} className="bodyVideoControlBar"/>
            </Player>
          </div>
          <div className="advMobileInfo">
            <p className="advMobileInfoTitle">Eco-cashback</p>
            <p className="advMobileInfoDescription">
              Join 30k brands rewarding their customers to crowdfund eco-projects that matter to them and stand out from the crowd in Google search, literally!
            </p>
            <div className="advMobileInfoImage">
              <div style={{flex: 1}}/>
              <img
                className="advCashbackPhoneImage"
                src={cashbackPhoneImage}
                style={{marginRight: -48}}
                width={344}
              />
            </div>
          </div>
          <div className="advMobileInfo">
            <p className="advMobileInfoTitle">Win new<br/>Customers</p>
            <p className="advMobileInfoDescription">
              Activate the free personal assitant that instantly matches your products with what customers are looking for and make the best first impression to help reduce your bounce rate and increase sales.
            </p>
            <div className="advMobileInfoImage">
              <img
                className="advCustomersPhoneImage"
                src={customersPhoneImage}
                style={{marginLeft: -110}}
                width={625}
              />
            </div>
          </div>
          <div className="advMobileInfo" style={{marginTop: 0}}>
            <p className="advMobileInfoTitle">Eco-Widget</p>
            <p className="advMobileInfoDescription">
              Wear your heart on your sleeve and activate the eco-widget to show your customer how your advertising is directly funding eco-projects.
            </p>
            <div className="advMobileInfoImage">
              <div style={{flex: 1}}/>
              <img
                className="advWidgetPhoneImage"
                src={widgetPhoneImage}
                style={{marginLeft: 0}}
                width={457}
              />
            </div>
          </div>
          <div className="advMobileInfo">
            <p className="advMobileInfoTitle">Winkback Ads</p>
            <p className="advMobileInfoDescription">
              “Reach Back“ to consumers that stray to competitors with an offer they can’t resist and say goodbye to the 80% of wasted advertising.
            </p>
            <div className="advMobileInfoImage">
              <img
                src={winkbackPhoneImage}
                style={{marginLeft: -60}}
                width={625}
              />
            </div>
          </div>
          <div className="advMobileInfo">
            <p className="advMobileInfoTitle">REachback Ads</p>
            <p className="advMobileInfoDescription">
              “Reachback” to users with pay-to-view consented advertising your customers will love you for
            </p>
            <div className="advMobileInfoImage">
              <div style={{flex: 1}}/>
              <img
                src={widgetMobileImage}
                style={{marginLeft: 90}}
                width={557}
              />
            </div>
          </div>
          <div className="advMobileInfo">
            <p className="advMobileInfoTitle">Instant Survey</p>
            <p className="advMobileInfoDescription">
              Instant poll eco-conscious consumers to answer quick burning questions and help bring the right products to market and reduce waste
            </p>
            <div className="advMobileInfoImage">
              <img
                src={surveyPhoneImage}
                style={{marginLeft: 50}}
                width={467}
              />
            </div>
          </div>
          <div className="advMobileInfo">
            <p className="advMobileInfoTitle">Ads Manager</p>
            <p className="advMobileInfoDescription">
              Full self-service advertising platform that is easy to use
            </p>
            <div className="advMobileInfoImage">
              <div style={{flex: 1}}/>
              <img
                src={managerPhoneImage}
                style={{marginLeft: 15}}
                width={405}
              />
            </div>
          </div>
          <div className="chalMobileStore" style={{marginTop: 262}}>
            <div className="chalMobileStoreTitle">
              <div className="chalMobileStoreTitleText">
                Join the revolution and help<br/>repair our planet from the<br/>grassroots up
              </div>
            </div>
            <div className="chalMobileStoreButtons">
              <AppStoreButton isMobile={true}/>
              <GoogleStoreButton isMobile={true} style={{marginLeft: 10, marginTop: 0}}/>
            </div>
          </div>
          <div className="seperator"/>
          <Footer isMobile={true}/>
        </div>
      </div>
    );
  }

  render = () => {
    if (this.state.screenWidth < 768) {
      return this.renderMobile();
    }

    // body size
    var screenWidth = this.state.screenWidth - this.props.scrollbarWidth;
    var marginTop = BACKGROUND_MARGINTOP / (BACKGROUND_SIZE.width / this.state.screenWidth);
    var screenHeight = SCREEN_HEIGHT * (screenWidth / SCREEN_WIDTH);
    screenHeight = SCREEN_HEIGHT / SCREEN_ASPECTRATIO; //screenWidth / SCREEN_ASPECTRATIO - marginTop;

    // body margin
    // var bodyMarginHorizontal = (screenWidth - VIDEO_WIDTH * 4) / 2;

    return (
      <div className="App page">
        <Header
          // backgroundImage={backgroundImage}
          // backgroundMarginTop={marginTop}
          screen="advertising"
          screenSize={{
            height: screenWidth / SCREEN_ASPECTRATIO,
            width: screenWidth,
          }}
        />
        <div
          className="body"
          style={{
            backgroundColor: "rgb(0, 95, 176)",
            backgroundImage: `url(${backgroundImage})`,
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            height: screenHeight,
            width: screenWidth,
          }}
        >
          <div className="bodyContent">
            <div className="bodyContentLeft">
              <Player
                autoPlay={true}
                className="bodyVideo"
                fluid={false}
                height={598}
                muted={false}
                playsInline={true}
                src={VIDEO_URL}
                width={598 * (607 / 1080)}
              >
                <BigPlayButton className="bodyVideoBigButton" position="center"/>
                <ControlBar autoHide={true} className="bodyVideoControlBar"/>
              </Player>
            </div>
            <div
              className="bodyStore"
              style={{
                marginLeft: 30,
                marginTop: 50,
              }}
            >
              <div className="bodyStoreTitle">
                <p className="bodyStoreTitleText">Join the</p>
                <p className="bodyStoreTitleBottomText">revolution!</p>
                <img
                  className="bodyStoreTitleBeeRight"
                  src={beeRightImage}
                  style={{
                    marginLeft: -554,
                    marginTop: -220,
                  }}
                />
                <img
                  className="bodyStoreTitleBeeLeft"
                  src={beeLeftImage}
                  style={{
                    marginLeft: 684,
                    marginTop: 10,
                  }}
                />
              </div>
              <div
                className="bodyStoreContent"
                style={{
                  flexDirection: "column",
                  marginTop: 10,
                }}
              >
                <div className="bodyStoreContentDescription">
                  <p className="bodyStoreContentDescriptionText">Know that all your advertising spend will be supporting eco projects that</p>
                  <p className="bodyStoreContentDescriptionText">matter to your prospects and customers and increase your sales</p>
                  <p className="bodyStoreContentDescriptionText">conversion to boot’ Now what’s not to like?</p>
                </div>
                <div
                  className="bodyStoreContentButtons"
                  style={{
                    flexDirection: "row",
                    marginTop: 55,
                  }}
                >
                  <AppStoreButton/>
                  <GoogleStoreButton style={{marginLeft: 20, marginTop: 0}}/>
                </div>
              </div>
            </div>
          </div>
          <div className="advCashback">
            <div style={{flex:0.6}}/>
            <div
              style={{
                display: "flex",
                flex: 0.7,
                flexDirection: "row",
                marginLeft: -this.state.screenWidth / 10,
              }}
            >
              <div className="advCashbackContent">
                <p className="advCashbackTitle">Eco-cashback</p>
                <p className="advCashbackDescription">
                  Join 30k brands rewarding their customers to crowdfund eco-projects that matter to them and stand out from the crowd in Google search, literally!
                </p>
              </div>
              <img
                className="advCashbackPhoneImage"
                src={cashbackPhoneImage}
                height={594}
              />
            </div>
          </div>
          <div className="advCustomers">
            <div
              style={{
                display: "flex",
                flex: 0.7,
                flexDirection: "row",
                justifyContent: "flex-end",
                marginRight: -this.state.screenWidth / 10,
              }}
            >
              <div className="advCustomersPhone">
                <img
                  className="advCustomersPhoneImage"
                  src={customersPhoneImage}
                  height={605}
                />
              </div>
              <div className="advCustomersContent">
                <p className="advCashbackTitle">Win new<br/>Customers</p>
                <p className="advCashbackDescription">
                  Activate the free personal assitant that instantly matches your products with what customers are looking for and make the best first impression to help reduce your bounce rate and increase sales.
                </p>
              </div>
            </div>
            <div style={{flex:0.6}}/>
          </div>
          <div className="advWidget">
            <div style={{flex:0.6}}/>
            <div
              style={{
                display: "flex",
                flex: 0.7,
                flexDirection: "row",
                marginLeft: -this.state.screenWidth / 10,
              }}
            >
              <div className="advWidgetContent">
                <p className="advCashbackTitle">Eco-Widget</p>
                <p className="advCashbackDescription">
                  Wear your heart on your sleeve and activate the eco-widget to show your customer how your advertising is directly funding eco-projects.
                </p>
              </div>
              <img
                className="advWidgetPhoneImage"
                src={widgetPhoneImage}
                height={600}
              />
            </div>
          </div>
          <div className="advWinkback">
            <div
              style={{
                display: "flex",
                flex: 0.7,
                flexDirection: "row",
                justifyContent: "flex-end",
                marginRight: -this.state.screenWidth / 10,
              }}
            >
              <div className="advWinkbackPhone">
                <img
                  className="advWinkbackPhoneImage"
                  src={winkbackPhoneImage}
                  height={605}
                />
              </div>
              <div className="advWinkbackContent">
                <p className="advCashbackTitle">Winkback Ads</p>
                <p className="advCashbackDescription">
                  “Reach Back“ to consumers that stray to competitors with an offer they can’t resist and say goodbye to the 80% of wasted advertising.
                </p>
              </div>
            </div>
            <div style={{flex:0.6}}/>
          </div>
          <div className="advReachback">
            <div style={{flex:0.6}}/>
            <div
              style={{
                display: "flex",
                flex: 0.7,
                flexDirection: "row",
                marginLeft: -this.state.screenWidth / 10,
              }}
            >
              <div className="advReachbackContent">
                <p className="advCashbackTitle">REachback Ads</p>
                <p className="advCashbackDescription">
                  “Reachback” to users with pay-to-view consented advertising your customers will love you for
                </p>
              </div>
              <img
                className="advReachbackPhoneImage"
                src={reachbackPhoneImage}
                height={600}
              />
            </div>
          </div>
          <div className="advSurvey">
            <div
              style={{
                display: "flex",
                flex: 0.7,
                flexDirection: "row",
                justifyContent: "flex-end",
                marginRight: -this.state.screenWidth / 10,
              }}
            >
              <div className="advSurveyPhone">
                <img
                  className="advSurveyPhoneImage"
                  src={surveyPhoneImage}
                  height={605}
                />
              </div>
              <div className="advSurveyContent">
                <p className="advCashbackTitle">Instant Survey</p>
                <p className="advCashbackDescription">
                  Instant poll eco-conscious consumers to answer quick burning questions and help bring the right products to market and reduce waste
                </p>
              </div>
            </div>
            <div style={{flex:0.6}}/>
          </div>
          <div className="advManager">
            <div style={{flex:0.6}}/>
            <div
              style={{
                display: "flex",
                flex: 0.7,
                flexDirection: "row",
                marginLeft: -this.state.screenWidth / 10,
              }}
            >
              <div className="advManagerContent">
                <p className="advCashbackTitle">Ads Manager</p>
                <p className="advCashbackDescription">
                  Full self-service advertising platform that is easy to use
                </p>
              </div>
              <img
                className="advManagerPhoneImage"
                src={managerPhoneImage}
                height={600}
              />
            </div>
          </div>
          <div className="advStore">
            <BeeHappyImage className="advStoreBee"/>
            <div className="advStoreTitle">
              <div className="advStoreTitleText">Join the revolution and help</div>
              <div className="advStoreTitleText">repair our planet from the</div>
              <div className="advStoreTitleText">grassroots up</div>
            </div>
            <div className="advStoreButtons">
              <AppStoreButton/>
              <GoogleStoreButton style={{marginLeft: 20, marginTop: 0}}/>
            </div>
          </div>
          <div className="seperator"/>
          <Footer/>
        </div>
      </div>
    );
  }
}

export default Advertising;
