import React, { Component } from "react";

import "video-react/dist/video-react.css";

import Header from "../components/header";
import Footer from "../components/footer";

import AppStoreButton from "../components/buttons/appstore";
import GoogleStoreButton from "../components/buttons/googlestore";

import "../../App.css";

import backgroundImage from "../../res/images/img-home-background.png";

const BACKGROUND_SIZE = {
  height: 7800,
  width: 2861,
};

const SCREEN_HEIGHT = 1920;
const SCREEN_WIDTH = 1440;
const SCREEN_ASPECTRATIO = BACKGROUND_SIZE.width / BACKGROUND_SIZE.height;

const MOBILE_SCREEN_HEIGHT = 2950;
const MOBILE_SCREEN_WIDTH = 1440;
const MOBILE_SCREEN_ASPECTRATIO = MOBILE_SCREEN_WIDTH / MOBILE_SCREEN_HEIGHT;

const BACKGROUND_MARGINTOP = 0;
const VIDEO_WIDTH = 333;

class AdTerms extends Component {
  constructor(props) {
    super(props);

    var screenWidth = window.innerWidth;

    this.state = {
      screenWidth: screenWidth,
      participants: [],
      availableCount: 0,
    };
  }

  componentDidMount = async () => {
    window.addEventListener("resize", this.onWindowResized);
    await this.refresh();
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.onWindowResized);
  }

  refresh = async () => {
  }

  onWindowResized = () => {
    var screenWidth = window.innerWidth;
    this.setState({
      screenWidth: screenWidth,
    });
  }

  renderMobile = () => {
    // body size
    var screenWidth = this.state.screenWidth - this.props.scrollbarWidth;
    var marginTop = BACKGROUND_MARGINTOP / (BACKGROUND_SIZE.width / this.state.screenWidth);
    var screenHeight = MOBILE_SCREEN_HEIGHT * (screenWidth / MOBILE_SCREEN_WIDTH);
    screenHeight = MOBILE_SCREEN_HEIGHT / MOBILE_SCREEN_ASPECTRATIO; //screenWidth / SCREEN_ASPECTRATIO - marginTop;

    // body margin
    var bodyMarginHorizontal = (screenWidth - VIDEO_WIDTH * 4) / 2;

    // participants width
    var contentWidth = screenWidth - 20;

    var availableCount = Math.floor((contentWidth + 10) / 42);
    var participantCount = availableCount - 1 + 8;
    var morePariticipantCount = participantCount - availableCount + 1;

    var participants = [];
    for (var i = 0; i < availableCount - 1; i++) {
      participants.push({
        name: "",
      });
    }

    return (
      <div className="App page">
        <Header
          //backgroundImage={backgroundImage}
          //backgroundMarginTop={marginTop}
          isMobile={true}
          screen="terms"
          screenSize={{
            height: screenWidth / SCREEN_ASPECTRATIO,
            width: screenWidth,
          }}
        />
        <div
          className="body"
          style={{
            backgroundColor: "rgb(0, 95, 176)",
            backgroundImage:`url(${backgroundImage})`,
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            height: screenHeight,
            width: screenWidth,
          }}
        >
          <div className="termsMobileBody">
            <div className="termsMobileTitle">Advertising Terms & Conditions</div>
            <div className="termsMobileDescription">
              Last updated [8th December 2022]
            </div>
            <hr className="hr"/>
            <div className="termsMobileSubTitle">1.	Application of these Terms  and Conditions</div>
            <div className="termsMobileDescription">
              All advertisements accepted by Enviromates are subject to these terms and conditions, which shall supersede all other terms and conditions. Unless expressly agreed to by an authorized Enviromates representative, any alteration to these terms and conditions and any statements about the Advertisement shall be null and void.
            </div>
            <div className="termsMobileDescription">
              Ads will go live as soon as the campaign is launched. Enviromates can at its discretion remove any ad.
            </div>
            <div className="termsMobileDescription">
              Enviromates is not required to violate any applicable laws or regulations, regardless of any other provision in these terms and conditions. We comply with data protection legislation such as the Data Protection Act of 1998 and the General Data Protection Regulation, which regulates the processing of data and ensures that your data is processed fairly and lawfully, is kept secure, and that only the data required for processing is retained. You can view our privacy statement at:
            </div>
            <div className="termsMobileDescription">
              <a href="http://www.enviromates.earth/privacy">http://www.enviromates.earth/privacy</a>
            </div>
            <div className="termsMobileDescription">
              This information is not intended for distribution to or use by any individual or organization in any jurisdiction or country where such distribution or use would be contrary to law or regulation or would subject us to any registration obligation within such jurisdiction or country. Those who access the Site from other locations do so voluntarily and are solely responsible for compliance with local laws, if and to the extent local laws are relevant.
            </div>
            <div className="termsMobileSubTitle">2.	The Advertiser’s Obligations</div>
            <div className="termsMobileDescription">
              The Advertiser warrants, represents, and agrees to Enviromates in this document that:
            </div>
            <div className="termsMobileDescription"></div>
            <ol className="mobileOl" type="a">
              <li>Regarding all Advertisements, Advertiser contracts with Enviromates as principal, regardless of whether Advertiser is acting directly or indirectly on behalf of Advertiser or in any other representative position;</li>
              <li>If the Advertiser changes its name, trading style, identity, or address, or if any other information supplied to Enviromates by the Advertiser changes, the Advertiser shall update their details through the app;</li>
              <li>the Advertisement will not violate any contract with a third party, infringe any copyright, trade mark, or other proprietary right of any third party, or otherwise be unlawful or render Enviromates liable for any proceedings, claims, demands, costs or expenses.</li>
              <li>in case any Advertisement contains the name or pictorial representation, whether photographic or otherwise, of any living person or any part of the anatomy of any living person or any material by which any living person may be identified, the Advertiser shall have obtained the permission of that living person to use his or her name, identity, image, representation, and/or copy;</li>
              <li>in relation to any financial promotion (as defined by the Financial Services and Markets Act 2000), the Advertiser is an authorised person within the meaning of the Act, or its content has been approved by an authorised person, or the Advertisement is otherwise permitted under the Act, the Financial Promotion Order 2001, or any other legislation subordinate to the Act;</li>
              <li>The Advertisement complies with all relevant legislation (including subordinate legislation, the norms of statutorily recognised regulatory authorities, and the law of the European Economic Community) and applicable laws now in force or applicable in the United Kingdom;</li>
              <li>All advertising is lawful, decent, honest, and truthful, and complies with the British Code of Advertising, Sales Promotion, and Direct Marketing, as well as all other applicable regulations governed by the Advertising Standards Authority;</li>
              <li>the Advertiser has authority to place the advertisement with Enviromates, and the Advertiser will indemnify Enviromates against any claim made by the Advertiser arising from the advertisement.</li>
            </ol>
            <div className="termsMobileSubTitle">3.	Advertisements</div>
            <div className="termsMobileDescription">
              Enviromates shall have the right, notwithstanding Condition 2 above, to request the Advertiser to modify any artwork, materials, and copy for and relating to any Advertisement, or to take down (without notice) any Advertisement for the following reasons:
            </div>
            <div className="termsMobileDescription"></div>
            <ol className="mobileOl" type="a">
              <li>conforming to any legal or ethical duties placed on Enviromates, or the Advertiser; or</li>
              <li>avoiding the violation of the rights of any third party, the British Code of Advertising, Sales Promotion and Direct Marketing, and all other pertinent codes supervised by the Advertising Standards Authority, or any other applicable law; or</li>
              <li>conforming to Advertiser's production and quality criteria to Enviromates' reasonable satisfaction.</li>
            </ol>
            <div className="termsMobileDescription">
              Enviromates reserves the right, at its sole discretion and without prior warning, to omit, edit, suspend, or change the position of any Advertisement
            </div>
            <div className="termsMobileDescription">
              Enviromates retains ownership of all copyright and other rights of a similar character established or existing in content originating from Enviromates in connection with the posting of the Advertisement.
            </div>
            <div className="termsMobileDescription">
              If an Ad links to a different website, the Advertiser is responsible for maintaining the link and for the content of the linked page. Enviromates reserves the right to remove any Advertisement that, in its sole judgement, contains content or links to a website that is (or is likely to be) defamatory, offensive, or otherwise likely to bring Enviromates into discredit. The Advertiser will compensate Enviromates for any claims or responsibility caused or incurred by Enviromates as a result of links mentioned in an advertisement.
            </div>
            <div className="termsMobileDescription">
              If Enviromates receives complaints regarding the content of an Advertisement, it may, at its sole discretion, remove the Advertisement from view, without reference to the Advertiser or Advertiser and without liability to any party.
            </div>
            <div className="termsMobileDescription">
              The provisions contained in this section apply in addition to (unless where the context expressly permits) and without prejudice to all other provisions contained in these terms and conditions.
            </div>
            <div className="termsMobileSubTitle">4.	Payment Terms</div>
            <div className="termsMobileDescription">
              Enviromates will take a 25% commission of the advertising revenue.
            </div>
            <div className="termsMobileSubTitle">5.	Positioning</div>
            <div className="termsMobileDescription">
              Except as otherwise expressly provided, positioning of advertisements is at the sole discretion of Enviromates, Advertiser acknowledges that Enviromates  has not made any guarantees with respect to usage, statistics, or levels of impressions for any advertising.
            </div>
            <div className="termsMobileSubTitle">6.	Advertisements: Responsibility and Liability</div>
            <div className="termsMobileDescription">
              The Advertiser shall indemnify and keep Enviromates indemnified against any proceedings, claims, demands, damages, costs, expenses, or other loss arising directly or reasonably foreseeable from the Advertisement or any breach of the Advertiser's obligations under these terms and conditions or implied by law.
            </div>
            <div className="termsMobileDescription">
              Regarding the actual or proposed placement of an Advertisement, Enviromates shall not be responsible or accountable to the Advertiser for:
            </div>
            <div className="termsMobileDescription"></div>
            <ol className="mobileOl" type="a">
              <li>a.	verifying the Advertisement for accuracy in the form it is received from the Advertiser;</li>
              <li>b.	any inaccuracy in the Ad as it is received by the Advertiser;</li>
              <li>c.	the text, portrayal, placement, or quality of color or monochrome reproduction of the Ad;</li>
              <li>d.	actual placement or prominence of the Advertisement;</li>
              <li>e.	failure, corruption, or malfunction of any electronic publication system, whether using electronic storage, display, or retrieval equipment or otherwise;</li>
              <li>f.	the payment of any damages or other compensation for breach of contract because of Enviromates' failure to perform any of its obligations under these terms and conditions if such failure is caused by something beyond Enviromates' reasonable control (that is, as a result of force majeure), including acts or threats of terrorism, strikes, lock-outs or other industrial actions or trade disputes, pandemic, epidemic or other widespread illness whether involving Enviromates or a third party.</li>
              <li>g.	any matter of complaint, claim, or query (whether relating to the Advertisement) unless raised  with Enviromates within __ Working Days following the posting of the Advertisement; and</li>
              <li>h.	any failure of the Ad to meet or produce the desired response.</li>
            </ol>
            <div className="termsMobileDescription">
              Enviromates' total liability to the Advertiser for any act or omission of Enviromates, its employees or agents relating to any Advertisement shall not exceed the full refund of any price paid to Enviromates for the Advertisement or the cost of a reasonably comparable additional or corrective Advertisement. Enviromates shall not be liable for any loss of profits, goodwill, or business, or for any indirect or consequential damages.
            </div>
            <div className="termsMobileSubTitle">7.	Cancellation & Termination</div>
            <div className="termsMobileDescription">
              Enviromates shall not be bound by any request from the Advertiser to stop, cancel or suspend an Advertisement. The advertiser has full control over its advertisements through app and can amend, pause or delete campaigns.
            </div>
            <div className="termsMobileSubTitle">8.	Governing Law</div>
            <div className="termsMobileDescription">
              These terms and conditions  shall be governed by and construed according to  English Law and the parties submit to the exclusive jurisdiction of the English  courts.
            </div>
            <div className="termsMobileSubTitle">9.	Severance</div>
            <div className="termsMobileDescription">
              If any provision of these terms and  conditions is or becomes invalid, illegal or void, that shall not affect the  validity and legality of the other provisions.
            </div>
            <div className="termsMobileSubTitle">10.	Waiver</div>
            <div className="termsMobileDescription">
              No failure or delay by Enviromates to exercise any  right or remedy provided under these terms and conditions or by law shall  constitute a waiver of that or any other right or remedy, nor shall it preclude  or restrict the further exercise of that or any other right or remedy. No  single or partial exercise of such right or remedy shall preclude or restrict  the further exercise of that or any other right or remedy.
            </div>
            <div className="termsMobileSubTitle">11.	Third Parties</div>
            <div className="termsMobileDescription">
              None of the provisions of these  terms and conditions are intended to confer a benefit on or be enforceable by  any third party under the Contracts (Rights of Third Parties) Act 1999 or  otherwise.
            </div>
          </div>
          <div className="termsMobileStore" style={{marginTop: 262}}>
            <div className="termsMobileStoreTitle">
              <div className="termsMobileStoreTitleText">
                Join the revolution and help<br/>repair our planet from the<br/>grassroots up
              </div>
            </div>
            <div className="termsMobileStoreButtons">
              <AppStoreButton isMobile={true}/>
              <GoogleStoreButton isMobile={true} style={{marginLeft: 10, marginTop: 0}}/>
            </div>
          </div>
          <div className="seperator"/>
          <Footer isMobile={true}/>
        </div>
      </div>
    );
  }

  render = () => {
    if (this.state.screenWidth < 768) {
      return this.renderMobile();
    }

    // body size
    var screenWidth = this.state.screenWidth - this.props.scrollbarWidth;
    var marginTop = BACKGROUND_MARGINTOP / (BACKGROUND_SIZE.width / this.state.screenWidth);
    var screenHeight = SCREEN_HEIGHT * (screenWidth / SCREEN_WIDTH);
    screenHeight = SCREEN_HEIGHT / SCREEN_ASPECTRATIO; //screenWidth / SCREEN_ASPECTRATIO - marginTop;

    // body margin
    var bodyMarginHorizontal = (screenWidth - VIDEO_WIDTH * 4) / 2;

    // participants width
    var contentWidth = (screenWidth - 35 * 2);
    if (contentWidth > 534 + 801) {
      contentWidth = 534 + 801;
    }
    contentWidth -= 142;

    var availableCount = Math.floor((contentWidth + 10) / 50);
    var participantCount = availableCount - 1 + 8;
    var morePariticipantCount = participantCount - availableCount + 1;
    var participants = [];
    for (var i = 0; i < availableCount - 1; i++) {
      participants.push({
        name: "",
      });
    }

    return (
      <div className="App page">
        <Header
          //backgroundImage={backgroundImage}
          //backgroundMarginTop={marginTop}
          screen="terms"
          screenSize={{
            height: screenWidth / SCREEN_ASPECTRATIO,
            width: screenWidth,
          }}
        />
        <div
          className="body"
          style={{
            backgroundColor: "rgb(0, 95, 176)",
            backgroundImage:`url(${backgroundImage})`,
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            height: screenHeight,
            width: screenWidth,
          }}
        >
          <div className="termsTitle">Advertising Terms & Conditions</div>
          <div className="termsBody">
            <div className="termsContent">
              <div className="termsContentBody">
                <div className="termsTextDescription">
                  Last updated [8th December 2022]
                </div>
                <hr className="hr"/>
                <div className="termsTextTitle">1.	Application of these Terms  and Conditions</div>
                <div className="termsTextDescription">
                  All advertisements accepted by Enviromates are subject to these terms and conditions, which shall supersede all other terms and conditions. Unless expressly agreed to by an authorized Enviromates representative, any alteration to these terms and conditions and any statements about the Advertisement shall be null and void.
                </div>
                <div className="termsTextDescription">
                  Ads will go live as soon as the campaign is launched. Enviromates can at its discretion remove any ad.
                </div>
                <div className="termsTextDescription">
                  Enviromates is not required to violate any applicable laws or regulations, regardless of any other provision in these terms and conditions. We comply with data protection legislation such as the Data Protection Act of 1998 and the General Data Protection Regulation, which regulates the processing of data and ensures that your data is processed fairly and lawfully, is kept secure, and that only the data required for processing is retained. You can view our privacy statement at:
                </div>
                <div className="termsTextDescription">
                  <a href="http://www.enviromates.earth/privacy">http://www.enviromates.earth/privacy</a>
                </div>
                <div className="termsTextDescription">
                  This information is not intended for distribution to or use by any individual or organization in any jurisdiction or country where such distribution or use would be contrary to law or regulation or would subject us to any registration obligation within such jurisdiction or country. Those who access the Site from other locations do so voluntarily and are solely responsible for compliance with local laws, if and to the extent local laws are relevant.
                </div>
                <div className="termsTextTitle">2.	The Advertiser’s Obligations</div>
                <div className="termsTextDescription">
                  The Advertiser warrants, represents, and agrees to Enviromates in this document that:
                </div>
                <div className="termsTextDescription"></div>
                <ol className="ol" type="a">
                  <li>Regarding all Advertisements, Advertiser contracts with Enviromates as principal, regardless of whether Advertiser is acting directly or indirectly on behalf of Advertiser or in any other representative position;</li>
                  <li>If the Advertiser changes its name, trading style, identity, or address, or if any other information supplied to Enviromates by the Advertiser changes, the Advertiser shall update their details through the app;</li>
                  <li>the Advertisement will not violate any contract with a third party, infringe any copyright, trade mark, or other proprietary right of any third party, or otherwise be unlawful or render Enviromates liable for any proceedings, claims, demands, costs or expenses.</li>
                  <li>in case any Advertisement contains the name or pictorial representation, whether photographic or otherwise, of any living person or any part of the anatomy of any living person or any material by which any living person may be identified, the Advertiser shall have obtained the permission of that living person to use his or her name, identity, image, representation, and/or copy;</li>
                  <li>in relation to any financial promotion (as defined by the Financial Services and Markets Act 2000), the Advertiser is an authorised person within the meaning of the Act, or its content has been approved by an authorised person, or the Advertisement is otherwise permitted under the Act, the Financial Promotion Order 2001, or any other legislation subordinate to the Act;</li>
                  <li>The Advertisement complies with all relevant legislation (including subordinate legislation, the norms of statutorily recognised regulatory authorities, and the law of the European Economic Community) and applicable laws now in force or applicable in the United Kingdom;</li>
                  <li>All advertising is lawful, decent, honest, and truthful, and complies with the British Code of Advertising, Sales Promotion, and Direct Marketing, as well as all other applicable regulations governed by the Advertising Standards Authority;</li>
                  <li>the Advertiser has authority to place the advertisement with Enviromates, and the Advertiser will indemnify Enviromates against any claim made by the Advertiser arising from the advertisement.</li>
                </ol>
                <div className="termsTextTitle">3.	Advertisements</div>
                <div className="termsTextDescription">
                  Enviromates shall have the right, notwithstanding Condition 2 above, to request the Advertiser to modify any artwork, materials, and copy for and relating to any Advertisement, or to take down (without notice) any Advertisement for the following reasons:
                </div>
                <div className="termsTextDescription"></div>
                <ol className="ol" type="a">
                  <li>conforming to any legal or ethical duties placed on Enviromates, or the Advertiser; or</li>
                  <li>avoiding the violation of the rights of any third party, the British Code of Advertising, Sales Promotion and Direct Marketing, and all other pertinent codes supervised by the Advertising Standards Authority, or any other applicable law; or</li>
                  <li>conforming to Advertiser's production and quality criteria to Enviromates' reasonable satisfaction.</li>
                </ol>
                <div className="termsTextDescription">
                  Enviromates reserves the right, at its sole discretion and without prior warning, to omit, edit, suspend, or change the position of any Advertisement
                </div>
                <div className="termsTextDescription">
                  Enviromates retains ownership of all copyright and other rights of a similar character established or existing in content originating from Enviromates in connection with the posting of the Advertisement.
                </div>
                <div className="termsTextDescription">
                  If an Ad links to a different website, the Advertiser is responsible for maintaining the link and for the content of the linked page. Enviromates reserves the right to remove any Advertisement that, in its sole judgement, contains content or links to a website that is (or is likely to be) defamatory, offensive, or otherwise likely to bring Enviromates into discredit. The Advertiser will compensate Enviromates for any claims or responsibility caused or incurred by Enviromates as a result of links mentioned in an advertisement.
                </div>
                <div className="termsTextDescription">
                  If Enviromates receives complaints regarding the content of an Advertisement, it may, at its sole discretion, remove the Advertisement from view, without reference to the Advertiser or Advertiser and without liability to any party.
                </div>
                <div className="termsTextDescription">
                  The provisions contained in this section apply in addition to (unless where the context expressly permits) and without prejudice to all other provisions contained in these terms and conditions.
                </div>
                <div className="termsTextTitle">4.	Payment Terms</div>
                <div className="termsTextDescription">
                  Enviromates will take a 25% commission of the advertising revenue.
                </div>
                <div className="termsTextTitle">5.	Positioning</div>
                <div className="termsTextDescription">
                  Except as otherwise expressly provided, positioning of advertisements is at the sole discretion of Enviromates, Advertiser acknowledges that Enviromates  has not made any guarantees with respect to usage, statistics, or levels of impressions for any advertising.
                </div>
                <div className="termsTextTitle">6.	Advertisements: Responsibility and Liability</div>
                <div className="termsTextDescription">
                  The Advertiser shall indemnify and keep Enviromates indemnified against any proceedings, claims, demands, damages, costs, expenses, or other loss arising directly or reasonably foreseeable from the Advertisement or any breach of the Advertiser's obligations under these terms and conditions or implied by law.
                </div>
                <div className="termsTextDescription">
                  Regarding the actual or proposed placement of an Advertisement, Enviromates shall not be responsible or accountable to the Advertiser for:
                </div>
                <div className="termsTextDescription"></div>
                <ol className="ol" type="a">
                  <li>a.	verifying the Advertisement for accuracy in the form it is received from the Advertiser;</li>
                  <li>b.	any inaccuracy in the Ad as it is received by the Advertiser;</li>
                  <li>c.	the text, portrayal, placement, or quality of color or monochrome reproduction of the Ad;</li>
                  <li>d.	actual placement or prominence of the Advertisement;</li>
                  <li>e.	failure, corruption, or malfunction of any electronic publication system, whether using electronic storage, display, or retrieval equipment or otherwise;</li>
                  <li>f.	the payment of any damages or other compensation for breach of contract because of Enviromates' failure to perform any of its obligations under these terms and conditions if such failure is caused by something beyond Enviromates' reasonable control (that is, as a result of force majeure), including acts or threats of terrorism, strikes, lock-outs or other industrial actions or trade disputes, pandemic, epidemic or other widespread illness whether involving Enviromates or a third party.</li>
                  <li>g.	any matter of complaint, claim, or query (whether relating to the Advertisement) unless raised  with Enviromates within __ Working Days following the posting of the Advertisement; and</li>
                  <li>h.	any failure of the Ad to meet or produce the desired response.</li>
                </ol>
                <div className="termsTextDescription">
                  Enviromates' total liability to the Advertiser for any act or omission of Enviromates, its employees or agents relating to any Advertisement shall not exceed the full refund of any price paid to Enviromates for the Advertisement or the cost of a reasonably comparable additional or corrective Advertisement. Enviromates shall not be liable for any loss of profits, goodwill, or business, or for any indirect or consequential damages.
                </div>
                <div className="termsTextTitle">7.	Cancellation & Termination</div>
                <div className="termsTextDescription">
                  Enviromates shall not be bound by any request from the Advertiser to stop, cancel or suspend an Advertisement. The advertiser has full control over its advertisements through app and can amend, pause or delete campaigns.
                </div>
                <div className="termsTextTitle">8.	Governing Law</div>
                <div className="termsTextDescription">
                  These terms and conditions  shall be governed by and construed according to  English Law and the parties submit to the exclusive jurisdiction of the English  courts.
                </div>
                <div className="termsTextTitle">9.	Severance</div>
                <div className="termsTextDescription">
                  If any provision of these terms and  conditions is or becomes invalid, illegal or void, that shall not affect the  validity and legality of the other provisions.
                </div>
                <div className="termsTextTitle">10.	Waiver</div>
                <div className="termsTextDescription">
                  No failure or delay by Enviromates to exercise any  right or remedy provided under these terms and conditions or by law shall  constitute a waiver of that or any other right or remedy, nor shall it preclude  or restrict the further exercise of that or any other right or remedy. No  single or partial exercise of such right or remedy shall preclude or restrict  the further exercise of that or any other right or remedy.
                </div>
                <div className="termsTextTitle">11.	Third Parties</div>
                <div className="termsTextDescription">
                  None of the provisions of these  terms and conditions are intended to confer a benefit on or be enforceable by  any third party under the Contracts (Rights of Third Parties) Act 1999 or  otherwise.
                </div>
              </div>
            </div>
            <div style={{flex: 0.4}}/>
          </div>
          <div className="advStore" style={{marginTop: 300}}>
            <div className="advStoreTitle">
              <div className="advStoreTitleText">Join the revolution and help</div>
              <div className="advStoreTitleText">repair our planet from the</div>
              <div className="advStoreTitleText">grassroots up</div>
            </div>
            <div className="advStoreButtons">
              <AppStoreButton/>
              <GoogleStoreButton style={{marginLeft: 20, marginTop: 0}}/>
            </div>
          </div>
          <div className="seperator"/>
          <Footer/>
        </div>
      </div>
    );
  }
}

export default AdTerms;
