import React, { Component } from "react";

import "video-react/dist/video-react.css";

import Header from "../components/header";
import Footer from "../components/footer";

import AppStoreButton from "../components/buttons/appstore";
import GoogleStoreButton from "../components/buttons/googlestore";

import "../../App.css";
import "./styles.css";

import backgroundImage from "../../res/images/img-home-background.png";

const BACKGROUND_SIZE = {
  height: 7350,
  width: 2861,
};

const SCREEN_HEIGHT = 3360;
const SCREEN_WIDTH = 1440;
const SCREEN_ASPECTRATIO = BACKGROUND_SIZE.width / BACKGROUND_SIZE.height;

const MOBILE_SCREEN_HEIGHT = 3860;
const MOBILE_SCREEN_WIDTH = 1440;
const MOBILE_SCREEN_ASPECTRATIO = MOBILE_SCREEN_WIDTH / MOBILE_SCREEN_HEIGHT;

const BACKGROUND_MARGINTOP = 0;
const VIDEO_WIDTH = 333;

class Privacy extends Component {
  constructor(props) {
    super(props);

    var screenWidth = window.innerWidth;

    this.state = {
      screenWidth: screenWidth,
      participants: [],
      availableCount: 0,
    };
  }

  componentDidMount = async () => {
    window.addEventListener("resize", this.onWindowResized);
    await this.refresh();
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.onWindowResized);
  }

  refresh = async () => {
  }

  onWindowResized = () => {
    var screenWidth = window.innerWidth;
    this.setState({
      screenWidth: screenWidth,
    });
  }

  renderMobile = () => {
    // body size
    var screenWidth = this.state.screenWidth - this.props.scrollbarWidth;
    var marginTop = BACKGROUND_MARGINTOP / (BACKGROUND_SIZE.width / this.state.screenWidth);
    var screenHeight = MOBILE_SCREEN_HEIGHT * (screenWidth / MOBILE_SCREEN_WIDTH);
    screenHeight = MOBILE_SCREEN_HEIGHT / MOBILE_SCREEN_ASPECTRATIO; //screenWidth / SCREEN_ASPECTRATIO - marginTop;

    // body margin
    var bodyMarginHorizontal = (screenWidth - VIDEO_WIDTH * 4) / 2;

    // participants width
    var contentWidth = screenWidth - 20;
    var availableCount = Math.floor((contentWidth + 10) / 42);
    var participantCount = availableCount - 1 + 8;
    var morePariticipantCount = participantCount - availableCount + 1;

    var participants = [];
    for (var i = 0; i < availableCount - 1; i++) {
      participants.push({
        name: "",
      });
    }

    return (
      <div className="App page">
        <Header
          // backgroundImage={backgroundImage}
          // backgroundMarginTop={marginTop}
          isMobile={true}
          screen="privacy"
          screenSize={{
            height: screenWidth / SCREEN_ASPECTRATIO,
            width: screenWidth,
          }}
        />
        <div
          className="body"
          style={{
            backgroundColor: "rgb(0, 95, 176)",
            backgroundImage:`url(${backgroundImage})`,
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            height: screenHeight,
            width: screenWidth,
          }}
        >
          <div className="privacyMobileBody">
            <div className="privacyMobileTitle">Privacy</div>
            <div className="privacyMobileDescription">
              Enviromates takes your privacy seriously and strives to create a transparent and honest narrative between advertisers and consumers with the significant value created passed on as a passive income to Enviromates users. Users can then elect to crowdfund the best environmental projects around the world.
            </div>
            <div className="privacyMobileDescription">
              Enviromates believes in creating a true digitally empowered democracy, one where society are in control of their presence and can elect whether to share anonymized attributes about themselves to advertisers or not. 
            </div>
            <div className="privacyMobileDescription">
              We have much work to do but we believe everyone should have a stake in the Digital Economy and we will continue to work towards a new digital future and find the optimum balance between protecting your privacy and helping to repair our planet.
            </div>
            <div className="privacyMobileDescription">
              Last updated [8th December 2022]
            </div>
            <hr className="hr"/>
            <div className="privacyMobileSubTitle">INTRODUCTION</div>
            <div className="privacyMobileDescription">
              Yamu Ltd (“we” or “us” or “our” or “Enviromates”) respects the privacy of our users (“user” or “you”). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit http://www.enviromates.earth website or our mobile application (“Enviromates” or “App” or “Application”) and any other media form, media channel, mobile website related, linked, or otherwise connected thereto (collectively the "Site"). Please read this privacy policy carefully.  If you do not agree with the terms of this privacy policy, please do not access the site. 
            </div>
            <div className="privacyMobileDescription">
              We reserve the right to make changes to this Privacy Policy at any time and for any reason.  We will alert you about any changes by updating the “Last Updated” date of this Privacy Policy.  Any changes or modifications will be effective immediately upon posting the updated Privacy Policy on the Site, and you waive the right to receive specific notice of each such change or modification. 
            </div>
            <div className="privacyMobileDescription">
              You are encouraged to periodically review this Privacy Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Privacy Policy by your continued use of the Site after the date such revised Privacy Policy is posted.  
            </div>
            <div className="privacyMobileSubTitle">COLLECTION OF YOUR INFORMATION</div>
            <div className="privacyMobileDescription">
              We may collect information about you in a variety of ways. The information we may collect on the Site includes:
            </div>
            <div className="privacyMobileSubTitleSub">Personal Data</div>
            <div className="privacyMobileDescription">
              Personally identifiable information, such as your name, address, email address, and telephone number, and demographic information, such as your age, gender, hometown, and interests, that you voluntarily share with us. You are under no obligation to provide us with personal information of any kind, however to do no so may prevent you from using certain features of the Site.
            </div>
            <div className="privacyMobileSubTitleSub">Derivative Data</div>
            <div className="privacyMobileDescription">
              Information our servers automatically collect when you access the Site, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the Site. 
            </div>
            <div className="privacyMobileSubTitleSub">Financial Data</div>
            <div className="privacyMobileDescription">
              Financial information, such as data related to your payment method (e.g. valid credit card number, card brand, expiration date) that we may collect when you purchase, order, return, exchange, or request information about our services from the Site. We store only very limited, if any, financial information that we collect. Otherwise, all financial information is stored by our payment processors, and you are encouraged to review their privacy policy and contact them directly for responses to your questions.
            </div>
            <div className="privacyMobileSubTitleSub">Data From Social Networks</div>
            <div className="privacyMobileDescription">
              User information from social networking sites, such as  Facebook, Instagram, Tik Tok. Twitter, including your name, your social network username, location, gender, birth date, email address, profile picture, and public data for contacts, if you connect your account to such social networks. 
            </div>
            <div className="privacyMobileSubTitleSub">Mobile Device Data</div>
            <div className="privacyMobileDescription">
              Device information, such as your mobile device ID, model, and manufacturer, and information about the location of your device, if you access the Site from a mobile device.
            </div>
            <div className="privacyMobileSubTitleSub">Third-Party Data</div>
            <div className="privacyMobileDescription">
              Information from third parties, such as personal information or network friends, if you connect your account to the third party and  grant the Site permission to access this information.
            </div>
            <div className="privacyMobileSubTitleSub">Data From Contests, Giveaways, and Surveys</div>
            <div className="privacyMobileDescription">
              Personal and other information you may provide when entering contests or giveaways and/or responding to surveys.
            </div>
            <div className="privacyMobileSubTitle">USE OF YOUR INFORMATION</div>
            <div className="privacyMobileDescription">
              Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience.  Specifically, we may use information collected about you via the Site to: 
            </div>
            <div className="privacyMobileDescription"></div>
            <ul className="mobileOl">
              <li>Create and manage your account.</li>
              <li>Assist law enforcement and respond to subpoena.</li>
              <li>Compile anonymous statistical data and analysis for use internally or with third parties. </li>
              <li>Deliver targeted advertising, coupons, newsletters, and other information regarding promotions and the Site  to you. </li>
              <li>Email you regarding your account.</li>
              <li>Enable user-to-user communications.</li>
              <li>Fulfill and manage purchases, orders, payments, and other transactions related to the Site.</li>
              <li>Generate a personal profile about you to make future visits to the Site  more personalized.</li>
              <li>Increase the efficiency and operation of the Site.</li>
              <li>Monitor and analyze usage and trends to improve your experience with the Site.</li>
              <li>Notify you of updates to the Sites.</li>
              <li>Offer new products, services, and/or recommendations to you.</li>
              <li>Perform other business activities as needed.</li>
              <li>Prevent fraudulent transactions, monitor against theft, and protect against criminal activity.</li>
              <li>Process payments and refunds.</li>
              <li>Request feedback and contact you about your use of the Site. </li>
              <li>Resolve disputes and troubleshoot problems.</li>
              <li>Respond to product and customer service requests.</li>
              <li>Send you a newsletter.</li>
              <li>Solicit support for the Site</li>
            </ul>
            <div className="privacyMobileSubTitle">DISCLOSURE OF YOUR INFORMATION</div>
            <div className="privacyMobileDescription">
              We may share information we have collected about you in certain situations. Your information may be disclosed as follows:  
            </div>
            <div className="privacyMobileSubTitleSub">By Law or to Protect Rights</div>
            <div className="privacyMobileDescription">
              If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.  This includes exchanging information with other entities for fraud protection and credit risk reduction.
            </div>
            <div className="privacyMobileSubTitleSub">Third-Party Service Providers</div>
            <div className="privacyMobileDescription">
              We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance. 
            </div>
            <div className="privacyMobileSubTitleSub">Marketing Communications</div>
            <div className="privacyMobileDescription">
              With your consent, or with an opportunity for you to withdraw consent, we may share your information with third parties for marketing purposes, as permitted by law.
            </div>
            <div className="privacyMobileSubTitleSub">Interactions with Other Users</div>
            <div className="privacyMobileDescription">
              If you interact with other users of the Site, those users may see your name, profile photo, and descriptions of your activity, including sending invitations to other users, chatting with other users, liking posts, following blogs. 
            </div>
            <div className="privacyMobileSubTitleSub">Online Postings</div>
            <div className="privacyMobileDescription">
              When you post comments, contributions or other content to the Site, your posts may be viewed by all users and may be publicly distributed outside the Site in perpetuity.
            </div>
            <div className="privacyMobileSubTitleSub">Third-Party Advertisers</div>
            <div className="privacyMobileDescription">
              We may use third-party advertising companies to serve ads when you visit the Site. These companies may use information about your visits to the Site  and other websites that are contained in web cookies in order to provide advertisements about goods and services of interest to you. 
            </div>
            <div className="privacyMobileSubTitleSub">Affiliates</div>
            <div className="privacyMobileDescription">
              We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include our parent company and any subsidiaries, joint venture partners or other companies that we control or that are under common control with us.
            </div>
            <div className="privacyMobileSubTitleSub">Other Third Parties</div>
            <div className="privacyMobileDescription">
              We may share your information with advertisers and investors for the purpose of conducting general business analysis. We may also share your information with such third parties for marketing purposes, as permitted by law. 
            </div>
            <div className="privacyMobileSubTitleSub">Sale or Bankruptcy</div>
            <div className="privacyMobileDescription">
              If we reorganize or sell all or a portion of our assets, undergo a merger, or are acquired by another entity, we may transfer your information to the successor entity.  If we go out of business or enter bankruptcy, your information would be an asset transferred or acquired by a third party.  You acknowledge that such transfers may occur and that the transferee may decline honor commitments we made in this Privacy Policy.
            </div>
            <div className="privacyMobileDescription">
              We are not responsible for the actions of third parties with whom you share personal or sensitive data, and we have no authority to manage or control third-party solicitations.  If you no longer wish to receive correspondence, emails or other communications from third parties, you are responsible for contacting the third party directly.
            </div>
            <div className="privacyMobileSubTitle">TRACKING TECHNOLOGIES</div>
            <div className="privacyMobileSubTitleSub">Cookies and Web Beacons</div>
            <div className="privacyMobileDescription">
              We may use cookies, web beacons, tracking pixels, and other tracking technologies on the Site  to help customize the Site  and improve your experience. For more information on how we use cookies, please refer to our Cookie Policy posted on the Site, which is incorporated into this Privacy Policy. By using the Site, you agree to be bound by our Cookie Policy.
            </div>
            <div className="privacyMobileSubTitleSub">Internet-Based Advertising</div>
            <div className="privacyMobileDescription">
              Additionally, we may use third-party software to serve ads on the Site , implement email marketing campaigns, and manage other interactive marketing initiatives.  This third-party software may use cookies or similar tracking technology to help manage and optimize your online experience with us.  For more information about opting-out of interest-based ads, visit the <a href="https://optout.networkadvertising.org">Network Advertising Initiative Opt-Out Tool</a> or <a href="https://optout.aboutads.info/?c=2&lang=EN">Digital Advertising Alliance Opt-Out Tool</a>.
            </div>
            <div className="privacyMobileSubTitleSub">Website Analytics</div>
            <div className="privacyMobileDescription">
              We may also partner with selected third-party vendors, to allow tracking technologies and remarketing services on the Site  through the use of first party cookies and third-party cookies, to, among other things, analyze and track users’ use of the Site  , determine the popularity of certain content and better understand online activity. By accessing the Site, you consent to the collection and use of your information by these third-party vendors. You are encouraged to review their privacy policy and contact them directly for responses to your questions. We do not transfer personal information to these third-party vendors. However, if you do not want any information to be collected and used by tracking technologies, you can visit the <a href="https://optout.networkadvertising.org">Network Advertising Initiative Opt-Out Tool</a> or <a href="https://optout.aboutads.info/?c=2&lang=EN">Digital Advertising Alliance Opt-Out Tool</a>.
            </div>
            <div className="privacyMobileDescription">
              You should be aware that getting a new computer, installing a new browser, upgrading an existing browser, or erasing or otherwise altering your browser’s cookies files may also clear certain opt-out cookies, plug-ins, or settings.
            </div>
            <div className="privacyMobileSubTitle">THIRD-PARTY WEBSITES</div>
            <div className="privacyMobileDescription">
              The Site  may contain links to third-party websites and applications of interest, including advertisements and external services, that are not affiliated with us. Once you have used these links to leave the Site , any information you provide to these third parties is not covered by this Privacy Policy, and we cannot guarantee the safety and privacy of your information. Before visiting and providing any information to any third-party websites, you should inform yourself of the privacy policies and practices (if any) of the third party responsible for that website, and should take those steps necessary to, in your discretion, protect the privacy of your information. We are not responsible for the content or privacy and security practices and policies of any third parties, including other sites, services or applications that may be linked to or from the Site .
            </div>
            <div className="privacyMobileSubTitle">SECURITY OF YOUR INFORMATION</div>
            <div className="privacyMobileDescription">
              We use administrative, technical, and physical security measures to help protect your personal information.  While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.  Any information disclosed online is vulnerable to interception and misuse by unauthorized parties. Therefore, we cannot guarantee complete security if you provide personal information.
            </div>
            <div className="privacyMobileSubTitle">POLICY FOR CHILDREN</div>
            <div className="privacyMobileDescription">
              We do not knowingly solicit information from or market to children under the age of 18. If you become aware of any data we have collected from children under age 18, please contact us using the contact information provided below. 
            </div>
            <div className="privacyMobileSubTitle">YOUR RIGHT TO PRIVACY</div>
            <div className="privacyMobileDescription">
              In some regions (such as the European Economic Area), applicable data privacy laws grant you particular rights. Examples include the right:
            </div>
            <ol className="mobileOl" type="i">
              <li>To request access to your personal information and acquire a copy of it,</li>
              <li>to request correction or deletion;</li>
              <li>to limit the processing of your personal data;</li>
              <li>where applicable, to data portability.</li>
            </ol>
            <div className="privacyMobileDescription">
              You may also have the right to object to the processing of your personal information under certain conditions. To make such a request, please contact us via the contact information provided below, We will evaluate and respond to any requests in compliance with the applicable data protection regulations.
            </div>
            <div className="privacyMobileSubTitle">CALIFORNIA RESIDENTS (CCPA)</div>
            <div className="privacyMobileDescription">
              If you are a resident of California, you have the right to access the Personal Information we hold about you (also known as the ‘Right to Know’), to port it to a new service, and to ask that your Personal Information be corrected, updated, or erased. If you would like to exercise these rights, please contact us through the contact information below .
            </div>
            <div className="privacyMobileDescription">
              If you would like to designate an authorized agent to submit these requests on your behalf, please contact us at the address below.
            </div>
            <div className="privacyMobileSubTitle">CALIFORNIA ONLINE PRIVACY PROTECTION ACT (CAIOPPA)</div>
            <div className="privacyMobileDescription">
              CaIOPPA requires us to disclose categories of Personal Information we collect and how we use it, the categories of sources horn whom we collect Personal Information, and the third parties with whom we share it, which we have explained above. CaIOPPA users have the following rights: 
              <br/>Right to Know and Access. You may submit a verifiable request for information regarding the: (1) categories of Personal Information we collect, use, or share; (2) purposes for which categories of Personal Information are collected or used by us, (3) categories of sources from which we collect Personal Information; and (4) specific pieces of Personal Information we have collected about you. 
              <br/>Right to Equal Service. We will not discriminate against you if you exercise your privacy rights.
              <br/>Right to Delete. You may submit a verifiable request to close your account and we will delete Personal Information about you that we have collected. 
              <br/>Right to request that a business that sells a consumer's personal data, not sell the consumer's personal data. If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us. We to not sell the Personal Information of our users. For more information about these rights, please contact us.
            </div>
            <div className="privacyMobileSubTitle">CONTROLS FOR DO-NOT-TRACK FEATURES</div>
            <div className="privacyMobileDescription">
              Most web browsers and some mobile operating systems  include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected.  No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online.  If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Policy. 
            </div>
            <div className="privacyMobileSubTitle">OPTIONS REGARDING YOUR INFORMATION</div>
            <div className="privacyMobileSubTitleSub">Account Information</div>
            <div className="privacyMobileDescription">
              You may at any time review or change the information in your account or terminate your account by:
            </div>
            <ul className="mobileOl">
              <li>Logging into your account settings and updating your account</li>
              <li>Contacting us using the contact information provided below</li>
            </ul>
            <div className="privacyMobileDescription" style={{marginTop:0}}>
              Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.
            </div>
            <div className="privacyMobileSubTitleSub">Emails and Communications</div>
            <div className="privacyMobileDescription">
              If you no longer wish to receive correspondence, emails, or other communications from us, you may opt-out by:
            </div>
            <ul className="mobileOl">
              <li>Noting your preferences at the time you register your account with the Site </li>
              <li>Logging into your account settings and updating your preferences.</li>
              <li>Contacting us using the contact information provided below</li>
            </ul>
            <div className="privacyMobileDescription" style={{marginTop:0}}>
              If you no longer wish to receive correspondence, emails, or other communications from third parties, you are responsible for contacting the third party directly. 
            </div>
            <div className="privacyMobileSubTitle">CONTACT US</div>
            <div className="privacyMobileDescription">
              If you have questions or comments about this Privacy Policy, please contact us at:
            </div>
            <div className="privacyMobileDescription">
              Yamu Ltd<br/>
              61c Rivington St<br/>
              London<br/>
              EC2A 3QQ
            </div>
          </div>
          <div className="privacyMobileStore" style={{marginTop: 262}}>
            <div className="privacyMobileStoreTitle">
              <div className="privacyMobileStoreTitleText">
                Join the revolution and help<br/>repair our planet from the<br/>grassroots up
              </div>
            </div>
            <div className="privacyMobileStoreButtons">
              <AppStoreButton isMobile={true}/>
              <GoogleStoreButton isMobile={true} style={{marginLeft: 10, marginTop: 0}}/>
            </div>
          </div>
          <div className="seperator"/>
          <Footer isMobile={true}/>
        </div>
      </div>
    );
  }

  render = () => {
    if (this.state.screenWidth < 768) {
      return this.renderMobile();
    }

    // body size
    var screenWidth = this.state.screenWidth - this.props.scrollbarWidth;
    var marginTop = BACKGROUND_MARGINTOP / (BACKGROUND_SIZE.width / this.state.screenWidth);
    var screenHeight = SCREEN_HEIGHT * (screenWidth / SCREEN_WIDTH);
    screenHeight = SCREEN_HEIGHT / SCREEN_ASPECTRATIO; //screenWidth / SCREEN_ASPECTRATIO - marginTop;

    // body margin
    var bodyMarginHorizontal = (screenWidth - VIDEO_WIDTH * 4) / 2;

    // participants width
    var contentWidth = (screenWidth - 35 * 2);
    if (contentWidth > 534 + 801) {
      contentWidth = 534 + 801;
    }
    contentWidth -= 142;

    var availableCount = Math.floor((contentWidth + 10) / 50);
    var participantCount = availableCount - 1 + 8;
    var morePariticipantCount = participantCount - availableCount + 1;

    var participants = [];
    for (var i = 0; i < availableCount - 1; i++) {
      participants.push({
        name: "",
      });
    }

    return (
      <div className="App page">
        <Header
          // backgroundImage={backgroundImage}
          // backgroundMarginTop={marginTop}
          screen="privacy"
          screenSize={{
            height: screenWidth / SCREEN_ASPECTRATIO,
            width: screenWidth,
          }}
        />
        <div
          className="body"
          style={{
            backgroundColor: "rgb(0, 95, 176)",
            backgroundImage:`url(${backgroundImage})`,
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            height: screenHeight,
            width: screenWidth,
          }}
        >
          <div className="privacyTitle">Privacy Policy</div>
          <div className="privacyBody">
            <div className="privacyContent">
              <div className="privacyContentBody">
                <div className="privacyTextDescription">
                  Enviromates takes your privacy seriously and strives to create a transparent and honest narrative between advertisers and consumers with the significant value created passed on as a passive income to Enviromates users. Users can then elect to crowdfund the best environmental projects around the world.
                </div>
                <div className="privacyTextDescription">
                  Enviromates believes in creating a true digitally empowered democracy, one where society are in control of their presence and can elect whether to share anonymized attributes about themselves to advertisers or not. 
                </div>
                <div className="privacyTextDescription">
                  We have much work to do but we believe everyone should have a stake in the Digital Economy and we will continue to work towards a new digital future and find the optimum balance between protecting your privacy and helping to repair our planet.
                </div>
                <div className="privacyTextDescription">
                  Last updated [8th December 2022]
                </div>
                <hr className="hr"/>
                <div className="privacyTextTitle">INTRODUCTION</div>
                <div className="privacyTextDescription">
                  Yamu Ltd (“we” or “us” or “our” or “Enviromates”) respects the privacy of our users (“user” or “you”). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit http://www.enviromates.earth website or our mobile application (“Enviromates” or “App” or “Application”) and any other media form, media channel, mobile website related, linked, or otherwise connected thereto (collectively the "Site"). Please read this privacy policy carefully.  If you do not agree with the terms of this privacy policy, please do not access the site. 
                </div>
                <div className="privacyTextDescription">
                  We reserve the right to make changes to this Privacy Policy at any time and for any reason.  We will alert you about any changes by updating the “Last Updated” date of this Privacy Policy.  Any changes or modifications will be effective immediately upon posting the updated Privacy Policy on the Site, and you waive the right to receive specific notice of each such change or modification. 
                </div>
                <div className="privacyTextDescription">
                  You are encouraged to periodically review this Privacy Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Privacy Policy by your continued use of the Site after the date such revised Privacy Policy is posted.  
                </div>
                <div className="privacyTextTitle">COLLECTION OF YOUR INFORMATION</div>
                <div className="privacyTextDescription">
                  We may collect information about you in a variety of ways. The information we may collect on the Site includes:
                </div>
                <div className="privacyTextSubTitle">Personal Data</div>
                <div className="privacyTextDescription">
                  Personally identifiable information, such as your name, address, email address, and telephone number, and demographic information, such as your age, gender, hometown, and interests, that you voluntarily share with us. You are under no obligation to provide us with personal information of any kind, however to do no so may prevent you from using certain features of the Site.
                </div>
                <div className="privacyTextSubTitle">Derivative Data</div>
                <div className="privacyTextDescription">
                  Information our servers automatically collect when you access the Site, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the Site. 
                </div>
                <div className="privacyTextSubTitle">Financial Data</div>
                <div className="privacyTextDescription">
                  Financial information, such as data related to your payment method (e.g. valid credit card number, card brand, expiration date) that we may collect when you purchase, order, return, exchange, or request information about our services from the Site. We store only very limited, if any, financial information that we collect. Otherwise, all financial information is stored by our payment processors, and you are encouraged to review their privacy policy and contact them directly for responses to your questions.
                </div>
                <div className="privacyTextSubTitle">Data From Social Networks</div>
                <div className="privacyTextDescription">
                  User information from social networking sites, such as  Facebook, Instagram, Tik Tok. Twitter, including your name, your social network username, location, gender, birth date, email address, profile picture, and public data for contacts, if you connect your account to such social networks. 
                </div>
                <div className="privacyTextSubTitle">Mobile Device Data</div>
                <div className="privacyTextDescription">
                  Device information, such as your mobile device ID, model, and manufacturer, and information about the location of your device, if you access the Site from a mobile device.
                </div>
                <div className="privacyTextSubTitle">Third-Party Data</div>
                <div className="privacyTextDescription">
                  Information from third parties, such as personal information or network friends, if you connect your account to the third party and  grant the Site permission to access this information.
                </div>
                <div className="privacyTextSubTitle">Data From Contests, Giveaways, and Surveys</div>
                <div className="privacyTextDescription">
                  Personal and other information you may provide when entering contests or giveaways and/or responding to surveys.
                </div>
                <div className="privacyTextTitle">USE OF YOUR INFORMATION</div>
                <div className="privacyTextDescription">
                  Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience.  Specifically, we may use information collected about you via the Site to: 
                </div>
                <div className="privacyTextDescription"></div>
                <ul className="ol">
                  <li>Create and manage your account.</li>
                  <li>Assist law enforcement and respond to subpoena.</li>
                  <li>Compile anonymous statistical data and analysis for use internally or with third parties. </li>
                  <li>Deliver targeted advertising, coupons, newsletters, and other information regarding promotions and the Site  to you. </li>
                  <li>Email you regarding your account.</li>
                  <li>Enable user-to-user communications.</li>
                  <li>Fulfill and manage purchases, orders, payments, and other transactions related to the Site.</li>
                  <li>Generate a personal profile about you to make future visits to the Site  more personalized.</li>
                  <li>Increase the efficiency and operation of the Site.</li>
                  <li>Monitor and analyze usage and trends to improve your experience with the Site.</li>
                  <li>Notify you of updates to the Sites.</li>
                  <li>Offer new products, services, and/or recommendations to you.</li>
                  <li>Perform other business activities as needed.</li>
                  <li>Prevent fraudulent transactions, monitor against theft, and protect against criminal activity.</li>
                  <li>Process payments and refunds.</li>
                  <li>Request feedback and contact you about your use of the Site. </li>
                  <li>Resolve disputes and troubleshoot problems.</li>
                  <li>Respond to product and customer service requests.</li>
                  <li>Send you a newsletter.</li>
                  <li>Solicit support for the Site</li>
                </ul>
                <div className="privacyTextTitle">DISCLOSURE OF YOUR INFORMATION</div>
                <div className="privacyTextDescription">
                  We may share information we have collected about you in certain situations. Your information may be disclosed as follows:  
                </div>
                <div className="privacyTextSubTitle">By Law or to Protect Rights</div>
                <div className="privacyTextDescription">
                  If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.  This includes exchanging information with other entities for fraud protection and credit risk reduction.
                </div>
                <div className="privacyTextSubTitle">Third-Party Service Providers</div>
                <div className="privacyTextDescription">
                  We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance. 
                </div>
                <div className="privacyTextSubTitle">Marketing Communications</div>
                <div className="privacyTextDescription">
                  With your consent, or with an opportunity for you to withdraw consent, we may share your information with third parties for marketing purposes, as permitted by law.
                </div>
                <div className="privacyTextSubTitle">Interactions with Other Users</div>
                <div className="privacyTextDescription">
                  If you interact with other users of the Site, those users may see your name, profile photo, and descriptions of your activity, including sending invitations to other users, chatting with other users, liking posts, following blogs. 
                </div>
                <div className="privacyTextSubTitle">Online Postings</div>
                <div className="privacyTextDescription">
                  When you post comments, contributions or other content to the Site, your posts may be viewed by all users and may be publicly distributed outside the Site in perpetuity.
                </div>
                <div className="privacyTextSubTitle">Third-Party Advertisers</div>
                <div className="privacyTextDescription">
                  We may use third-party advertising companies to serve ads when you visit the Site. These companies may use information about your visits to the Site  and other websites that are contained in web cookies in order to provide advertisements about goods and services of interest to you. 
                </div>
                <div className="privacyTextSubTitle">Affiliates</div>
                <div className="privacyTextDescription">
                  We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include our parent company and any subsidiaries, joint venture partners or other companies that we control or that are under common control with us.
                </div>
                <div className="privacyTextSubTitle">Other Third Parties</div>
                <div className="privacyTextDescription">
                  We may share your information with advertisers and investors for the purpose of conducting general business analysis. We may also share your information with such third parties for marketing purposes, as permitted by law. 
                </div>
                <div className="privacyTextSubTitle">Sale or Bankruptcy</div>
                <div className="privacyTextDescription">
                  If we reorganize or sell all or a portion of our assets, undergo a merger, or are acquired by another entity, we may transfer your information to the successor entity.  If we go out of business or enter bankruptcy, your information would be an asset transferred or acquired by a third party.  You acknowledge that such transfers may occur and that the transferee may decline honor commitments we made in this Privacy Policy.
                </div>
                <div className="privacyTextDescription">
                  We are not responsible for the actions of third parties with whom you share personal or sensitive data, and we have no authority to manage or control third-party solicitations.  If you no longer wish to receive correspondence, emails or other communications from third parties, you are responsible for contacting the third party directly.
                </div>
                <div className="privacyTextTitle">TRACKING TECHNOLOGIES</div>
                <div className="privacyTextSubTitle">Cookies and Web Beacons</div>
                <div className="privacyTextDescription">
                  We may use cookies, web beacons, tracking pixels, and other tracking technologies on the Site  to help customize the Site  and improve your experience. For more information on how we use cookies, please refer to our Cookie Policy posted on the Site, which is incorporated into this Privacy Policy. By using the Site, you agree to be bound by our Cookie Policy.
                </div>
                <div className="privacyTextSubTitle">Internet-Based Advertising</div>
                <div className="privacyTextDescription">
                  Additionally, we may use third-party software to serve ads on the Site , implement email marketing campaigns, and manage other interactive marketing initiatives.  This third-party software may use cookies or similar tracking technology to help manage and optimize your online experience with us.  For more information about opting-out of interest-based ads, visit the <a href="https://optout.networkadvertising.org">Network Advertising Initiative Opt-Out Tool</a> or <a href="https://optout.aboutads.info/?c=2&lang=EN">Digital Advertising Alliance Opt-Out Tool</a>.
                </div>
                <div className="privacyTextSubTitle">Website Analytics</div>
                <div className="privacyTextDescription">
                  We may also partner with selected third-party vendors, to allow tracking technologies and remarketing services on the Site  through the use of first party cookies and third-party cookies, to, among other things, analyze and track users’ use of the Site  , determine the popularity of certain content and better understand online activity. By accessing the Site, you consent to the collection and use of your information by these third-party vendors. You are encouraged to review their privacy policy and contact them directly for responses to your questions. We do not transfer personal information to these third-party vendors. However, if you do not want any information to be collected and used by tracking technologies, you can visit the <a href="https://optout.networkadvertising.org">Network Advertising Initiative Opt-Out Tool</a> or <a href="https://optout.aboutads.info/?c=2&lang=EN">Digital Advertising Alliance Opt-Out Tool</a>.
                </div>
                <div className="privacyTextDescription">
                  You should be aware that getting a new computer, installing a new browser, upgrading an existing browser, or erasing or otherwise altering your browser’s cookies files may also clear certain opt-out cookies, plug-ins, or settings.
                </div>
                <div className="privacyTextTitle">THIRD-PARTY WEBSITES</div>
                <div className="privacyTextDescription">
                  The Site  may contain links to third-party websites and applications of interest, including advertisements and external services, that are not affiliated with us. Once you have used these links to leave the Site , any information you provide to these third parties is not covered by this Privacy Policy, and we cannot guarantee the safety and privacy of your information. Before visiting and providing any information to any third-party websites, you should inform yourself of the privacy policies and practices (if any) of the third party responsible for that website, and should take those steps necessary to, in your discretion, protect the privacy of your information. We are not responsible for the content or privacy and security practices and policies of any third parties, including other sites, services or applications that may be linked to or from the Site .
                </div>
                <div className="privacyTextTitle">SECURITY OF YOUR INFORMATION</div>
                <div className="privacyTextDescription">
                  We use administrative, technical, and physical security measures to help protect your personal information.  While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.  Any information disclosed online is vulnerable to interception and misuse by unauthorized parties. Therefore, we cannot guarantee complete security if you provide personal information.
                </div>
                <div className="privacyTextTitle">POLICY FOR CHILDREN</div>
                <div className="privacyTextDescription">
                  We do not knowingly solicit information from or market to children under the age of 18. If you become aware of any data we have collected from children under age 18, please contact us using the contact information provided below. 
                </div>
                <div className="privacyTextTitle">YOUR RIGHT TO PRIVACY</div>
                <div className="privacyTextDescription">
                  In some regions (such as the European Economic Area), applicable data privacy laws grant you particular rights. Examples include the right:
                </div>
                <ol className="ol" type="i">
                  <li>To request access to your personal information and acquire a copy of it,</li>
                  <li>to request correction or deletion;</li>
                  <li>to limit the processing of your personal data;</li>
                  <li>where applicable, to data portability.</li>
                </ol>
                <div className="privacyTextDescription">
                  You may also have the right to object to the processing of your personal information under certain conditions. To make such a request, please contact us via the contact information provided below, We will evaluate and respond to any requests in compliance with the applicable data protection regulations.
                </div>
                <div className="privacyTextTitle">CALIFORNIA RESIDENTS (CCPA)</div>
                <div className="privacyTextDescription">
                  If you are a resident of California, you have the right to access the Personal Information we hold about you (also known as the ‘Right to Know’), to port it to a new service, and to ask that your Personal Information be corrected, updated, or erased. If you would like to exercise these rights, please contact us through the contact information below .
                </div>
                <div className="privacyTextDescription">
                  If you would like to designate an authorized agent to submit these requests on your behalf, please contact us at the address below.
                </div>
                <div className="privacyTextTitle">CALIFORNIA ONLINE PRIVACY PROTECTION ACT (CAIOPPA)</div>
                <div className="privacyTextDescription">
                  CaIOPPA requires us to disclose categories of Personal Information we collect and how we use it, the categories of sources horn whom we collect Personal Information, and the third parties with whom we share it, which we have explained above. CaIOPPA users have the following rights: 
                  <br/>Right to Know and Access. You may submit a verifiable request for information regarding the: (1) categories of Personal Information we collect, use, or share; (2) purposes for which categories of Personal Information are collected or used by us, (3) categories of sources from which we collect Personal Information; and (4) specific pieces of Personal Information we have collected about you. 
                  <br/>Right to Equal Service. We will not discriminate against you if you exercise your privacy rights.
                  <br/>Right to Delete. You may submit a verifiable request to close your account and we will delete Personal Information about you that we have collected. 
                  <br/>Right to request that a business that sells a consumer's personal data, not sell the consumer's personal data. If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us. We to not sell the Personal Information of our users. For more information about these rights, please contact us.
                </div>
                <div className="privacyTextTitle">CONTROLS FOR DO-NOT-TRACK FEATURES</div>
                <div className="privacyTextDescription">
                  Most web browsers and some mobile operating systems  include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected.  No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online.  If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Policy. 
                </div>
                <div className="privacyTextTitle">OPTIONS REGARDING YOUR INFORMATION</div>
                <div className="privacyTextSubTitle">Account Information</div>
                <div className="privacyTextDescription">
                  You may at any time review or change the information in your account or terminate your account by:
                </div>
                <ul className="ol">
                  <li>Logging into your account settings and updating your account</li>
                  <li>Contacting us using the contact information provided below</li>
                </ul>
                <div className="privacyTextDescription" style={{marginTop:0}}>
                  Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.
                </div>
                <div className="privacyTextSubTitle">Emails and Communications</div>
                <div className="privacyTextDescription">
                  If you no longer wish to receive correspondence, emails, or other communications from us, you may opt-out by:
                </div>
                <ul className="ol">
                  <li>Noting your preferences at the time you register your account with the Site </li>
                  <li>Logging into your account settings and updating your preferences.</li>
                  <li>Contacting us using the contact information provided below</li>
                </ul>
                <div className="privacyTextDescription" style={{marginTop:0}}>
                  If you no longer wish to receive correspondence, emails, or other communications from third parties, you are responsible for contacting the third party directly. 
                </div>
                <div className="privacyTextTitle">CONTACT US</div>
                <div className="privacyTextDescription">
                  If you have questions or comments about this Privacy Policy, please contact us at:
                </div>
                <div className="privacyTextDescription">
                  Yamu Ltd<br/>
                  61c Rivington St<br/>
                  London<br/>
                  EC2A 3QQ
                </div>
              </div>
            </div>
            <div style={{flex: 0.4}}/>
          </div>
          <div className="advStore" style={{marginTop: 300}}>
            <div className="advStoreTitle">
              <div className="advStoreTitleText">Join the revolution and help</div>
              <div className="advStoreTitleText">repair our planet from the</div>
              <div className="advStoreTitleText">grassroots up</div>
            </div>
            <div className="advStoreButtons">
              <AppStoreButton/>
              <GoogleStoreButton style={{marginLeft: 20, marginTop: 0}}/>
            </div>
          </div>
          <div className="seperator"/>
          <Footer/>
        </div>
      </div>
    );
  }
}

export default Privacy;
