import React, {useEffect} from "react";
import { useSearchParams } from "react-router-dom";
import {loadScript} from "@paypal/paypal-js";
const base_url = "https://us-central1-yamu-dev.cloudfunctions.net/api";

const PaypalCheckout = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();

    console.log(searchParams);

    const resultMessage = (message) => {
        const container = document.querySelector("#result-message");
        container.innerHTML = message;
      }

    useEffect(()=>{
        loadScript({
            "client-id": 'Aco1DwwSC_PYywPyGXIDqlyxmGxMQ54lyCRwzmrquzHtlmfs0qJfejAsN9rbydh-ket9inmr8aRALSYz',
            "currency": searchParams.currency_code
        }).then((paypal) => {
    
            paypal
              .Buttons({
                async createOrder() {
                  try {
                    const response = await fetch(`${base_url}/paypal/orders`, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      // use the "body" param to optionally pass additional order information
                      // like product ids and quantities
                      body: JSON.stringify({
                        cart: [
                          {
                            id: searchParams.product_id,
                            quantity: searchParams.product_quantity,
                          },
                        ],
                        details: {
                            currency_code: searchParams.currency_code,
                            value: searchParams.product_quantity,
                        }
                      }),
                    });
            
                    const orderData = await response.json();
            
                    if (orderData.id) {
                      return orderData.id;
                    } else {
                      const errorDetail = orderData?.details?.[0];
                      const errorMessage = errorDetail
                        ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                        : JSON.stringify(orderData);
            
                      throw new Error(errorMessage);
                    }
                  } catch (error) {
                    console.error(error);
                    resultMessage(`Could not initiate PayPal Checkout...<br><br>${error}`);
                  }
                },
                async onApprove(data, actions) {
                  try {
                    const response = await fetch(`${base_url}/paypal/orders/${data.orderID}/capture`, {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                    });
            
                    const orderData = await response.json();
                    // Three cases to handle:
                    //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                    //   (2) Other non-recoverable errors -> Show a failure message
                    //   (3) Successful transaction -> Show confirmation or thank you message
            
                    const errorDetail = orderData?.details?.[0];
            
                    if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                      // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                      // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                      return actions.restart();
                    } else if (errorDetail) {
                      // (2) Other non-recoverable errors -> Show a failure message
                      throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
                    } else if (!orderData.purchase_units) {
                      throw new Error(JSON.stringify(orderData));
                    } else {
                      // (3) Successful transaction -> Show confirmation or thank you message
                      // Or go to another URL:  actions.redirect('thank_you.html');
                      const transaction =
                        orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
                        orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];
                      resultMessage(
                        `Transaction ${transaction.status}: ${transaction.id}<br><br>See console for all available details`,
                      );
                      console.log(
                        "Capture result",
                        orderData,
                        JSON.stringify(orderData, null, 2),
                      );
            
                      // tell app
                    if (window.ReactNativeWebView) {
                        window.ReactNativeWebView.postMessage(JSON.stringify(orderData));
                    }
                
                    }
                  } catch (error) {
                    console.error(error);
                    resultMessage(
                      `Sorry, your transaction could not be processed...<br><br>${error}`,
                    );
                  }
                },
              })
              .render("#paypal-button-container");
            });
    }, []);



    return (
        <div>
            <div id="paypal-button-container" className="paypal-button-container"></div>
            <p id="result-message"></p>
            {/*  
            <div id="paypal-button-container" className="paypal-button-container"></div>
           <div id="card-form" className="card_container">
            <div id="card-name-field-container"></div>
            <div id="card-number-field-container"></div>
            <div id="card-expiry-field-container"></div>
            <div id="card-cvv-field-container"></div>
            <button id="multi-card-field-button" type="button">Pay now with Card</button> 
            </div>
            <p id="result-message"></p>
            */}
        </div>
    );
}


export default PaypalCheckout;