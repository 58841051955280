import React, { Component } from "react";
import { Link } from 'react-router-dom';

import "./styles.css";

import { ReactComponent as FacebookIcon } from "../../../res/images/icon-footer-facebook.svg";
import { ReactComponent as InstagramIcon } from "../../../res/images/icon-footer-instagram.svg";
import { ReactComponent as TwitterIcon } from "../../../res/images/icon-footer-twitter.svg";
import { ReactComponent as ThreadsIcon } from "../../../res/images/icon-footer-threads.svg";
class Footer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = async () => {
    await this.refresh();
  }

  refresh = async () => {
  }

  renderMobile = () => {
    return (
      <div className="mobileFooter">
        <div className="mobileFooterSocial">
          <a className="footerIcon" style={{marginLeft:0}} href="https://www.instagram.com/enviromates.earth"  target="_blank" rel="noopener noreferrer">
            <InstagramIcon className="mobileFooterSocialIcon"/>
          </a>
          <a className="footerIcon" href="https://www.facebook.com/profile.php?id=61555427294310" target="_blank" rel="noopener noreferrer">
            <FacebookIcon className="mobileFooterSocialIcon"/>
          </a>
          <a className="footerIcon" href="https://www.threads.net/@enviromates.earth" arget="_blank" ref="noreferrer">
            <ThreadsIcon className="mobileFooterSocialIcon"/>
          </a>
          <a className="footerIcon" href="https://twitter.com/enviromatesx" target="_blank" ref="noreferrer">
            <TwitterIcon className="mobileFooterSocialIcon"/>
          </a>
        </div>
        <div className="mobileFooterMenus">
          <div className={"mobileFooterMenu"} style={{marginLeft: 10}}>
            <Link className="mobileFooterMenuText" to="/Mission">Mission</Link>
          </div>
          <div className="seperator"/>
          <div className={"mobileFooterMenu"} >
            <Link className="mobileFooterMenuText" to="/Terms">Terms & Conditions</Link>
          </div>
          <div className="seperator"/>
          <div className={"mobileFooterMenu"} >
            <Link className="mobileFooterMenuText" to="/Privacy">Privacy</Link>
          </div>
          <div className="seperator"/>
          <div className={"mobileFooterMenu"} >
            <Link className="mobileFooterMenuText" to="/Cookie">Cookie Policy</Link>
          </div>
          <div className="seperator"/>
          <div className={"mobileFooterMenu"} style={{marginRight: 10}}>
            <Link className="mobileFooterMenuText" to="/Contact">Contact</Link>
          </div>
        </div>
      </div>
    );
  }

  render = () => {
    if (this.props.isMobile == true) {
      return this.renderMobile();
    }

    return (
      <div className="footer">
        <a className="footerIcon" style={{marginLeft:40}} href="https://www.instagram.com/enviromates.earth" target="_blank" rel="noopener noreferrer">
          <InstagramIcon className="footerIconImage"/>
        </a>
        <a className="footerIcon" href="https://www.facebook.com/profile.php?id=61555427294310" target="_blank" rel="noopener noreferrer">
          <FacebookIcon className="footerIconImage"/>
        </a>
        <a className="footerIcon" href="https://www.threads.net/@enviromates.earth" target="_blank" rel="noopener noreferrer">
          <ThreadsIcon className="footerIconImage"/>
        </a>
        <a className="footerIcon" href="https://twitter.com/enviromatesx" target="_blank" rel="noopener noreferrer">
          <TwitterIcon className="footerIconImage"/>
        </a>
        <div className="seperator"/>
        <div className="footerMenu">
          <Link className="footerMenuText" to="/Mission">Mission</Link>
        </div>
        <div className="footerMenu">
          <Link className="footerMenuText" to="/Terms">Terms & Conditions</Link>
        </div>
        <div className="footerMenu">
          <Link className="footerMenuText" to="/Privacy">Privacy</Link>
        </div>
        <div className="footerMenu">
          <Link className="footerMenuText" to="/Cookie">Cookie Policy</Link>
        </div>
        <div className="footerMenu">
          <Link className="footerMenuText" to="/Contact">Contact</Link>
        </div>
      </div>
    );
  }
}

export default Footer;
