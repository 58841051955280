import React, { Component } from "react";

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { Oval } from "react-loader-spinner";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import { EmailEngine } from "../../engine/email";

import Header from "../components/header";
import Footer from "../components/footer";

import AppStoreButton from "../components/buttons/appstore";
import GoogleStoreButton from "../components/buttons/googlestore";
import GreenButton from "../components/buttons/green";

import "../../App.css";
import "./styles.css";

import backgroundImage from "../../res/images/img-home-background.png";
import beeLeftImage from "../../res/images/img-global-bee-left.png";
import beeRightImage from "../../res/images/img-global-bee-right.png";

const BACKGROUND_SIZE = {
  height: 4020,
  width: 2861,
};

const SCREEN_HEIGHT = 1550;
const SCREEN_WIDTH = 1440;
const SCREEN_ASPECTRATIO = BACKGROUND_SIZE.width / BACKGROUND_SIZE.height;

const MOBILE_SCREEN_HEIGHT = 1300;
const MOBILE_SCREEN_WIDTH = 1440;
const MOBILE_SCREEN_ASPECTRATIO = MOBILE_SCREEN_WIDTH / MOBILE_SCREEN_HEIGHT;

const BACKGROUND_MARGINTOP = 800;
const VIDEO_WIDTH = 333;

class Contact extends Component {
  constructor(props) {
    super(props);

    var screenWidth = window.innerWidth;

    this.state = {
      screenWidth: screenWidth,
      name: "",
      email: "",
      text: "",
      loading: false,
    };
  }

  componentDidMount = async () => {
    window.addEventListener("resize", this.onWindowResized);
    await this.refresh();
  }

  refresh = async () => {
  }

  onWindowResized = () => {
    var screenWidth = window.innerWidth;
    this.setState({
      screenWidth: screenWidth,
    });
  }

  onEmailChanged = (event) => {
    var email = event.target.value;
    this.setState({
      email: email,
    });
  }

  onNameChanged = (event) => {
    var name = event.target.value;
    this.setState({
      name: name,
    });
  }

  onTextChanged = (event) => {
    var text = event.target.value;
    this.setState({
      text: text,
    });
  }

  onSend = async () => {
    if (this.state.name == "") {
      toast.error("Please enter your name.");
      return;
    }
    if (this.state.email == "") {
      toast.error("Please enter your email.");
      return;
    }
    if (this.state.text == "") {
      toast.error("Please enter your message.");
      return;
    }

    this.setState({
      loading: true,
    });

    var data = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.text,
    };

    var ret = await EmailEngine.sendContactEmail(data);
    if (ret.success == false) {
      toast.error("Error occurred while sending contact message. Please try again.");

      this.setState({
        loading: false,
      });

      return;
    }

    toast.success("Your contact message has succefully been sent!");

    this.setState({
      name: "",
      email: "",
      text: "",
      loading: false,
    });
  }

  renderMobile = () => {
      // body size
      var screenWidth = this.state.screenWidth - this.props.scrollbarWidth;
      var marginTop = BACKGROUND_MARGINTOP / (BACKGROUND_SIZE.width / this.state.screenWidth);
      var screenHeight = MOBILE_SCREEN_HEIGHT * (screenWidth / MOBILE_SCREEN_WIDTH);
      screenHeight = MOBILE_SCREEN_HEIGHT / MOBILE_SCREEN_ASPECTRATIO; //screenWidth / SCREEN_ASPECTRATIO - marginTop;

      // video width
      var videoWidth = screenWidth - 48;

      // body margin
      // var bodyMarginHorizontal = (screenWidth - VIDEO_WIDTH * 4) / 2;

    return (
      <div className="App page">
        <Header
          // backgroundImage={backgroundImage}
          // backgroundMarginTop={marginTop}
          isMobile={true}
          screen="brands"
          screenSize={{
            height: screenWidth / SCREEN_ASPECTRATIO,
            width: screenWidth,
          }}
        />
        <div
          className="body"
          style={{
            backgroundColor: "rgb(0, 95, 176)",
            backgroundImage: `url(${backgroundImage})`,
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            height: screenHeight,
            width: screenWidth,
          }}
        >
          <div className="missionMobileTitle">Contact</div>
          <div className="missionMobileDescription">
            Curious about something or do you have a question? Just get in contact with us.
          </div>
          <Form style={{marginTop: 35, paddingLeft: 10, paddingRight: 10, width: "100%"}}>
            <input
              type="text"
              className="contactInput"
              onChange={this.onNameChanged}
              placeholder="Your name"
              value={this.state.name}
            />
            <input
              type="email"
              className="contactInput"
              onChange={this.onEmailChanged}
              placeholder="email"
              style={{marginTop: 10}}
              value={this.state.email}
            />
            <div className="contactText" style={{marginTop: 10}}>
              <textarea
                className="contactTextArea"
                onChange={this.onTextChanged}
                placeholder="Your text"
                value={this.state.text}
              />
            </div>
            <div className="contactButton">
              <GreenButton
                style={{height: 38, width: "100%"}}
                title="Send message"
                titleStyle={{fontSize: 14}}
              />
            </div>
          </Form>
          <div className="missionMobileStore" style={{marginTop: 262}}>
            <div className="missionMobileStoreTitle">
              <div className="missionMobileStoreTitleText">
                Join the revolution and help<br/>repair our planet from the<br/>grassroots up
              </div>
            </div>
            <div className="missionMobileStoreButtons">
              <AppStoreButton isMobile={true}/>
              <GoogleStoreButton isMobile={true} style={{marginLeft: 10, marginTop: 0}}/>
            </div>
          </div>
          <div className="seperator"/>
          <Footer isMobile={true}/>
        </div>

        <ToastContainer
          autoClose={5000}
          hideProgressBar={true}
          position="top-center"
        />

        { this.state.loading &&
          <div className="spinnerContainer">
            <Oval
              ariaLabel='oval-loading'
              color="#72C500"
              height={60}
              secondaryColor="#72C500"
              strokeWidth={3}
              strokeWidthSecondary={3}
              visible={true}
              width={60}
              wrapperStyle
              wrapperClass="spinner"
            />
          </div>
        }
      </div>
    );
  }

  render = () => {
    if (this.state.screenWidth < 768) {
      return this.renderMobile();
    }

    // body size
    var screenWidth = this.state.screenWidth - this.props.scrollbarWidth;
    var marginTop = BACKGROUND_MARGINTOP / (BACKGROUND_SIZE.width / this.state.screenWidth);
    var screenHeight = screenWidth / SCREEN_ASPECTRATIO - marginTop;

    return (
      <div className="App page">
        <Header
          backgroundImage={backgroundImage}
          backgroundMarginTop={marginTop}
          screen="brands"
          screenSize={{
            height: screenWidth / SCREEN_ASPECTRATIO,
            width: screenWidth,
          }}
        />
        <div
          className="body"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundPosition: "bottom",
            backgroundSize: "cover",
            height: screenHeight,
            width: screenWidth,
          }}
        >
          <div className="missionText">Contact</div>
          <div className="missionDescription">
            Curious about something or do you have a question? Just get in contact with us.
          </div>
          <Form style={{marginTop: 70, maxWidth: 900, width: "70%"}}>
            <Row>
              <Col>
                <input
                  type="text"
                  className="contactInput"
                  onChange={this.onNameChanged}
                  placeholder="Your name"
                  value={this.state.name}
                />
              </Col>
              <Col>
                <input
                  type="email"
                  className="contactInput"
                  onChange={this.onEmailChanged}
                  placeholder="email"
                  value={this.state.email}
                />
              </Col>
            </Row>
            <div className="contactText">
              <textarea
                className="contactTextArea"
                onChange={this.onTextChanged}
                placeholder="Your text"
                value={this.state.text}
              />
            </div>
            <div className="contactButton">
              <GreenButton
                onClick={this.onSend}
                style={{height: 38, width: 217}}
                title="Send message"
                titleStyle={{fontSize: 14}}
              />
            </div>
          </Form>
          <div className="advStore">
            <div className="advStoreTitle">
              <div className="advStoreTitleText">Join the revolution and help</div>
              <div className="advStoreTitleText">repair our planet from the</div>
              <div className="advStoreTitleText">grassroots up</div>
            </div>
            <div className="advStoreButtons">
              <AppStoreButton/>
              <GoogleStoreButton style={{marginLeft: 20, marginTop: 0}}/>
            </div>
          </div>
          <div className="seperator"/>
          <Footer/>
        </div>

        <ToastContainer
          autoClose={5000}
          hideProgressBar={true}
          position="top-center"
        />

        { this.state.loading &&
          <div className="spinnerContainer">
            <Oval
              ariaLabel='oval-loading'
              color="#72C500"
              height={60}
              secondaryColor="#72C500"
              strokeWidth={3}
              strokeWidthSecondary={3}
              visible={true}
              width={60}
              wrapperStyle
              wrapperClass="spinner"
            />
          </div>
        }
      </div>
    );
  }
}

export default Contact;
