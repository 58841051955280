import React, { Component } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGooglePlay } from "@fortawesome/free-brands-svg-icons";

import "./styles.css";

class GoogleStoreButton extends Component {
  constructor(props) {
    super(props);
  }

  renderMobile = () => {
    return (
      <div className="mobileBtnGoogleStore" style={this.props.style}>
        <FontAwesomeIcon className="mobileBtnGoogleStoreIcon" icon={faGooglePlay}/>
        <div className="mobileBtnGoogleStoreTitle">
          <div className="mobileBtnGoogleStoreTitleText">Download on</div>
          <div className="mobileBtnGoogleStoreTitleStore" style={{marginTop: -5}}>Google play</div>
        </div>
        <div className="btnGoogleStoreSoon">
          <div className="mobileBtnGoogleStoreSoonBox">
          <div className="mobileBtnGoogleStoreSoonBoxText">
            Coming<br/>soon!</div>
          </div>
        </div>
      </div>
    );
  }

  render = () => {
    if (this.props.isMobile == true) {
      return this.renderMobile();
    }

    return (
      <div className="btnGoogleStore" style={this.props.style}>
        <FontAwesomeIcon className="btnGoogleStoreIcon" icon={faGooglePlay}/>
        <div className="btnGoogleStoreTitle">
          <div className="btnGoogleStoreTitleText">Download on</div>
          <div className="btnGoogleStoreTitleStore">Google play</div>
        </div>
        <div className="btnGoogleStoreSoon">
          <div className="btnGoogleStoreSoonBox">
            <div className="btnGoogleStoreSoonBoxText">Coming</div>
            <div className="btnGoogleStoreSoonBoxText">soon!</div>
          </div>
        </div>
      </div>
    );
  }
}

export default GoogleStoreButton;
