import React, { Component, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { FirebaseEngine } from "../../engine/firebase";

import {
    verifyPasswordResetCode,
    confirmPasswordReset,
    checkActionCode,
    applyActionCode,
    sendPasswordResetEmail,

} from "firebase/auth";

import Popup from "react-popup";

/** The prompt content component */
class Prompt extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.defaultValue
        };

        this.onChange = (e) => this._onChange(e);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.value !== this.state.value) {
            this.props.onChange(this.state.value);
        }
    }

    _onChange(e) {
        let value = e.target.value;

        this.setState({value: value});
    }

    render() {
        return <input type="text" placeholder={this.props.placeholder} className="mm-popup__input" value={this.state.value} onChange={this.onChange} />;
    }
}

const Auth = (props) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [oobCode, setOobCode] = useState(queryParams.get("oobCode"));
    const [mode, setMode] = useState(queryParams.get("mode"));
    const [apiKey, setApiKey] = useState(queryParams.get("apiKey"));
    const [continueUrl, setContinueUrl] = useState(queryParams.get("continueUrl"));
    const [lang, setLang] = useState(queryParams.get("lang"));
    const [email, setEmail] = useState(queryParams.get("email"));

    const auth = FirebaseEngine.auth();

    const [newPassword, setNewPassword] = useState("");

    /** Prompt plugin */
    Popup.registerPlugin('prompt', function (defaultValue, placeholder, callback) {
        let promptValue = null;
        let promptChange = function (value) {
            promptValue = value;
        };

        this.create({
            title: 'Reset your password',
            content: <Prompt onChange={promptChange} placeholder={placeholder} value={defaultValue} />,
            closeBtn: false,
            buttons: {
                right: [{
                    text: 'Save',
                    key: '⌘+s',
                    className: 'success',
                    action: function () {
                        callback(promptValue);
                        Popup.close();
                    }
                }]
            }
        });
    });

    const showNotice = (title, content) => {
        Popup.alert(content, title);
    }


    useEffect(()=>{
        
        /** Call the plugin */
        

        switch(mode) {
            case "resetPassword":
                handleResetPassword(auth, oobCode, continueUrl, lang);
                break;
            case "recoverEmail":
                handleRecoverEmail(auth, oobCode, lang);
                break;
            case "verifyEmail":
                handleVerifyEmail(auth, oobCode, continueUrl, lang, email);
                break;
        }
    }, []);

    const handleResetPassword = (auth, actionCode, continueUrl, lang) => {
        verifyPasswordResetCode(auth, actionCode).then((email) => {

            Popup.plugins().prompt('', 'New Password', function (newPassword) {
                if (!newPassword || newPassword.length < 6) {
                    Popup.alert('Your password should be longer than 6 characters.');
                    return;
                }

                confirmPasswordReset(auth, actionCode, newPassword).then((resp) => {
                    // Password reset has been confirmed and new password updated.
    
                    // TODO: Display a link back to the app, or sign-in the user directly
                    // if the page belongs to the same domain as the app:
                    // auth.signInWithEmailAndPassword(accountEmail, newPassword);
   
                    showNotice('You can now sign in with your new password', 'Password changed');
                    // TODO: If a continue URL is available, display a button which on
                    // click redirects the user back to the app via continueUrl with
                    // additional state determined from that URL's parameters.
                }).catch((error) => {
                    // Error occurred during confirmation. The code might have expired or the
                    // password is too weak.
                    showNotice('Try resetting your password again', 'Your request to reset your password has expired or the link has already been used');
                });
            });
            


        }).catch((error) => {
            // Invalid or expired action code. Ask user to try to reset the password
            // again.
            showNotice('Try resetting your password again', 'Your request to reset your password has expired or the link has already been used');
        });

    };

    const handleRecoverEmail = (auth, actionCode, lang) => {
        // Localize the UI to the selected language as determined by the lang
        // parameter.
        let restoredEmail = null;
        // Confirm the action code is valid.
        checkActionCode(auth, actionCode).then((info) => {
          // Get the restored email address.
          restoredEmail = info['data']['email'];
      
          // Revert to the old email.
          return applyActionCode(auth, actionCode);
        }).then(() => {
          // Account email reverted to restoredEmail
      
          // TODO: Display a confirmation message to the user.
      
          // You might also want to give the user the option to reset their password
          // in case the account was compromised:
          sendPasswordResetEmail(auth, restoredEmail).then(() => {
            // Password reset confirmation sent. Ask user to check their email.
          }).catch((error) => {
            // Error encountered while sending password reset code.
          });
        }).catch((error) => {
          // Invalid code.
        });
    }

    const handleVerifyEmail = async (auth, actionCode, continueUrl, lang, email) => {
        // Localize the UI to the selected language as determined by the lang
        // parameter.
        // Try to apply the email verification code.
        applyActionCode(auth, actionCode).then(async (resp) => {
          // Email address has been verified.
      
          // TODO: Display a confirmation message to the user.
          // You could also provide the user with a link back to the app.
          await FirebaseEngine.sendWelcomeEmail({email: email});
    
         
          showNotice('You can now sign in with your new account', 'Your email has been verified');
          // TODO: If a continue URL is available, display a button which on
          // click redirects the user back to the app via continueUrl with
          // additional state determined from that URL's parameters.
        }).catch((error) => {
          // Code is invalid or expired. Ask the user to verify their email address
          // again.
          showNotice('Your request to verify your email has expired or the link has already been used', 'Try verifying your email again');
        });
    }



    return (
        <div></div>
    );

};

export default Auth;