import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

export class FirebaseEngine {
  static auth = () => {
    try {
      var app = initializeApp({
        apiKey: "AIzaSyDlRe462LN1vgXEJufW2Kh0GZJ4glxryws",
        authDomain: "yamu-dev.firebaseapp.com",
        databaseURL: "https://yamu-dev.firebaseio.com",
        projectId: "yamu-dev",
      });

      return getAuth(app);
    } catch(error) {
      console.log("[FirebaseEngine] functions()");
    }

    return null;

  }


  static functions = () => {
    try {
      var app = initializeApp({
        apiKey: "AIzaSyDlRe462LN1vgXEJufW2Kh0GZJ4glxryws",
        authDomain: "yamu-dev.firebaseapp.com",
        databaseURL: "https://yamu-dev.firebaseio.com",
        projectId: "yamu-dev",
      });

      return getFunctions(app);
    } catch(error) {
      console.log("[FirebaseEngine] functions()");
    }

    return null;
  }

  static sendWelcomeEmail = async (data) => {
    console.log("[FirebaseEngine] sendWelcomeEmail()", data);
    const functions = FirebaseEngine.functions();
    const sendWelcomeEmailFunction = httpsCallable(functions, 'sendWelcomeEmail');

    sendWelcomeEmailFunction(data).then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(`error: ${JSON.stringify(error)}`);
    });
  }

  static sendContactEmail = async (data) => {
    try {
      const functions = FirebaseEngine.functions();
      const sendFunction = httpsCallable(functions, "sendContactEmail");
      return await sendFunction(data);
    } catch(error) {
      console.log("[FirebaseEngine] functions()");
      return {
        success: false,
        error: error.message,
      };
    }
  }
}
