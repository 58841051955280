import React, { useEffect } from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import useScrollbarSize from "react-scrollbar-size";

import ScrollToTop from "./helpers/scrolltotop";

import PaypalAuthWrapper from "./paypal/auth/wrapper";
import PaypalCheckout from "./paypal/checkout";
import StripeOnboardingRefresh from "./stripe/onboarding/refresh";
import StripeOnboardingReturn from "./stripe/onboarding/return";

// admin
import AdminPagesCookie from "./pages/admin/pages/cookie";

// pages
import AdTerms from "./pages/adterms";
import Advertising from "./pages/advertising";
import Brands from "./pages/brands";
import Browser from "./pages/browser";
import Challenges from "./pages/challenges";
import Contact from "./pages/contact";
import Cookie from "./pages/cookie";
import Home from "./pages/home";
import Login from "./pages/login";
import Mission from "./pages/mission";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";

import Auth from "./pages/auth";
import "./App.css";
import Popup from "react-popup";
import Subscription from "./pages/subscription";

function App() {
  const { width } = useScrollbarSize();

  var href = window.location.href;

  /* if (!href.includes("/paypal/auth")
    && !href.includes("/stripe/onboarding/refresh")
    && !href.includes("/stripe/onboarding/return")
    && !href.includes("/verify")
    && !href.includes("/welcome")
    && !href.includes("/auth")
    && !href.includes("/Browser")
    && !href.includes("/Privacy")
    && !href.includes("/Advertising")
    && !href.includes("/paypal/checkout")) {
    var user = sessionStorage.getItem("user");
    if (user == null) {
      if (user == undefined || user == null) {
        return <Login/>;
      }
    }
  } */

  return (
    <BrowserRouter>
      <ScrollToTop/>
      <Routes>
        <Route path="/paypal/auth" element={<PaypalAuthWrapper/>}/>
        <Route path="/paypal/checkout" element={<PaypalCheckout/>}/>
        <Route path="/stripe/onboarding/refresh" element={<StripeOnboardingRefresh/>}/>
        <Route path="/stripe/onboarding/return" element={<StripeOnboardingReturn/>}/>
        
        <Route path="/" element={<Home scrollbarWidth={width}/>}/>
        <Route path="/Home" element={<Home scrollbarWidth={width}/>}/>
        <Route path="/Challenges" element={<Challenges scrollbarWidth={width}/>}/>
        <Route path="/Advertising" element={<Advertising scrollbarWidth={width}/>}/>
        <Route path="/Brands" element={<Brands scrollbarWidth={width}/>}/>
        <Route path="/Browser" element={<Browser scrollbarWidth={width}/>}/>
        <Route path="/Mission" element={<Mission scrollbarWidth={width}/>}/>
        <Route path="/Terms" element={<Terms scrollbarWidth={width}/>}/>
        <Route path="/AdTerms" element={<AdTerms scrollbarWidth={width}/>}/>
        <Route path="/Privacy" element={<Privacy scrollbarWidth={width}/>}/>
        <Route path="/Cookie" element={<Cookie scrollbarWidth={width}/>}/>
        <Route path="/Contact" element={<Contact scrollbarWidth={width}/>}/>
        <Route path="/auth" element={<Auth scrollbarWidth={width}/>}/>
        <Route path="/subscription" element={<Subscription scrollbarWidth={width}/>}/>

        <Route path="/admin/cookie" element={<AdminPagesCookie scrollbarWidth={width}/>}/>
      </Routes>
    </BrowserRouter>    
  );
}

export default App;
