import React, { Component } from "react";

import { Player, BigPlayButton, ControlBar } from "video-react";
import "video-react/dist/video-react.css";

import Header from "../components/header";
import Footer from "../components/footer";

import AppStoreButton from "../components/buttons/appstore";
import GoogleStoreButton from "../components/buttons/googlestore";

import "../../App.css";
import "./styles.css";

import backgroundImage from "../../res/images/img-home-background.png";
import beeLeftImage from "../../res/images/img-global-bee-left.png";
import beeRightImage from "../../res/images/img-global-bee-right.png";
import qrcodeImage from "../../res/images/img-home-qrcode.png";

const VIDEO_URL = "https://firebasestorage.googleapis.com/v0/b/yamu-dev.appspot.com/o/web%2Fvideos%2FEnviromates.mp4?alt=media&token=30ffea5a-cd36-4070-9a39-97fe9b9b8fb3";

const BACKGROUND_SIZE = {
  height: 3286,
  width: 2861,
};

const SCREEN_HEIGHT = 1600;
const SCREEN_WIDTH = 1440;
const SCREEN_ASPECTRATIO = BACKGROUND_SIZE.width / BACKGROUND_SIZE.height;

const MOBILE_SCREEN_HEIGHT = 1360;
const MOBILE_SCREEN_WIDTH = 1440;
const MOBILE_SCREEN_ASPECTRATIO = MOBILE_SCREEN_WIDTH / MOBILE_SCREEN_HEIGHT;

const BACKGROUND_MARGINTOP = 800;
const VIDEO_WIDTH = 333;

class Home extends Component {
  constructor(props) {
    super(props);

    var screenWidth = window.innerWidth;

    this.state = {
      screenWidth: screenWidth,
      videoStopped: false,
    };

    this.player = null;
  }

  componentDidMount = async () => {
    window.addEventListener("resize", this.onWindowResized);

    await this.refresh();

    if (this.player != null) {
      // this.player.load();
      // this.player.play();
    }
  }

  refresh = async () => {
  }

  onWindowResized = () => {
    var screenWidth = window.innerWidth;
    this.setState({
      screenWidth: screenWidth,
    });
  }

  renderMobile = () => {
    // body size
    var screenWidth = this.state.screenWidth - this.props.scrollbarWidth;
    var marginTop = BACKGROUND_MARGINTOP / (BACKGROUND_SIZE.width / this.state.screenWidth);
    var screenHeight = MOBILE_SCREEN_HEIGHT * (screenWidth / MOBILE_SCREEN_WIDTH);
    screenHeight = MOBILE_SCREEN_HEIGHT / MOBILE_SCREEN_ASPECTRATIO; //screenWidth / SCREEN_ASPECTRATIO - marginTop;

    // video width
    var videoWidth = screenWidth - 48;

    // body margin
    // var bodyMarginHorizontal = (screenWidth - VIDEO_WIDTH * 4) / 2;

    return (
      <div className="App page">
        <Header
          // backgroundImage={backgroundImage}
          // backgroundMarginTop={marginTop}
          isMobile={true}
          screen="home"
          screenSize={{
            height: screenWidth / SCREEN_ASPECTRATIO,
            width: screenWidth,
          }}
        />
        <div
          className="body"
          style={{
            backgroundColor: "rgb(0, 95, 176)",
            backgroundImage: `url(${backgroundImage})`,
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            height: screenHeight,
            width: screenWidth,
          }}
        >
          <div className="mobileHomeTitle">
            <div className="mobileHomeTitleText" style={{fontSize:80, lineHeight:0.7}}>Join the</div>
            <div className="mobileHomeTitleText">revolution!</div>
            <img className="mobileHomeBeeLeft" src={beeRightImage}/>
            <img className="mobileHomeBeeRight" src={beeLeftImage}/>
          </div>
          <AppStoreButton isMobile={true} style={{marginTop: 40}}/>
          <GoogleStoreButton isMobile={true} style={{marginTop: 15}}/>
          <div className="bodyContentLeft" style={{marginTop: 40}}>
            <Player
              autoPlay={true}
              className="bodyVideo"
              fluid={false}
              height={videoWidth / (607 / 1080)}
              muted={false}
              playsInline={true}
              ref={player => {this.player = player}}
              src={VIDEO_URL}
              width={videoWidth}
            >
              <BigPlayButton className="bodyVideoBigButton" position="center"/>
              <ControlBar autoHide={true} className="bodyVideoControlBar"/>
            </Player>
          </div>
          <div className="seperator"/>
          <Footer isMobile={true}/>
        </div>
      </div>
    );
  }

  render = () => {
    if (this.state.screenWidth < 768) {
      return this.renderMobile();
    }

    // body size
    var screenWidth = this.state.screenWidth - this.props.scrollbarWidth;
    var marginTop = BACKGROUND_MARGINTOP / (BACKGROUND_SIZE.width / this.state.screenWidth);
    var screenHeight = screenWidth / SCREEN_ASPECTRATIO - marginTop;

    // body margin
    // var bodyMarginHorizontal = (screenWidth - VIDEO_WIDTH * 4) / 2;

    return (
      <div className="App page">
        <Header
          backgroundImage={backgroundImage}
          backgroundMarginTop={marginTop}
          screen="home"
          screenSize={{
            height: screenWidth / SCREEN_ASPECTRATIO,
            width: screenWidth,
          }}
        />
        <div
          className="body"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundPosition: "bottom",
            backgroundSize: "cover",
            height: screenHeight,
            width: screenWidth,
          }}
        >
          <div className="bodyContent">
            <div className="bodyContentLeft">
              <Player
                autoPlay={true}
                className="bodyVideo"
                fluid={false}
                height={598}
                muted={false}
                playsInline={true}
                ref={player => {this.player = player}}
                src={VIDEO_URL}
                width={598 * (607 / 1080)}
              >
                <BigPlayButton
                  className="bodyVideoBigButton"
                  position="center"
                />
                <ControlBar autoHide={true} className="bodyVideoControlBar"/>
              </Player>
            </div>
            <div className="bodyStore">
              <div className="bodyStoreTitle">
                <p className="bodyStoreTitleText">Join the</p>
                <p className="bodyStoreTitleBottomText">revolution!</p>
                <img className="bodyStoreTitleBeeRight" src={beeRightImage}/>
                <img className="bodyStoreTitleBeeLeft" src={beeLeftImage}/>
              </div>
              <div className="bodyStoreContent">
                <div className="bodyStoreContentButtons">
                  <AppStoreButton/>
                  <GoogleStoreButton/>
                </div>
                <div className="bodyStoreContentBarcode">
                  <img className="bodyStoreContentBarcodeImage" src={qrcodeImage}/>
                </div>
              </div>
            </div>
          </div>
          <div className="seperator"/>
          <Footer/>
        </div>
      </div>
    );
  }
}

export default Home;
