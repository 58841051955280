import { ObjectId } from "bson";

export default class PageBlock {
  static PAGE_TERMS = "terms";
  static PAGE_ADTERMS = "adterms";
  static PAGE_PRIVACY = "privacy";
  static PAGE_COOKIE = "cookie";

  static TYPE_HEADING = "heading";
  static TYPE_SUBHEADING = "subheading";
  static TYPE_PARAGRAPH = "paragraph";
  static TYPE_LIST_ORDERED = "list_ordered";
  static TYPE_LIST_UNORDERED = "list_unordered";

  _id = null;
  docId = "";

  page = PageBlock.PAGE_TERMS;

  type = PageBlock.TYPE_HEADING;
  text = "";
  items = [];                                         // string

  constructor() {
    this._id = new ObjectId();
    this.docId = this._id.valueOf();
  }
}
