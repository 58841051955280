import React from "react";
import { useSearchParams } from "react-router-dom";

import PaypalAuth from "./auth";

import "../../App.css";

function PaypalAuthWrapper() {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <PaypalAuth searchParams={searchParams}/>
  );
}

export default PaypalAuthWrapper;
