import React, { Component } from "react";

import Form from 'react-bootstrap/Form';
import GreenButton from "../components/buttons/green";

import "../../App.css";
import "./styles.css";

class Login extends Component {
  constructor(props) {
    super(props);

    var screenHeight = window.innerHeight;
    var screenWidth = window.innerWidth;

    this.state = {
      screenHeight: screenHeight,
      screenWidth: screenWidth,

      name: "",
      nameWrong: false,

      password: "",
      passwordWrong: false,
    };
  }

  componentDidMount = async () => {
    window.addEventListener("resize", this.onWindowResized);
  }

  onLogin = () => {
    if (this.state.name != "enviromates") {
      this.setState({
        nameWrong: true,
      });

      return;
    }
    this.setState({
      nameWrong: false,
    });

    if (this.state.password != "letsdothis") {
      this.setState({
        passwordWrong: true,
      });

      return;
    }
    this.setState({
      passwordWrong: false,
    });

    var user = {
      name: this.state.name,
      type: "user",
    };

    sessionStorage.setItem("user", user);
    if (this.props.setUser) {
      this.props.setUser(user);
    }

    window.location.reload();
  }

  onNameChanged = (event) => {
    var name = event.target.value;
    this.setState({
      name: name,
    });
  }

  onPasswordChanged = (event) => {
    var password = event.target.value;
    this.setState({
      password: password,
    });
  }

  onWindowResized = () => {
    var screenHeight = window.innerHeight;
    var screenWidth = window.innerWidth;
    this.setState({
      screenHeight: screenHeight,
      screenWidth: screenWidth,
    });
  }

  renderMobile = () => {
    return (
      <div className="App page">
        <div
          className="body"
          style={{
            backgroundColor: "rgb(0, 95, 176)",
            height: this.state.screenHeight,
            paddingLeft: 30,
            paddingRight: 30,
            width: this.state.screenWidth,
          }}
        >
          <div className="loginTitle">Login</div>
          <Form style={{marginTop: 50}}>
            <input
              type="text"
              className={this.state.nameWrong ? "loginInputWrong" : "loginInput"}
              onChange={this.onNameChanged}
              placeholder="Your name"
              value={this.state.name}
            />
            <input
              type="password"
              className={this.state.passwordWrong ? "loginInputWrong" : "loginInput"}
              onChange={this.onPasswordChanged}
              placeholder="Your password"
              style={{marginTop: 15}}
              value={this.state.email}
            />
            <div className="loginButton">
              <GreenButton
                onClick={this.onLogin}
                style={{height: 38, width: 217}}
                title="Login"
                titleStyle={{fontSize: 14}}
              />
            </div>
          </Form>
        </div>
      </div>
    );
  }

  render = () => {
    if (this.state.screenWidth < 768) {
      return this.renderMobile();
    }

    return (
      <div className="App page" style={{justifyContent: "center"}}>
        <div
          className="body"
          style={{
            backgroundColor: "rgb(0, 95, 176)",
            height: this.state.screenHeight,
            width: this.state.screenWidth,
          }}
        >
          <div className="loginTitle">Login</div>
          <Form style={{marginTop: 50}}>
            <input
              type="text"
              className={this.state.nameWrong ? "loginInputWrong" : "loginInput"}
              onChange={this.onNameChanged}
              placeholder="Your name"
              value={this.state.name}
            />
            <input
              type="password"
              className={this.state.passwordWrong ? "loginInputWrong" : "loginInput"}
              onChange={this.onPasswordChanged}
              placeholder="Your password"
              style={{marginTop: 15}}
              value={this.state.email}
            />
            <div className="loginButton">
              <GreenButton
                onClick={this.onLogin}
                style={{height: 38, width: 217}}
                title="Login"
                titleStyle={{fontSize: 14}}
              />
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default Login;
