import React, { Component } from "react";

import { Oval } from "react-loader-spinner";
import TextareaAutosize from "react-textarea-autosize";

import PageBlock from "../../../../engine/mongodb/pageblock";
import PageBlockEngine from "../../../../engine/mongodb/pageblocks";

import "../../../admin/styles.css";
import "./styles.css";

const BACKGROUND_SIZE = {
  height: 6686,
  width: 2861,
};

const SCREEN_HEIGHT = 1920;
const SCREEN_WIDTH = 1440;
const SCREEN_ASPECTRATIO = BACKGROUND_SIZE.width / BACKGROUND_SIZE.height;

const MOBILE_SCREEN_HEIGHT = 2590;
const MOBILE_SCREEN_WIDTH = 1440;
const MOBILE_SCREEN_ASPECTRATIO = MOBILE_SCREEN_WIDTH / MOBILE_SCREEN_HEIGHT;

const BACKGROUND_MARGINTOP = 0;
const VIDEO_WIDTH = 333;

class AdminPagesCookie extends Component {
  constructor(props) {
    super(props);

    var screenWidth = window.innerWidth;

    this.state = {
      blocks: [],
      screenWidth: screenWidth,
      loading: false,
    };
  }

  componentDidMount = async () => {
    window.addEventListener("resize", this.onWindowResized);
    await this.refresh();
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.onWindowResized);
  }

  refresh = async () => {
    this.setState({
      loading: true,
    });

    var blocks = await PageBlockEngine.getByPage(PageBlock.PAGE_COOKIE);
    this.setState({
      blocks: blocks,
    });

    this.setState({
      loading: false,
    });
  }

  onWindowResized = () => {
    var screenWidth = window.innerWidth;
    this.setState({
      screenWidth: screenWidth,
    });
  }

  onPressSave = async () => {
    this.setState({
      loading: true,
    });

    await PageBlockEngine.deleteByPage(PageBlock.PAGE_COOKIE);

    var blocks = this.state.blocks;
    await PageBlockEngine.addMultiple(blocks);

    this.setState({
      loading: false,
    });
  }

  onBlockTypeChanged = (block, type) => {
    block.type = type;
    var blocks = this.state.blocks;
    this.setState({
      blocks: blocks,
    });
  }

  onPressBlockDelete = (block) => {
    var blocks = this.state.blocks;
    var index = blocks.findIndex(b => b.docId == block.docId);
    if (index < 0) {
      return;
    }

    blocks.splice(index, 1);

    this.setState({
      blocks: blocks,
    });
  }

  onBlockTextChanged = (block, text) => {
    block.text = text;
    var blocks = this.state.blocks;
    this.setState({
      blocks: blocks,
    });
  }

  onBlockListTextChanged = (block, text, index) => {
    try {
      block.items[index] = text;
      var blocks = this.state.blocks;
      this.setState({
        blocks: blocks,
      });
    } catch(error) {
      console.log("[AdminPagesCookie] onBlockListTextChanged() -> error: ", error);
    }
  }

  onAddBlock = (type) => {
    var block = new PageBlock();
    block.page = PageBlock.PAGE_COOKIE;
    block.type = type;
    var blocks = this.state.blocks;
    blocks.push(block);
    this.setState({
      blocks: blocks,
    });
  }

  onPressBlockListItemDelete = (block, index) => {
    block.items.splice(index, 1);
    var blocks = this.state.blocks;
    this.setState({
      blocks: blocks,
    });
  }

  onPressBlockListItemAdd = (block) => {
    block.items.push("");
    var blocks = this.state.blocks;
    this.setState({
      blocks: blocks,
    });
  }

  renderBlock = (block) => {
    if (block.type == PageBlock.TYPE_LIST_ORDERED || block.type == PageBlock.TYPE_LIST_UNORDERED) {
      return this.renderBlockList(block);
    }

    return (
      <div className="card" style={{marginTop:20}}>
        <div className="card-header">
          <div className="d-flex flex-row">
            <div className="dropdown">
              <button type="button" className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown">
                { PageBlockEngine.getTypeName(block.type) }
              </button>
              <ul className="dropdown-menu">
                <li>
                  <a className={block.type == PageBlock.TYPE_HEADING ? "dropdown-item adminDropDownIemChecked" : "dropdown-item"} href="#" onClick={() => this.onBlockTypeChanged(block, PageBlock.TYPE_HEADING)}>
                    { PageBlockEngine.getTypeName(PageBlock.TYPE_HEADING) }
                  </a>
                </li>
                <li>
                  <a className={block.type == PageBlock.TYPE_SUBHEADING ? "dropdown-item adminDropDownIemChecked" : "dropdown-item"} href="#" onClick={() => this.onBlockTypeChanged(block, PageBlock.TYPE_SUBHEADING)}>
                    { PageBlockEngine.getTypeName(PageBlock.TYPE_SUBHEADING) }
                  </a>
                </li>
                <li>
                  <a className={block.type == PageBlock.TYPE_PARAGRAPH ? "dropdown-item adminDropDownIemChecked" : "dropdown-item"} href="#" onClick={() => this.onBlockTypeChanged(block, PageBlock.TYPE_PARAGRAPH)}>
                    { PageBlockEngine.getTypeName(PageBlock.TYPE_PARAGRAPH) }
                  </a>
                </li>
                <li>
                  <a className={block.type == PageBlock.TYPE_LIST_ORDERED ? "dropdown-item adminDropDownIemChecked" : "dropdown-item"} href="#" onClick={() => this.onBlockTypeChanged(block, PageBlock.TYPE_LIST_ORDERED)}>
                    { PageBlockEngine.getTypeName(PageBlock.TYPE_LIST_ORDERED) }
                  </a>
                </li>
                <li>
                  <a className={block.type == PageBlock.TYPE_LIST_UNORDERED ? "dropdown-item adminDropDownIemChecked" : "dropdown-item"} href="#" onClick={() => this.onBlockTypeChanged(block, PageBlock.TYPE_LIST_UNORDERED)}>
                    { PageBlockEngine.getTypeName(PageBlock.TYPE_LIST_UNORDERED) }
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex-fill"></div>
            <button type="button" className="btn btn-outline-light text-dark" onClick={() => this.onPressBlockDelete(block)}>
              <i className="fa-solid fa-xmark fa-xl"></i>
            </button>
          </div>
        </div>
        <div className="card-body">
          <TextareaAutosize
            className="adminContentTextArea"
            onChange={event => this.onBlockTextChanged(block, event.target.value)}
            placeholder={ PageBlockEngine.getTypeName(block.type) }
            value={block.text}
          />
        </div>
      </div>
    );
  }

  renderBlockList = (block) => {
    return (
      <div className="card" style={{marginTop:20}}>
        <div className="card-header">
          <div className="d-flex flex-row">
            <div className="dropdown">
              <button type="button" className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown">
                { PageBlockEngine.getTypeName(block.type) }
              </button>
              <ul className="dropdown-menu">
                <li>
                  <a className={block.type == PageBlock.TYPE_HEADING ? "dropdown-item adminDropDownIemChecked" : "dropdown-item"} href="#" onClick={() => this.onBlockTypeChanged(block, PageBlock.TYPE_HEADING)}>
                    { PageBlockEngine.getTypeName(PageBlock.TYPE_HEADING) }
                  </a>
                </li>
                <li>
                  <a className={block.type == PageBlock.TYPE_SUBHEADING ? "dropdown-item adminDropDownIemChecked" : "dropdown-item"} href="#" onClick={() => this.onBlockTypeChanged(block, PageBlock.TYPE_SUBHEADING)}>
                    { PageBlockEngine.getTypeName(PageBlock.TYPE_SUBHEADING) }
                  </a>
                </li>
                <li>
                  <a className={block.type == PageBlock.TYPE_PARAGRAPH ? "dropdown-item adminDropDownIemChecked" : "dropdown-item"} href="#" onClick={() => this.onBlockTypeChanged(block, PageBlock.TYPE_PARAGRAPH)}>
                    { PageBlockEngine.getTypeName(PageBlock.TYPE_PARAGRAPH) }
                  </a>
                </li>
                <li>
                  <a className={block.type == PageBlock.TYPE_LIST_ORDERED ? "dropdown-item adminDropDownIemChecked" : "dropdown-item"} href="#" onClick={() => this.onBlockTypeChanged(block, PageBlock.TYPE_LIST_ORDERED)}>
                    { PageBlockEngine.getTypeName(PageBlock.TYPE_LIST_ORDERED) }
                  </a>
                </li>
                <li>
                  <a className={block.type == PageBlock.TYPE_LIST_UNORDERED ? "dropdown-item adminDropDownIemChecked" : "dropdown-item"} href="#" onClick={() => this.onBlockTypeChanged(block, PageBlock.TYPE_LIST_UNORDERED)}>
                    { PageBlockEngine.getTypeName(PageBlock.TYPE_LIST_UNORDERED) }
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex-fill"></div>
            <button type="button" className="btn btn-outline-light text-dark">
              <i className="fa-solid fa-xmark fa-xl"></i>
            </button>
          </div>
        </div>
        <div className="card-body" style={{paddingTop:11}}>
          { block.items.map((text, index) => this.renderBlockListItem(block, text, index)) }
          <button className="btn btn-primary" type="button" style={{fontSize:12, marginTop:10}} onClick={() => this.onPressBlockListItemAdd(block)}>
            <i className="fa-solid fa-plus" style={{marginRight:5}}></i>
            Add Item
          </button>
        </div>
      </div>
    );
  }

  renderBlockListItem = (block, text, index) => {
    return (
      <div className="d-flex flex-row adminContentListItem">
        <TextareaAutosize
          className="flex-fill adminContentListIteamTextArea"
          onChange={event => this.onBlockListTextChanged(block, event.target.value, index)}
          placeholder={ PageBlockEngine.getTypeName(block.type) }
          value={text}
        />
        <button type="button" className="btn btn-outline-light text-dark" onClick={() => this.onPressBlockListItemDelete(block, index)}>
          <i className="fa-solid fa-xmark fa-l"></i>
        </button>
      </div>
    );
  }

  renderMobile = () => {
    // body size
    var screenWidth = this.state.screenWidth - this.props.scrollbarWidth;
    var marginTop = BACKGROUND_MARGINTOP / (BACKGROUND_SIZE.width / this.state.screenWidth);
    var screenHeight = MOBILE_SCREEN_HEIGHT * (screenWidth / MOBILE_SCREEN_WIDTH);
    screenHeight = MOBILE_SCREEN_HEIGHT / MOBILE_SCREEN_ASPECTRATIO; //screenWidth / SCREEN_ASPECTRATIO - marginTop;

    // body margin
    var bodyMarginHorizontal = (screenWidth - VIDEO_WIDTH * 4) / 2;

    // participants width
    var contentWidth = screenWidth - 20;

    var availableCount = Math.floor((contentWidth + 10) / 42);
    var participantCount = availableCount - 1 + 8;
    var morePariticipantCount = participantCount - availableCount + 1;

    var participants = [];
    for (var i = 0; i < availableCount - 1; i++) {
      participants.push({
        name: "",
      });
    }

    return (
      <div className="App page">
      </div>
    );
  }

  render = () => {
    if (this.state.screenWidth < 768) {
      return this.renderMobile();
    }

    // body size
    var screenWidth = this.state.screenWidth - this.props.scrollbarWidth;
    var marginTop = BACKGROUND_MARGINTOP / (BACKGROUND_SIZE.width / this.state.screenWidth);
    var screenHeight = SCREEN_HEIGHT * (screenWidth / SCREEN_WIDTH);
    screenHeight = SCREEN_HEIGHT / SCREEN_ASPECTRATIO; //screenWidth / SCREEN_ASPECTRATIO - marginTop;

    // body margin
    var bodyMarginHorizontal = (screenWidth - VIDEO_WIDTH * 4) / 2;

    // participants width
    var contentWidth = (screenWidth - 35 * 2);
    if (contentWidth > 534 + 801) {
      contentWidth = 534 + 801;
    }
    contentWidth -= 142;

    var availableCount = Math.floor((contentWidth + 10) / 50);
    var participantCount = availableCount - 1 + 8;
    var morePariticipantCount = participantCount - availableCount + 1;

    var participants = [];
    for (var i = 0; i < availableCount - 1; i++) {
      participants.push({
        name: "",
      });
    }

    return (
      <main className="d-flex flex-nowrap">
        <div className="d-flex flex-column flex-shrink-0 p-3 text-bg-dark" style={{width:280}}>
          <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
            <span className="fs-4">Admin Panel</span>
          </a>
          <hr />
          <ul className="nav nav-pills flex-column mb-auto">
            <li className="nav-item">
              <a href="/admin/cookie" className="nav-link active" aria-current="page">Cookie Policy</a>
            </li>
            <li>
              <a href="#" className="nav-link text-white">Dashboard</a>
            </li>
            <li>
              <a href="#" className="nav-link text-white">Orders</a>
            </li>
            <li>
              <a href="#" className="nav-link text-white">Products</a>
            </li>
            <li>
              <a href="#" className="nav-link text-white">Customers</a>
            </li>
          </ul>
        </div>
        <div className="adminContent flex-fill">
          <nav className="navbar navbar-expand-sm" style={{padding:0}}>
            <div className="container-fluid">
              <a className="navbar-brand adminContentTitle" href="#">Cookie Policy</a>
              <form className="d-flex">
                <button className="btn btn-primary" type="button" onClick={this.onPressSave}>Save</button>
              </form>
            </div>
          </nav>
          <hr />
          { this.state.blocks.map((block, index) => this.renderBlock(block))}
          <div className="dropdown" style={{marginTop:30}}>
            <button type="button" className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown">
              <i className="fa-solid fa-plus fa-l" style={{marginRight:5}}></i>
              Add block
            </button>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" href="#" onClick={() => this.onAddBlock(PageBlock.TYPE_HEADING)}>
                  { PageBlockEngine.getTypeName(PageBlock.TYPE_HEADING) }
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#" onClick={() => this.onAddBlock(PageBlock.TYPE_SUBHEADING)}>
                  { PageBlockEngine.getTypeName(PageBlock.TYPE_SUBHEADING) }
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#" onClick={() => this.onAddBlock(PageBlock.TYPE_PARAGRAPH)}>
                  { PageBlockEngine.getTypeName(PageBlock.TYPE_PARAGRAPH) }
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#" onClick={() => this.onAddBlock(PageBlock.TYPE_LIST_ORDERED)}>
                  { PageBlockEngine.getTypeName(PageBlock.TYPE_LIST_ORDERED) }
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#" onClick={() => this.onAddBlock(PageBlock.TYPE_LIST_UNORDERED)}>
                  { PageBlockEngine.getTypeName(PageBlock.TYPE_LIST_UNORDERED) }
                </a>
              </li>
            </ul>
          </div>

          { this.state.loading &&
            <div className="spinnerContainer">
              <Oval
                ariaLabel='oval-loading'
                color="#72C500"
                height={60}
                secondaryColor="#72C500"
                strokeWidth={3}
                strokeWidthSecondary={3}
                visible={true}
                width={60}
                wrapperStyle
                wrapperClass="spinner"
              />
            </div>
          }
        </div>
      </main>
    );
  }
}

export default AdminPagesCookie;
