import { ObjectId } from "bson";

import MongoEngine from ".";
import PageBlock from "./pageblock";

export default class PageBlockEngine {
  static async add(block) {
    try {
      var db = await MongoEngine.getDB();
      var result = await db.collection("WebPageBlock").insertOne(block);
      block._id = result.insertedId;
    } catch(error) {
      console.log("[PageBlockEngine] add() -> error: ", error);
    }
  }

  static async addMultiple(blocks) {
    try {
      var db = await MongoEngine.getDB();
      var result = await db.collection("WebPageBlock").insertMany(blocks);
    } catch(error) {
      console.log("[PageBlockEngine] addMultiple() -> error: ", error);
    }
  }

  static async delete(block) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("WebPageBlock");
      var filter = {
        docId: block.docId,
      };
      var result = await collection.deleteOne(filter);
    } catch(error) {
      console.log("[PageBlockEngine] delete() -> error: ", error);
    }
  }

  static async deleteByPage(page) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("WebPageBlock");
      var filter = {
        page: page,
      };
      var result = await collection.deleteMany(filter);
    } catch(error) {
      console.log("[PageBlockEngine] deleteByPage() -> error: ", error);
    }
  }

  static fromJson = (json) => {
    var block = new PageBlock();
    try {
      if (json._id != undefined) {
        block._id = new ObjectId(json._id);
      }

      if (json.docId != undefined && json.docId != null && json.docId != "") {
        block.docId = json.docId;
      } else {
        block.docId = block._id.valueOf();
      }

      if (json.page != undefined) {
        block.page = json.page;
      }

      if (json.type != undefined) {
        block.type = json.type;
      }

      if (json.text != undefined) {
        block.text = json.text;
      }

      if (json.items != undefined) {
        json.items.forEach(text => {
          block.items.push(text);
        });
      }
    } catch(error) {
      console.log("[PageBlockEngine] fromJson() -> error: ", error);
    }

    return block;
  }

  static async getByPage(page) {
    var blocks = [];
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("WebPageBlock");
      var filter = {
        page: page,
      };

      var mongoBlocks = await collection.find(filter);
      mongoBlocks.forEach(mongoBlock => {
        var block = PageBlockEngine.fromJson(mongoBlock);
        blocks.push(block);
      });
    } catch(error) {
      console.log("[PageBlockEngine] getByPage() -> error: ", error);
    }

    return blocks;
  }

  static getTypeName = (type) => {
    switch(type) {
      case PageBlock.TYPE_HEADING:
        return "Heading";
      case PageBlock.TYPE_SUBHEADING:
        return "Subheading";
      case PageBlock.TYPE_PARAGRAPH:
        return "Paragraph";
      case PageBlock.TYPE_LIST_ORDERED:
        return "Ordered list";
      case PageBlock.TYPE_LIST_UNORDERED:
        return "Unordered list";
    }

    return "Unknown";
  }

  static async update(block) {
    try {
      var db = await MongoEngine.getDB();
      var collection = db.collection("WebPageBlock");
      block.updatedTimestamp = new Date();
      var result = await collection.updateOne(
        { _id: block._id },
        { $set: block },
        { upsert: true },
      );
    } catch(error) {
      console.log("[PageBlockEngine] update() -> error: ", error);
    }
  }
}
