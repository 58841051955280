import * as Realm from "realm-web";

export default class MongoEngine {
  static APP_ID = "yamu-ecbxl";
  static USER_EMAIL = "dev@yamu.ai";
  static EMAIL_PASSWORD = "Mungab-datke1-juvsod";
  static API_KEY = "u5ajDlHjISLa4uiLJBQbdd0RQCCwck6whZEPMovxZnibTNMkC3KOjs7nGqvExvW6";

  static app = null;
  static db = null;
  static realm = null;

  static getDB = async () => {
    // if (MongoEngine.db != null) {
    //   return MongoEngine.db;
    // }

    var app = Realm.App.getApp(MongoEngine.APP_ID);
    if (app && app.currentUser) {
      var mongodb = app.currentUser.mongoClient("mongodb-atlas");
      var db = mongodb.db("Yamu");
      MongoEngine.db = db;
      return db;
    }

    try {
      var appConfig = {
        id: MongoEngine.APP_ID,
      };

      var app = new Realm.App(appConfig);

      // authenticate
      var credentials = Realm.Credentials.emailPassword(MongoEngine.USER_EMAIL, MongoEngine.EMAIL_PASSWORD);
      var user = await app.logIn(credentials);

      // db
      var mongodb = app.currentUser.mongoClient("mongodb-atlas");
      var db = mongodb.db("Yamu");

      MongoEngine.db = db;
      return db;
    } catch(error) {
      console.log("[MongoEngine] getDB() -> error: ", error);
    }

    return null;
  }
}
