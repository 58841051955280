import React, { Component } from "react";

import "video-react/dist/video-react.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from "@fortawesome/free-solid-svg-icons";

import Header from "../components/header";
import Footer from "../components/footer";

import AppStoreButton from "../components/buttons/appstore";
import GoogleStoreButton from "../components/buttons/googlestore";

import "../../App.css";
import "./styles.css";

import backgroundImage from "../../res/images/img-home-background.png";

const BACKGROUND_SIZE = {
  height: 27000,
  width: 2861,
};

const SCREEN_HEIGHT = 1920;
const SCREEN_WIDTH = 1440;
const SCREEN_ASPECTRATIO = BACKGROUND_SIZE.width / BACKGROUND_SIZE.height;

const MOBILE_SCREEN_HEIGHT = 6120;
const MOBILE_SCREEN_WIDTH = 1440;
const MOBILE_SCREEN_ASPECTRATIO = MOBILE_SCREEN_WIDTH / MOBILE_SCREEN_HEIGHT;

const BACKGROUND_MARGINTOP = 0;
const VIDEO_WIDTH = 333;

class Terms extends Component {
  constructor(props) {
    super(props);

    var screenWidth = window.innerWidth;

    this.state = {
      screenWidth: screenWidth,
      participants: [],
      availableCount: 0,
    };
  }

  componentDidMount = async () => {
    window.addEventListener("resize", this.onWindowResized);
    await this.refresh();
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.onWindowResized);
  }

  refresh = async () => {
    window.scrollTo(0, 0);
  }

  onWindowResized = () => {
    var screenWidth = window.innerWidth;
    this.setState({
      screenWidth: screenWidth,
    });
  }

  renderMobile = () => {
    // body size
    var screenWidth = this.state.screenWidth - this.props.scrollbarWidth;
    var marginTop = BACKGROUND_MARGINTOP / (BACKGROUND_SIZE.width / this.state.screenWidth);
    var screenHeight = MOBILE_SCREEN_HEIGHT * (screenWidth / MOBILE_SCREEN_WIDTH);
    screenHeight = MOBILE_SCREEN_HEIGHT / MOBILE_SCREEN_ASPECTRATIO; //screenWidth / SCREEN_ASPECTRATIO - marginTop;

    // body margin
    var bodyMarginHorizontal = (screenWidth - VIDEO_WIDTH * 4) / 2;

    // participants width
    var contentWidth = screenWidth - 20;
    var availableCount = Math.floor((contentWidth + 10) / 42);
    var participantCount = availableCount - 1 + 8;
    var morePariticipantCount = participantCount - availableCount + 1;

    var participants = [];
    for (var i = 0; i < availableCount - 1; i++) {
      participants.push({
        name: "",
      });
    }

    return (
      <div className="App page">
        <Header
          // backgroundImage={backgroundImage}
          // backgroundMarginTop={marginTop}
          isMobile={true}
          screen="terms"
          screenSize={{
            height: screenWidth / SCREEN_ASPECTRATIO,
            width: screenWidth,
          }}
        />
        <div
          className="body"
          style={{
            backgroundColor: "rgb(0, 95, 176)",
            backgroundImage:`url(${backgroundImage})`,
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            height: screenHeight,
            width: screenWidth,
          }}
        >
          <div className="termsMobileBody">
            <div className="termsMobileTitle">Terms & Conditions</div>
            <div className="termsMobileDescription">
              Last updated [8th December 2022]
            </div>
            <hr className="hr"/>
            <div className="termsMobileSubTitle">AGREEMENT TO TERMS</div>
            <div className="termsMobileDescription">
              These Terms of Service constitute a legally binding agreement between you, individually or on behalf of an entity ("you"), and Yamu Ltd  ("we," "us," or "our") regarding your access to and use of the http://www.enviromates.earth website or our mobile application (“Enviromates” or “App” or “Application”) and any other media form, media channel, mobile website related, linked, or otherwise connected thereto (collectively the "Site").
            </div>
            <div className="termsMobileDescription">
              You acknowledge that by accessing the Site, you have read, comprehended, and consent to be bound by these whole Terms of Service. If you do not agree with these Terms of Service in their entirety, you are explicitly barred from using the Site and must immediately terminate use.
            </div>
            <div className="termsMobileDescription">
              Additional terms and conditions or documents that may from time to time be posted on the Site are expressly incorporated herein by this reference. We reserve the right, at any time and for any cause, to make changes or revisions to these Terms of Service at our sole discretion.
            </div>
            <div className="termsMobileDescription">
              We shall notify you of any modifications by revising the "Last updated" date in these Terms of Service, and you waive your right to obtain explicit notification of any modification. You are responsible for frequently reviewing these Terms of Service for modifications. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any updated Terms of Use by continuing to use the Site after such revised Terms of Use have been posted.
            </div>
            <div className="termsMobileDescription">
              This information is not intended for distribution to or use by any individual or organization in any jurisdiction or country where such distribution or use would be contrary to law or regulation or would subject us to any registration obligation within such jurisdiction or country. Those who access the Site from other locations do so voluntarily and are solely responsible for compliance with local laws, if and to the extent local laws are relevant.
            </div>
            <div className="termsMobileDescription">
              The Website is designed for users over the age of 18. All users who are minors in their home jurisdiction (usually under the age of 18) are required to have parental consent and direct supervision in order to access the Site. Prior to using the Site if you are a minor, your parent or legal guardian must read and consent to these Terms of Service. 
            </div>
            <div className="termsMobileSubTitle">INTELLECTUAL PROPERTY RIGHTS</div>
            <div className="termsMobileDescription">
              Unless otherwise specified, the Site is our exclusive property, and all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the Site (collectively, the "Content") and trademarks, service marks, and logos contained therein (the "Marks") are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws as well as various other intellectual property rights and unfair competition laws. The Site provides the Content and Marks "AS IS" for your information and personal use only. No portion of the Site and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, licensed, or otherwise exploited for any commercial purpose whatsoever, except as expressly permitted in these Terms of Service.
            </div>
            <div className="termsMobileDescription">
              You are granted a limited license to access and use the Site, as well as to download or print a copy of any portion of the Content to which you have lawfully gained access, solely for your own private, non-commercial use. This is granted as long as you are qualified to use the Site. We hold all ownership rights to the Site, the Content, and the Marks that are not otherwise explicitly granted to you.
            </div>
            <div className="termsMobileSubTitle">USER REPRESENTATIONS</div>
            <div className="termsMobileDescription">
              You affirm and guarantee by using the website that:
              <ol type="a">
                <li>You certify that any registration information you provide is true, accurate, up-to-date, and comprehensive.</li>
                <li>You will keep such information accurate and immediately update such registration information as required;</li>
                <li>you agree to abide by these Terms of Use and are of legal age;</li>
                <li>if you are a minor, your parents have given you permission to use the Site if you are not a minor in the country in which you now reside;</li>
                <li>You won't use a bot, script, or any other automated or non-human method to access the Site;</li>
                <li>you won't utilize the website for any improper or prohibited purposes;</li>
                <li>You won't break any laws or regulations by using the Site.</li>
              </ol>
            </div>
            <div className="termsMobileDescription">
              We reserve the right to suspend or cancel your account and prohibit any and all current or future use of the Site in the event that you provide any information that is untrue, inaccurate, out-of-date, or incomplete (or any portion thereof). 
            </div>
            <div className="termsMobileSubTitle">ENVIROMATES SERVICES</div>
            <div className="termsMobileDescription">
              The application has been designed to be grassroots crowdfunding platform, enabling environmentalists from all around the world to create, fund and share updates on environmental projects.
            </div>
            <div className="termsMobileDescription">
              In lieu of traditional monetary donations (which are still allowed), we've developed a browser that enables consumers to passively earn money when they make purchases and receive a portion back that they can pledge to environmental projects of their choosing. We also provide a variety of advertising solutions where users are paid directly creating a peer-to-peer advertising platform.
            </div>
            <div className="termsMobileDescription">
              To aid environmentalists in managing their initiatives, we've developed a social media platform that allows projects to quickly and effectively update their supporters and followers.
            </div>
            <div className="termsMobileDescription">
              <strong>Minimum age</strong> - The Enviromates Crowdfunding platform is not intended for users under the age of 13. You are not allowed to use Enviromates Crowdfunding if you are under the age of 13. You can only create an Enviromates Crowdfunding page if you have your parent's or guardian's permission if you are between the ages of 13 and 18. Make sure your parent or legal guardian is aware that you are in charge of utilizing the money in accordance with these Terms. When utilizing Enviromates' services or setting up a fundraising page, minors must be directly supervised by an adult.
            </div>
            <div className="termsMobileDescription">
              Please email us at assistme@enviromates.earth right away if you have any concerns about a child using our services.
            </div>
            <div className="termsMobileSubTitle">USER REGISTRATION</div>
            <div className="termsMobileDescription">
              There is no requirement for the user to create an account. The browser can be utilized in the same manner as Chrome or Safari. You will get access to view environmental projects. However, registration is required to earn any passive income, pledge to projects, access some social media features, and post comments. 
            </div>
            <div className="termsMobileDescription">
              If you attempt to do an action that requires registration, the registration form will appear. When you create an account on the site, you agree to keep your password secure and are responsible for any activities that occur under your account and password. We reserve the right to remove, reclaim, or alter a username you choose if, in our sole discretion, we think that it is improper, offensive, or otherwise objectionable.
            </div>
            <div className="termsMobileSubTitle">PRODUCTS</div>
            <div className="termsMobileDescription">
              There are four sections to the Enviromates App
            </div>
            <div className="termsMobileDescription">
              <strong>Browser</strong> - In this case, you use the Browser (similar to Chrome or Safari) to search the web and collect a portion of the sale from subscribing brands. Earned funds expressed as hearts can only be used to fund environmental projects on the platform. If a crowdfunding project, for any specific project is unsuccessful or the project creator terminates the campaign early, the funds are restored to the user's app wallet.
            </div>
            <div className="termsMobileDescription">
              App users also have access to a wallet that displays earnings, and there are other pages that users can select complete to disclose further information.
            </div>
            <div className="termsMobileDescription">
              <strong>Environmental Creators</strong> – Utilise the social media site to enable creators to establish environmental initiatives, share information, publish updates, and respond to messages.
            </div>
            <div className="termsMobileDescription">
              Environmental creators can establish personalized crowdfunding campaigns and provide periodic updates to backers to demonstrate the project's development. Users can also provide feedback on projects.
            </div>
            <div className="termsMobileDescription">
              <strong>Advertisers</strong> – Directly upload advertising to serve to users of Enviromates.
            </div>
            <div className="termsMobileDescription">
              <strong>Brands/Organisations</strong> – Can submit ready-to-launch projects that once vetted will be available for others to select and crowdfund. Brands and organization are also able to create projects and become environmental creators.
            </div>
            <div className="termsMobileDescription">
              We make every attempt to display the colors, features, specs, and specifics of everything offered on the website as accurately as possible. However, we cannot guarantee that the colors, features, specs, and details will be accurate, complete, reliable, current, or error-free, and your electronic display may not reflect the actual colors and details.
            </div>
            <div className="termsMobileSubTitle">PRICING</div>
            <div className="termsMobileDescription">
              There are no fees or costs associated with using the app. The listing of environmental projects is free. There is no cost associated with using the browser. Only a percentage of advertising revenue and eco-cashback will be retained by the company to pay salaries and invest in continued R&D to improve the service to everyone. 
            </div>
            <div className="termsMobileSubTitle">REFUND POLICY</div>
            <div className="termsMobileDescription">
              <strong>Enviromates is not a store and we do not issue refunds.</strong> When you back an environmental project you do so on the basis you are supporting an environmental creator’s project and pledged as a donation. 
            </div>
            <div className="termsMobileDescription">
              When a project is successfully backed the environmental creator is responsible for completing the project to the best of their abilities and to provide feedback to supporters what happens. Enviromates does not guarantee the quality of an environmental creators' work. 
            </div>
            <div className="termsMobileDescription">
              Once a pledge is made it is not possible to cancel. If the environmental creator cancels the project before they reach their target or is not ultimately not successful the hearts pledged will be returned back to the user's app wallet.
            </div>
            <div className="termsMobileSubTitle">PROHIBITED ACTIVITIES</div>
            <div className="termsMobileDescription">
              <strong>Enviromates is not a store and we do not issue refunds.</strong> When you back an environmental project you do so on the basis you are supporting an environmental creator’s project and pledged as a donation. 
            </div>
            <div className="termsMobileDescription">
              You agree, as a user of the Site, not to:
              <ol type="a">
                <li>Systematically retrieve data or other content from the Site for the purpose of creating or compiling, directly or indirectly, a collection, compilation, database, or directory without our explicit consent.</li>
                <li>Make any unauthorized use of the Site, such as gathering usernames and/or email addresses of users using electronic or other means for the purpose of sending unsolicited email, or creating user accounts through automated means or under false pretenses.</li>
                <li>Use a purchasing agent or buying agent to make purchases on the Site.</li>
                <li>Utilize the Site to promote or offer to sell products or services.</li>
                <li>Bypass, deactivate, or otherwise interfere with security-related aspects of the Site, including features that prevent or restrict the use or copying of any Content or enforce usage limitations on the Site and/or its Content.</li>
                <li>engage in illegal framing or linking to the Site.</li>
                <li>Trick, defraud, or mislead us or other users, including in an attempt to obtain sensitive account information such as user passwords; make improper use of our support services; or submit fraudulent claims of misconduct or abuse.</li>
                <li>No automated use of the system is permitted, including the use of scripts to send comments or messages, data mining, robots, or similar data collection and extraction techniques.</li>
                <li>Interfere with, disrupt, or impose an unreasonable load on the Website or the networks or services connected to the Website.</li>
                <li>attempt to assume the identity of another user or person, or use another user's username.</li>
                <li>sell or transfer your profile in some other way.</li>
                <li>Use any information obtained from the Site to harass, abuse, or otherwise cause harm to another user.</li>
                <li>You may not use the Site as part of any effort to compete with us or utilize the Site and/or Content for any other revenue-generating or commercial purpose.</li>
                <li>You may not decrypt, decompile, disassemble, or otherwise reverse-engineer any of the software comprising or otherwise constituting any portion of the Site.</li>
                <li>attempt to circumvent any Site measures intended to prevent or limit access to the Site or any portion thereof.</li>
                <li>You may not harass, irritate, intimidate, or threaten any of our employees or agents involved in supplying you with any portion of our website.</li>
                <li>Remove the notice of copyright or other property rights from the Content.</li>
                <li>You may not copy or modify the software on the Site, including but not limited to Flash, PHP, HTML, JavaScript, or other code.</li>
                <li>upload or transmit (or attempt to upload or transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with the uninterrupted use and enjoyment of the Site by any party, or that modifies, impairs, disrupts, or interferes with the use, features, functions, operation, or maintenance of the Site.</li>
                <li>upload or transmit (or attempt to upload or transmit) any material that acts as a passive or active information collection or transmission mechanism, including, but not limited to, clear graphics interchange formats ("gifs"), 11 pixels, web bugs, cookies, or other similar devices (sometimes known as "spyware" or "passive collection mechanisms" or "pcms").</li>
                <li>Use, launch, develop, or distribute any automated system, including, but not limited to, any spider, robot, cheat utility, scraper, or offline reader that accesses the Site, or use or launch any unauthorized script or other software, except as may be the result of standard search engine or Internet browser usage.</li>
                <li>Denigrate, taint, or otherwise harm us and/or the Site, in our opinion.</li>
                <li>Use the Website in violation of any applicable laws or regulations.</li>
              </ol>
            </div>
            <div className="termsMobileSubTitle">USER GENERATED CONTRIBUTIONS</div>
            <div className="termsMobileDescription">
              The Site may invite you to participate in chat, blogs, message boards, online forums, and other features and may give you the chance to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or on the Site, such as but not limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or other material containing personal information (collectively, "Contributions").
            </div>
            <div className="termsMobileDescription">
              Other Site users and third-party websites may be able to view contributions. Any Contributions you send could therefore be considered non-confidential and non-proprietary. When you generate Contributions or make them available, you represent and warrant that:
              <ol type="a">
                <li>Creation, distribution, transmission, public display or performance, as well as accessing, downloading, or copying of your Contributions, do not and will not violate the intellectual rights of any third party, including but not limited to copyright, patent, trademark, trade secret, or moral rights.</li>
                <li>You are the creator and owner of your Contributions, or you have the appropriate licenses, rights, consents, releases, and permissions to use and authorize us, the Site, and other Site users to use your Contributions in any way permitted by the Site and these Terms of Service.</li>
                <li>You have the written approval, release, and/or authorization of each and every identifiable individual in your Contributions to enable inclusion and use of your Contributions in any manner authorized by the Site and these Terms of Service.</li>
                <li>Your Contributions are neither fake nor wrong.</li>
                <li>Your Contributions are not unsolicited or unlawful advertising, promotional materials, pyramid schemes, chain letters, spam, or mass mailings.</li>
                <li>Your Contributions must not be obscene, lewd, lascivious, filthy, violent, harassing, libelous, or otherwise inappropriate (as determined by us).</li>
                <li>Your Contributions do not mock, degrade, intimidate, or abuse anyone.</li>
                <li>Your contributions do not advocate for the violent overthrow of any government or incite, urge, or threaten physical damage against another individual.</li>
                <li>Your Contributions comply with all applicable laws, rules, and regulations.</li>
                <li>your Contributions do not breach the privacy or publicity rights of any third party.</li>
                <li>your Contributions do not contain any content that solicits personal information from anybody under the age of 18 or exploits anyone under the age of 18 in a sexual or violent manner.</li>
                <li>your Contributions do not violate any federal or state legislation prohibiting child pornography, or otherwise intended to protect the health or well-being of minors;</li>
                <li>your Contributions do not include any offensive comments that are connected to race, country origin, gender, sexual preference, or physical impairment.</li>
                <li>Your Contributions do not otherwise violate any aspect of these Terms of Service or any relevant law or regulation, nor do they link to content that does.</li>
              </ol>
            </div>
            <div className="termsMobileDescription">
              Any use of the Site in violation of the aforementioned may result in termination or suspension of your rights to use the Site. 
            </div>
            <div className="termsMobileSubTitle">CONTRIBUTION LICENSE</div>
            <div className="termsMobileDescription">
              You automatically grant us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right and license to host, use, copy, reproduce, disclose, sell, resell, publish your Contributions on the Site or make them accessible to the Site by linking your account on the Site to any of your social networking accounts. You also represent and warrant that you have the right to grant this license. Any media channels and any media formats are acceptable for use and distribution. 
            </div>
            <div className="termsMobileDescription">
              This license includes our use of your name, company name, and franchise name, as applicable, as well as any trademarks, service marks, trade names, logos, and personal and commercial images you supply. You renounce all moral rights to your Contributions, and you warrant that no moral rights have been asserted against them.
            </div>
            <div className="termsMobileDescription">
              We make no claim to ownership of your Contributions. You retain complete ownership of all of your Contributions and associated intellectual property rights and other proprietary rights. We are not responsible for any claims or representations made in your Contributions in any area of the Site.
            </div>
            <div className="termsMobileDescription">
              You are entirely responsible for your Contributions to the Site, and you expressly agree to absolve us of any liability and to refrain from bringing any legal action against us in connection with your Contributions.  
            </div>
            <div className="termsMobileDescription">
              We reserve the right, in our sole and absolute discretion, to (1) edit, redact, or otherwise modify any Contributions; (2) re-categorize any Contributions to place them in more appropriate locations on the Site; and (3) pre-screen or delete any Contributions at any time and for any reason, without notice. We are under no responsibility to oversee your Contributions. 
            </div>
            <div className="termsMobileSubTitle">GUIDELINES FOR REVIEWS</div>
            <div className="termsMobileDescription">
              We may provide locations on the Site where you can leave comments or ratings. The following requirements must be met while publishing a review:
              <ol type="a">
                <li>You should have direct knowledge with the individual or organization being examined;</li>
                <li>Your reviews should not contain profanity or language that is abusive, racial, insulting, or hateful;</li>
                <li>Your evaluations should not contain any references that are biased based on religion, ethnicity, gender, national origin, age, marital status, sexual orientation, or disability;</li>
                <li>Your reviews should not contain unlawful activity references;</li>
                <li>When publishing unfavorable evaluations, you should not be linked with competitors.</li>
                <li>You should not draw any inferences on the legality of conduct;</li>
                <li>you are not permitted to publish any false or misleading statements;</li>
                <li>You are not permitted to create a campaign urging others to publish reviews, regardless of whether they are positive or negative.</li>
              </ol>
              We have the right to approve, reject, or remove reviews at our discretion. We are under no responsibility whatsoever to moderate or remove reviews, even if someone finds them offensive or false. We do not endorse reviews, nor do they necessarily reflect our opinions or those of our affiliates or partners.
            </div>
            <div className="termsMobileDescription">
              We assume no responsibility for any review or any claims, liabilities, or losses that may emerge from any review. By submitting a review, you offer us a perpetual, non-exclusive, worldwide, royalty-free, fully-paid, assignable, and sublicensable right and license to reproduce, edit, translate, transmit by any means, display, perform, and/or distribute any and all review-related content.
            </div>
            <div className="termsMobileSubTitle">MOBILE APPLICATION LICENSE</div>
            <div className="termsMobileSubTitleSub">Use License</div>
            <div className="termsMobileDescription">
              If you access the Site through a mobile application, we grant you a limited, revocable, non-exclusive, and non-transferable license to install and use the mobile application on wireless electronic devices that you own or control, and to access and use the mobile application on such devices strictly in accordance with the terms and conditions of this mobile application license set forth in these Terms of Service. 
            </div>
            <div className="termsMobileDescription">
              You must not:
              <ol type="a">
                <li>decompile, reverse engineer, disassemble, or attempt to derive the application's source code;</li>
                <li>make any changes, adaptations, improvements, translations, or derivative works to the application;</li>
                <li>violate any applicable laws, rules, or regulations relating to your access to or use of the application;</li>
                <li>remove, modify, or conceal any proprietary notice (including notices of copyright or trademark) displayed by us or the application's licensors;</li>
                <li>Use the application for any revenue-generating, commercial, or other unintended purpose.</li>
                <li>provide the application through a network or other environment that permits simultaneous access or use by numerous devices or users;</li>
                <li>Create a product, service, or software that directly or indirectly competes with or is an alternative for the application.</li>
                <li>Use the program to send automated queries to any website or unsolicited commercial e-mail;</li>
                <li>Use any of our proprietary information, interfaces, or other intellectual property in the design, development, manufacturing, licensing, or distribution of apps, accessories, or devices for use with the application.</li>
              </ol>
            </div>
            <div className="termsMobileSubTitleSub">Apple Devices</div>
            <div className="termsMobileDescription">
              When you access the Site through a mobile application downloaded from the Apple Store ("App Distributor"), the following conditions apply:
              <ol type="a">
                <li>The license we grant you for our mobile app is restricted to a non-transferable license to use it on a device running the Apple iOS, as appropriate, and in accordance with the usage guidelines described in the terms of service of the App Distributor;</li>
                <li>You acknowledge that the App Distributor has no obligation whatsoever to provide any maintenance and support services with respect to the mobile application, and that we are responsible for providing any such services as specified in the terms and conditions of this mobile application license contained in these Terms of Use or as otherwise required by applicable law.</li>
                <li>If the mobile application does not meet any applicable warranty, you may contact the relevant app distributor, and that distributor may, in accordance with its terms and policies, refund any money paid for the mobile application's purchase; however, to the fullest extent permitted by applicable law, the applicable app distributor will have no other warranty obligation with respect to the mobile application;</li>
                <li>You affirm and guarantee that you do not reside in a nation under U.S. embargo or one that the government has designated as "terrorist supporting," and that your name does not appear on any list of individuals or entities that the government has restricted or prohibited;</li>
                <li>When using the mobile application, you must adhere to any applicable third-party terms of agreement; for instance, if you use a VoIP application, you must not violate their wireless data service agreement;</li>
                <li>You acknowledge and agree that the App Distributors are third-party beneficiaries of the terms and conditions in this mobile application license contained in these Terms of Use. As such, you acknowledge and agree that each App Distributor will have the right (and will be deemed to have accepted the right) to enforce the terms and conditions in this mobile application license contained in these Terms of Use against you.</li>
              </ol>
            </div>
            <div className="termsMobileSubTitle">SOCIAL MEDIA</div>
            <div className="termsMobileDescription">
              As part of the functionality of the Site, you may link your account with online accounts you have with third-party service providers (each such account, a "Third-Party Account"). To do this, you can either: provide your Third-Party Account login information through the Site; or grant us access to your Third-Party Account, as permitted by the applicable terms and conditions that govern your use of each Third-Party Account.
            </div>
            <div className="termsMobileDescription">
              You affirm and warrant that you have the right to provide us with your third-party account login information and/or access to your third-party account, without violating any of the rules governing your use of the relevant third-party account, without charging us any fees, and without putting any restrictions on our use of the account by the third-party service provider.
            </div>
            <div className="termsMobileDescription">
              By giving us permission to access any Third-Party Accounts, you agree that we may access, make available, and store (as appropriate) any content you have provided to and stored in your Third-Party Account (the "Social Network Content") so that it is accessible on and through the Site via your account, including without limitation any friend lists. You also agree that we may submit to and receive from your Third-Party Account additional information to the extent you are informed when we do so. 
            </div>
            <div className="termsMobileDescription">
              Depending on the Third-Party Accounts you choose and the privacy settings on those Third-Party Accounts, personally identifiable information that you submit to your Third-Party Accounts may be accessible on and through your account on the Site. Please be aware that if a Third-Party Account or associated service becomes unavailable, or if our access to such Third-Party Account is terminated by the third-party service provider, then Social Network Content may no longer be accessible via the Site.
            </div>
            <div className="termsMobileDescription">
              You will always have the option to discontinue the link between your Site account and your Third-Party Accounts. PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY THE AGREEMENT(S) YOU HAVE ENTERED INTO WITH SUCH THIRD-PARTY SERVICE PROVIDERS.
            </div>
            <div className="termsMobileDescription">
              We make no effort to check any Social Network Content, including for accuracy, legality, or non-infringement, and we are not responsible for any Social Network Content. You agree that we may access your email address book associated with a Third-Party Account and your contacts list stored on your mobile device or tablet computer for the express purpose of identifying and notifying you of those contacts who have also registered to use the Site.
            </div>
            <div className="termsMobileDescription">
              You may deactivate the link between the Site and your Third-Party Account by contacting us using the details shown below, or by accessing your account settings (if applicable). We will endeavour to remove any information saved on our servers that was accessed through a Third-Party Account, with the exception of your username and profile picture.
            </div>
            <div className="termsMobileSubTitle">SUBMISSIONS</div>
            <div className="termsMobileDescription">
              You understand and agree that any questions, comments, suggestions, ideas, feedback, or other information you give to us on the Site ("Submissions") are non-confidential and become our sole property. We shall own all rights, including intellectual property rights, and shall be entitled to the unlimited use and dissemination of these Submissions for any lawful purpose, commercial or otherwise, without acknowledgment or payment to you.
              <br/>You relinquish all moral rights to such Submissions and warrant that such Submissions are your original work or that you have the right to submit them. You agree that we shall not be liable for any alleged or actual infringement or misappropriation of any proprietary right in connection with your Submissions. 
            </div>
            <div className="termsMobileSubTitle">THIRD-PARTY WEBSITES AND CONTENT</div>
            <div className="termsMobileDescription">
              Links to other websites ("Third-Party Websites"), as well as articles, photos, text, graphics, pictures, designs, music, sound, video, information, applications, software, and other content or items belonging to or originating from third parties, may be found on the Site or you may be sent via the Site ("Third-Party Content").
            </div>
            <div className="termsMobileDescription">
              We do not review, monitor, or check the accuracy, suitability, or completeness of any such Third-Party Websites or Third-Party Content, and we do not assume any liability or responsibility for any such Third-Party Websites or Third-Party Content that is posted on, made available through, or installed from the Site, including the accuracy, offensiveness, opinions, reliability, privacy practices, or other guidelines of or contained in the Third-Party Websites or the Third-Party Content.
            </div>
            <div className="termsMobileDescription">
              No approval or endorsement by us is implied by the inclusion of, linking to, or allowing the use or installation of any Third-Party Websites or any Third-Party Content. You do so at your own risk and should be aware that these Terms of Use no longer apply if you choose to exit the Site, access Third-Party Websites, or use or install any Third-Party Content.
            </div>
            <div className="termsMobileDescription">
              If you visit to any website through the Site or use or install any programs from the Site, you should examine the appropriate terms and policies, including privacy and data gathering practices. We accept no liability for any transactions you make through Third-Party Websites since they are solely between you and the relevant third party. Any purchases you make through Third-Party Websites will be through other websites and from other businesses.
            </div>
            <div className="termsMobileDescription">
              You accept and agree that we do not support the goods or services advertised on Third-Party Websites, and you agree to indemnify us for any damage resulting from your purchase of such goods or services. Furthermore, you agree to indemnify us from any losses you may incur or injury you may suffer connected with or resulting from any contact with third-party websites or any third-party content. 
            </div>
            <div className="termsMobileSubTitle">ADVERTISERS</div>
            <div className="termsMobileDescription">
              In some places of the site we permit advertisers to show their ads and other content. If you are an advertiser, you are entirely responsible for any advertisements you post on the site, as well as any services you offer or goods you promote.
            </div>
            <div className="termsMobileDescription">
              Additionally, in your capacity as an advertiser, you warrant and represent that you have all necessary rights, including but not limited to intellectual property, publicity, and contractual rights, to post advertisements on the Site.
            </div>
            <div className="termsMobileDescription">
              Advertisers acknowledge and agree that the provisions of our Digital Millennium Copyright Act ("DMCA") Notice and Policy, as described below, apply to such advertisements. Advertisers also acknowledge and agree that there will be no refunds or other forms of remuneration for DMCA takedown-related issues. We have no other interaction with advertisers other than to provide the platform for such adverts.   
            </div>
            <div className="termsMobileSubTitle">SITE MANAGEMENT</div>
            <div className="termsMobileDescription">
              Although we are not obligated to, we reserve the right to:
            </div>
            <ol className="mobileOl" type="a">
              <li>check the website for any violations of these terms and conditions;</li>
              <li>anyone who, in our sole judgement, violates the law or these Terms of Use will be subject to appropriate legal action, which may include but is not limited to reporting the offending user to police enforcement;</li>
              <li>Any of your contributions or any portion thereof may be rejected, denied access to, limited in availability, or disabled (to the degree technologically possible) at our sole discretion;</li>
              <li>All files and content that are excessively large or otherwise burdensome to our systems may be removed from the Site or otherwise disabled at our sole discretion and without restriction, notification, or liability;</li>
              <li>otherwise take care of the Site in a way that promotes its proper operation while safeguarding our rights and property.</li>
            </ol>
            <div className="termsMobileSubTitle">PRIVACY POLICY</div>
            <div className="termsMobileDescription">
              Data security and privacy are important to us. Please read the Site's posted Privacy Statement by clicking [HERE]. You consent to be governed by our Privacy Policy, which is incorporated into these Terms of Use, by using the Site. Please be aware that United Kingdon  is where the website is hosted.
            </div>
            <div className="termsMobileDescription">
              By continuing to use the Site, you are sending your data to United Kingdom and you hereby give your express consent for such data to be sent to and processed in United kingdom if you are accessing the Site from the European Union, Asia, or any other region of the world where applicable laws regarding the collection, use, or disclosure of personal data differ from applicable laws in England.
            </div>
            <div className="termsMobileDescription">
              Furthermore, we do not knowingly market to children or accept, request, or solicit information from them.
            </div>
            <div className="termsMobileSubTitle">DIGITAL MILLENNIUM COPYRIGHT ACT (DMCA) NOTICE AND POLICY</div>
            <div className="termsMobileSubTitleSub">Notifications</div>
            <div className="termsMobileDescription">
              We honor other people's rights to their intellectual property. Please immediately notify our Designated Copyright Agent using the contact information provided below (a "Notification") if you think any content on or accessible via the Site violates any copyright you are the owner of or otherwise have control over.
            </div>
            <div className="termsMobileDescription">
              The individual who posted or stored the content mentioned in your notification will receive a copy of it. Please be aware that if you make serious misrepresentations in a Notification, you may be held accountable for damages under federal law. Therefore, you should think about first consulting an attorney if you are unsure if any content on the Site or one of its links violates your copyright.
            </div>
            <div className="termsMobileSubTitleSub">Counter Notification</div>
            <div className="termsMobileDescription">
              You can send us/our Designated Copyright Agent a written counter notification (a "Counter Notification") if you think your own copyrighted content was taken from the Site due to an error or misidentification. The contact information is provided below.
            </div>
            <div className="termsMobileDescription">
              If you send us a legitimate, written Counter Notification, we will reinstate the material that has been removed or disabled unless we first receive notification from the party who filed the Notification informing us that such party has initiated legal action to prevent you from engaging in infringement-related activity.
            </div>
            <div className="termsMobileDescription">
              Please be aware that you might be held accountable for damages, including costs and attorney's fees, if you materially misrepresent that the content was disabled or removed by mistake or misidentification. Filing a false Counter Notification constitutes perjury.
            </div>
            <div className="termsMobileDescription">
              Attn: Copyright Agent
              <br/>Yamu Ltd
              <br/>61c Rivington st
              <br/>London
              <br/>EC2A 3QQ
            </div>
            <div className="termsMobileSubTitle">COPYRIGHT INFRINGEMENTS</div>
            <div className="termsMobileDescription">
              We honor other people's rights to their intellectual property. Please contact us straight away using the details listed below (a "Notification") if you feel that any content on or accessible via the Site violates any copyright you may have. The individual who posted or stored the content mentioned in your notification will receive a copy of it.
            </div>
            <div className="termsMobileDescription">
              Please be aware that if you make serious misrepresentations in a Notification, you may be held accountable for damages under federal law. Therefore, you should think about first consulting an attorney if you are unsure if any content on the Site or one of its links violates your copyright.
            </div>
            <div className="termsMobileSubTitle">CORRECTIONS</div>
            <div className="termsMobileDescription">
              The Site may contain typographical mistakes, inaccuracies, or omissions that may relate to the descriptions, prices, availability, or other information. We retain the right to alter or update the information on the Site at any time, without prior notice, in order to address any mistakes, inaccuracies, or omissions.
            </div>
            <div className="termsMobileSubTitle">DISCLAIMER</div>
            <div className="termsMobileDescription">
              The website is made accessible "as is" and "as available." You acknowledge that using the website's services is done at your own risk. We disclaim all warranties, explicit or implicit, in relation to the site and your use of it, including, without limitation, implied warranties of merchantability, fitness for a particular purpose, and non-infringement, to the maximum extent permissible by law.
              <br/>We will not accept duty or responsibility for any errors or omissions in the site's material, the content of any websites connected to this one, and we make no guarantees or claims
            </div>
            <ol className="mobileOl" type="a">
              <li>regarding its correctness or completeness.</li>
              <li>omissions, faults, or inaccuracies in the contents and content</li>
              <li>any type of physical harm or material loss brought on by your use of the site or access to it,</li>
              <li>any unlawful access to or use of our secure systems, including any unauthorized access to or use of any personal information, financial information,</li>
              <li>if there is a halt to transmission to or from the location,</li>
              <li>any bugs, viruses, trojan horses, or similar items that any third party might send to or through the website, and/or </li>
              <li>any mistakes or omissions in the materials and content, as well as for any loss or harm of any kind brought on by the use of any materials or content posted, communicated, or otherwise made accessible via the website.</li>
            </ol>
            <div className="termsMobileDescription">
              Any product or service advertised or offered by a third party through the website, any linked website, any website or mobile application featured in any banner ad, or in any other manner, is not warranted, endorsed, guaranteed, or assumed by us to be of any quality, and we have no involvement in, and take no responsibility for, the monitoring of, any transaction between you and any third-party suppliers of products or services. You should use caution when necessary and use your best judgment when buying a good or service, regardless of the venue or location.
            </div>
            <div className="termsMobileSubTitle">LIMITATIONS OF LIABILITY</div>
            <div className="termsMobileDescription">
              Even if we have been informed of the possibility of such damages, in no event will we or our directors, employees, or agents be liable to you or any third party for any direct, indirect, consequential, exemplary, incidental, special, or punitive damages, including lost profit, lost revenue, loss of data, or other damages arising from your use of the website. Despite anything to the contrary in this document, our liability to you for any reason and regardless of the form of the action will always be limited to the lesser of $100 or the amount you paid, if any, to us in the 12 months prior to the cause of action occurring. Limitations on implied warranties and the exclusion or restriction of certain damages are prohibited by some state laws. If these laws apply to you, some or all of the aforementioned limits or disclaimers may not be applicable to you, and you could even be entitled to more rights.
            </div>
            <div className="termsMobileSubTitle">INDEMNIFICATION</div>
            <div className="termsMobileDescription">
              You promise to protect us, our subsidiaries, affiliates, and each of our officers, agents, business partners, and staff members from and against any loss, damage, responsibility, claim, or demand made by any third party owing to or resulting from:
            </div>
            <ol className="mobileOl" type="a">
              <li>your contributions;</li>
              <li>using the website;</li>
              <li>violates these terms and conditions;</li>
              <li>any violation of the promises and representations you've made in these terms of use;</li>
              <li>your infringement of another party's rights, particularly those related to intellectual property, or</li>
              <li>any overtly detrimental behavior you display toward another site user with whom you associated there. </li>
            </ol>
            <div className="termsMobileDescription">
              Despite the aforementioned, we retain the right to assume the exclusive defense and control of any matter for which you are obligated to indemnify us, and you agree to assist us in the defense of such claims at your expense. As soon as we become aware of any such claim, action, or process that is covered by this indemnity, we shall make a reasonable effort to let you know about it. 
            </div>
            <div className="termsMobileSubTitle">EXTERNAL LINKS DISCLAIMER</div>
            <div className="termsMobileDescription">
              The Site  may contain (or you may be sent through the Site  links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising). Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability or completeness by us.
            </div>
            <div className="termsMobileDescription">
              We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information offered by third-party websites linked through the site or any website or feature linked in any banner or other advertising. We will not be a party to or in any way be responsible for monitoring any transaction between you and third-party providers of products or services.
            </div>
            <div className="termsMobileSubTitle">PROFESSIONAL DISCLAIMER</div>
            <div className="termsMobileDescription">
              The Site cannot and does not contain medical/legal/fitness/health/other advice. The legal/medical/fitness/health/other information is provided for general informational and educational purposes only and is not a substitute for professional advice.
            </div>
            <div className="termsMobileDescription">
              Accordingly, before taking any actions based upon such information, we encourage you to consult with the appropriate professionals. We do not provide any kind of medical/legal/fitness/health/other advice. The use or reliance of any information contained on this site  is solely at your own risk.
            </div>
            <div className="termsMobileSubTitle">AFFILIATES DISCLAIMER</div>
            <div className="termsMobileDescription">
              The Site  may contain links to affiliate websites, and we receive an affiliate commission for any purchases made by you on the affiliate website using such links. Our affiliates include Brandreward and Awin,
            </div>
            <div className="termsMobileSubTitle">TESTIMONIALS DISCLAIMER</div>
            <div className="termsMobileDescription">
              The Site may contain testimonials by users of our products and/or services. These testimonials reflect the real-life experiences and opinions of such users. However, the experiences are personal to those particular users, and may not necessarily be representative of all users of our products and/or services. We do not claim, and you should not assume, that all users will have the same experiences. Your individual results may vary.
            </div>
            <div className="termsMobileDescription">
              The testimonials on the Site are submitted in various forms such as text, audio and/or video, and are reviewed by us before being posted. They appear on the Site verbatim as given by the users, except for the correction of grammar or typing errors. Some testimonials may have been shortened for the sake of brevity where the full testimonial contained extraneous information not relevant to the general public. 
            </div>
            <div className="termsMobileDescription">
              The views and opinions contained in the testimonials belong solely to the individual user and do not reflect our views and opinions. We are not affiliated with users who provide testimonials, and users are not paid or otherwise compensated for their testimonials.
            </div>
            <div className="termsMobileSubTitle">USER DATA</div>
            <div className="termsMobileDescription">
              For the purpose of maintaining the Site's performance, we will keep track of some of the information you send to the Site, as well as information on how you use the Site. Despite the fact that we regularly backup your data, you are solely in charge of any data you communicate or that pertains to any activity you conduct while using the Site.
            </div>
            <div className="termsMobileDescription">
              You acknowledge that we are not responsible for any loss or corruption of such data, and you hereby release us from any claims you may have about such loss or corruption.
            </div>
            <div className="termsMobileSubTitle">TERM AND TERMINATION</div>
            <div className="termsMobileDescription">
              While you use the Site, these Terms of Use will continue to be in full force and effect. Without limiting any other provision of these terms of use, we reserve the right to, in our sole discretion and without notice or liability, refuse access to and use of the site to anyone for any reason or for no reason at all, including without limitation for breach of any representation, warranty, or covenant contained in these terms of use or of any applicable law or regulation, including blocking specific IP addresses. In any time, without prior notice, and at our sole discretion, we may stop allowing you to use or participate in the site, or we may delete your account together with any material or information you have provided. 
            </div>
            <div className="termsMobileDescription">
              You are forbidden from registering and setting up a new account in the name of any third person, even if you could be acting on their behalf, even if we terminate or suspend your account for any reason.
            </div>
            <div className="termsMobileDescription">
              We reserve the right to pursue appropriate legal action, including without limitation seeking civil, criminal, and injunctive remedy, in addition to canceling or suspending your account.
            </div>
            <div className="termsMobileSubTitle">MODIFICATIONS AND INTERRUPTIONS</div>
            <div className="termsMobileDescription">
              The Site's contents are subject to alteration, modification, or removal at any time and without prior notice for any reason. We are not obligated to update any material on our website, though. Additionally, we retain the right to change or stop offering the Site altogether at any time without prior notice.
            </div>
            <div className="termsMobileDescription">
              Any time the Site is altered, its price is raised, it is suspended, or it is discontinued, we won't be held responsible to you or any other person.
            </div>
            <div className="termsMobileDescription">
              The availability of the Site cannot be guaranteed at all times. We could encounter hardware, software, or other issues or need to carry out maintenance on the Site, which might cause disruptions, delays, or mistakes. Without providing you with prior notice, we retain the right to alter, suspend, or cancel the Site at any time and for any reason.
            </div>
            <div className="termsMobileDescription">
              You acknowledge and agree that we shall not be responsible or liable in any way for any loss, harm, or inconvenience resulting from your inability to access or use the Site during any outage or discontinuation of the Site. Nothing in these Terms of Use shall be deemed to oblige us to provide any corrections, updates, or releases in connection with the Site's upkeep and support.
            </div>
            <div className="termsMobileSubTitle">GOVERNING LAW</div>
            <div className="termsMobileDescription">
              These Terms of Use and your use of the Site are governed by and construed in accordance with the laws of the United Kingdom without regard to its conflict of law principles. 
            </div>
            <div className="termsMobileSubTitle">DISPUTE RESOLUTION</div>
            <div className="termsMobileDescription">
              The Parties agree to first attempt to negotiate any Dispute (aside from those Disputes expressly provided below) informally for at least 60 days before commencing arbitration in order to expedite resolution and manage the costs of any dispute, controversy, or claim related to these terms of use (each a "Dispute" and collectively, the "Disputes"). Following written notification from one party to the other, such informal negotiations begin.
            </div>
            <div className="termsMobileDescription">
              Except for those disputes that are specifically excluded herein, disputes between the Parties that cannot be settled via informal dialogue shall be ultimately and solely settled by binding arbitration. YOU UNDERSTAND THAT IN THE ABSENCE OF THIS PROVISION, YOU WOULD BE ENTITLED TO THE RIGHT TO SUE IN COURT.
            </div>
            <div className="termsMobileDescription">
              The arbitration must start and proceed in accordance with the relevant arbitration legislation.
              The Parties hereby agree to, and waive any defenses of lack of personal jurisdiction and forum non conveniens with respect to venue and jurisdiction in the courts in London, in the event that a dispute is resolved via litigation rather than arbitration.
            </div>
            <div className="termsMobileDescription">
              Any dispute involving the Site that is raised by any Party must never be filed more than a year after the cause of action first materialized. A court of competent jurisdiction within the courts listed for jurisdiction above will resolve any disputes falling within this provision if it is determined that any part of it is unlawful or unenforceable, and the Parties agree to submit to the personal jurisdiction of such court. If this provision is determined to be unlawful or unenforceable, neither Party will choose to arbitrate any disputes falling within that portion of this provision.
            </div>
            <div className="termsMobileDescription">
              The Parties concur that any arbitration will only cover the particular disputes between the Parties. There is no right or authority for any dispute to be brought in a purported representative capacity on behalf of the general public or any other individuals, and no arbitration shall be joined with any other proceeding to the fullest extent permitted by law. There is also no right or authority for any dispute to be arbitrated on a class-action basis or to use class-action procedures.
            </div>
            <div className="termsMobileDescription">
              The Parties concur that the following Disputes are not covered by the aforementioned clauses regarding binding arbitration and informal negotiations:
            </div>
            <ol className="mobileOl" type="a">
              <li>any Disputes involving the enforcement, protection, or validity of a Party's intellectual property rights;</li>
              <li>any Conflict involving, or resulting from, claims of Theft, Piracy, Privacy Invasion, or Unauthorized Use; and</li>
              <li>any demand for an injunction. </li>
            </ol>
            <div className="termsMobileDescription">
              If any part of this clause is found to be unlawful or unenforceable, neither Party will choose to arbitrate any disputes that fall under that part of the clause; instead, those disputes will be resolved by a court of competent jurisdiction, and the Parties consent to the personal jurisdiction of that court.
            </div>
            <div className="termsMobileSubTitle">ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</div>
            <div className="termsMobileDescription">
              Electronic communications include messages sent to us via email, website visits, and online form submissions. You agree that all agreements, notices, disclosures, and other communications that we give to you electronically through email and on the Site fulfill any legal need that such communications be in writing. You also consent to receiving electronic communications from us. THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AS WELL AS THE ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE, ARE HEREBY AGREEED TO BY YOU. 
            </div>
            <div className="termsMobileDescription">
              You hereby waive any rights or obligations you may have under any statutes, rules, regulations, ordinances, or other legislation in any area that call for an original signature, the delivery of paper documents, their storage, or the right to payment or credit by methods other than electronic ones. 
            </div>
            <div className="termsMobileSubTitle">MISCELLANEOUS</div>
            <div className="termsMobileDescription">
              The only agreements and understandings between you and us are these Terms of Use and any other policies or operating guidelines we put on the website or in relation to the website. No right or provision of these Terms of Use shall be deemed to have been waived by our failure to exert or enforce such right or provision.
            </div>
            <div className="termsMobileDescription">
              To the largest extent permitted by law, these Terms of Use are in effect. All of our rights and duties are transferable at any time. Any loss, damage, delay, or failure to act brought on by a factor outside of our reasonable control shall not be our responsibility or liability. 
            </div>
            <div className="termsMobileDescription">
              If any term or portion of a term of these Terms of Use is found to be unlawful, invalid, or unenforceable, that term or portion is deemed severable from these Terms of Use and has no bearing on the legality or enforceability of any other terms that remain in place. These Terms of Use and your use of the Site do not establish any joint venture, partnership, employment relationship, or agency between you and us.
            </div>
            <div className="termsMobileDescription">
              You acknowledge that the fact that we wrote these terms of use should not be used against us. You hereby waive any and all objections you may have to these Terms of Use's electronic format and the parties' failure to execute them by hand.
            </div>
            <div className="termsMobileSubTitle">CONTACT US</div>
            <div className="termsMobileDescription">
              In order to resolve a complaint regarding the Site or to receive further information regarding use of the Site, please contact us at:
            </div>
            <div className="termsMobileDescription">
              Yamu Ltd<br/>
              61c Rivington St<br/>
              London<br/>
              EC2A 3QQ
            </div>
          </div>
          <div className="termsMobileStore" style={{marginTop: 262}}>
            <div className="termsMobileStoreTitle">
              <div className="termsMobileStoreTitleText">
                Join the revolution and help<br/>repair our planet from the<br/>grassroots up
              </div>
            </div>
            <div className="termsMobileStoreButtons">
              <AppStoreButton isMobile={true}/>
              <GoogleStoreButton isMobile={true} style={{marginLeft: 10, marginTop: 0}}/>
            </div>
          </div>
          <div className="seperator"/>
          <Footer isMobile={true}/>
        </div>
      </div>
    );
  }

  render = () => {
    if (this.state.screenWidth < 768) {
      return this.renderMobile();
    }

    // body size
    var screenWidth = this.state.screenWidth - this.props.scrollbarWidth;
    var marginTop = BACKGROUND_MARGINTOP / (BACKGROUND_SIZE.width / this.state.screenWidth);
    var screenHeight = SCREEN_HEIGHT * (screenWidth / SCREEN_WIDTH);
    screenHeight = SCREEN_HEIGHT / SCREEN_ASPECTRATIO; //screenWidth / SCREEN_ASPECTRATIO - marginTop;

    // body margin
    var bodyMarginHorizontal = (screenWidth - VIDEO_WIDTH * 4) / 2;

    // participants width
    var contentWidth = (screenWidth - 35 * 2);
    if (contentWidth > 534 + 801) {
      contentWidth = 534 + 801;
    }
    contentWidth -= 142;

    var availableCount = Math.floor((contentWidth + 10) / 50);
    var participantCount = availableCount - 1 + 8;
    var morePariticipantCount = participantCount - availableCount + 1;

    var participants = [];
    for (var i = 0; i < availableCount - 1; i++) {
      participants.push({
        name: "",
      });
    }

    return (
      <div className="App page">
        <Header
          // backgroundImage={backgroundImage}
          // backgroundMarginTop={marginTop}
          screen="terms"
          screenSize={{
            height: screenWidth / SCREEN_ASPECTRATIO,
            width: screenWidth,
          }}
        />
        <div
          className="body"
          style={{
            backgroundColor: "rgb(0, 95, 176)",
            backgroundImage:`url(${backgroundImage})`,
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            height: screenHeight,
            width: screenWidth,
          }}
        >
          <div className="termsTitle">Terms & Conditions</div>
          <div className="termsBody">
            <div className="termsContent">
              <div className="termsContentBody">
                <div className="termsTextDescription">
                  Last updated [8th December 2022]
                </div>
                <hr className="hr"/>
                <div className="termsTextTitle">AGREEMENT TO TERMS</div>
                <div className="termsTextDescription">
                  These Terms of Service constitute a legally binding agreement between you, individually or on behalf of an entity ("you"), and Yamu Ltd  ("we," "us," or "our") regarding your access to and use of the http://www.enviromates.earth website or our mobile application (“Enviromates” or “App” or “Application”) and any other media form, media channel, mobile website related, linked, or otherwise connected thereto (collectively the "Site").
                </div>
                <div className="termsTextDescription">
                  You acknowledge that by accessing the Site, you have read, comprehended, and consent to be bound by these whole Terms of Service. If you do not agree with these Terms of Service in their entirety, you are explicitly barred from using the Site and must immediately terminate use.
                </div>
                <div className="termsTextDescription">
                  Additional terms and conditions or documents that may from time to time be posted on the Site are expressly incorporated herein by this reference. We reserve the right, at any time and for any cause, to make changes or revisions to these Terms of Service at our sole discretion.
                </div>
                <div className="termsTextDescription">
                  We shall notify you of any modifications by revising the "Last updated" date in these Terms of Service, and you waive your right to obtain explicit notification of any modification. You are responsible for frequently reviewing these Terms of Service for modifications. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any updated Terms of Use by continuing to use the Site after such revised Terms of Use have been posted.
                </div>
                <div className="termsTextDescription">
                  This information is not intended for distribution to or use by any individual or organization in any jurisdiction or country where such distribution or use would be contrary to law or regulation or would subject us to any registration obligation within such jurisdiction or country. Those who access the Site from other locations do so voluntarily and are solely responsible for compliance with local laws, if and to the extent local laws are relevant.
                </div>
                <div className="termsTextDescription">
                  The Website is designed for users over the age of 18. All users who are minors in their home jurisdiction (usually under the age of 18) are required to have parental consent and direct supervision in order to access the Site. Prior to using the Site if you are a minor, your parent or legal guardian must read and consent to these Terms of Service. 
                </div>
                <div className="termsTextTitle">INTELLECTUAL PROPERTY RIGHTS</div>
                <div className="termsTextDescription">
                  Unless otherwise specified, the Site is our exclusive property, and all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the Site (collectively, the "Content") and trademarks, service marks, and logos contained therein (the "Marks") are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws as well as various other intellectual property rights and unfair competition laws. The Site provides the Content and Marks "AS IS" for your information and personal use only. No portion of the Site and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, licensed, or otherwise exploited for any commercial purpose whatsoever, except as expressly permitted in these Terms of Service.
                </div>
                <div className="termsTextDescription">
                  You are granted a limited license to access and use the Site, as well as to download or print a copy of any portion of the Content to which you have lawfully gained access, solely for your own private, non-commercial use. This is granted as long as you are qualified to use the Site. We hold all ownership rights to the Site, the Content, and the Marks that are not otherwise explicitly granted to you.
                </div>
                <div className="termsTextTitle">USER REPRESENTATIONS</div>
                <div className="termsTextDescription">
                  You affirm and guarantee by using the website that:
                  <ol type="a">
                    <li>You certify that any registration information you provide is true, accurate, up-to-date, and comprehensive.</li>
                    <li>You will keep such information accurate and immediately update such registration information as required;</li>
                    <li>you agree to abide by these Terms of Use and are of legal age;</li>
                    <li>if you are a minor, your parents have given you permission to use the Site if you are not a minor in the country in which you now reside;</li>
                    <li>You won't use a bot, script, or any other automated or non-human method to access the Site;</li>
                    <li>you won't utilize the website for any improper or prohibited purposes;</li>
                    <li>You won't break any laws or regulations by using the Site.</li>
                  </ol>
                </div>
                <div className="termsTextDescription">
                  We reserve the right to suspend or cancel your account and prohibit any and all current or future use of the Site in the event that you provide any information that is untrue, inaccurate, out-of-date, or incomplete (or any portion thereof). 
                </div>
                <div className="termsTextTitle">ENVIROMATES SERVICES</div>
                <div className="termsTextDescription">
                  The application has been designed to be grassroots crowdfunding platform, enabling environmentalists from all around the world to create, fund and share updates on environmental projects.
                </div>
                <div className="termsTextDescription">
                  In lieu of traditional monetary donations (which are still allowed), we've developed a browser that enables consumers to passively earn money when they make purchases and receive a portion back that they can pledge to environmental projects of their choosing. We also provide a variety of advertising solutions where users are paid directly creating a peer-to-peer advertising platform.
                </div>
                <div className="termsTextDescription">
                  To aid environmentalists in managing their initiatives, we've developed a social media platform that allows projects to quickly and effectively update their supporters and followers.
                </div>
                <div className="termsTextDescription">
                  <strong>Minimum age</strong> - The Enviromates Crowdfunding platform is not intended for users under the age of 13. You are not allowed to use Enviromates Crowdfunding if you are under the age of 13. You can only create an Enviromates Crowdfunding page if you have your parent's or guardian's permission if you are between the ages of 13 and 18. Make sure your parent or legal guardian is aware that you are in charge of utilizing the money in accordance with these Terms. When utilizing Enviromates' services or setting up a fundraising page, minors must be directly supervised by an adult.
                </div>
                <div className="termsTextDescription">
                  Please email us at assistme@enviromates.earth right away if you have any concerns about a child using our services.
                </div>
                <div className="termsTextTitle">USER REGISTRATION</div>
                <div className="termsTextDescription">
                  There is no requirement for the user to create an account. The browser can be utilized in the same manner as Chrome or Safari. You will get access to view environmental projects. However, registration is required to earn any passive income, pledge to projects, access some social media features, and post comments. 
                </div>
                <div className="termsTextDescription">
                  If you attempt to do an action that requires registration, the registration form will appear. When you create an account on the site, you agree to keep your password secure and are responsible for any activities that occur under your account and password. We reserve the right to remove, reclaim, or alter a username you choose if, in our sole discretion, we think that it is improper, offensive, or otherwise objectionable.
                </div>
                <div className="termsTextTitle">PRODUCTS</div>
                <div className="termsTextDescription">
                  There are four sections to the Enviromates App
                </div>
                <div className="termsTextDescription">
                  <strong>Browser</strong> - In this case, you use the Browser (similar to Chrome or Safari) to search the web and collect a portion of the sale from subscribing brands. Earned funds expressed as hearts can only be used to fund environmental projects on the platform. If a crowdfunding project, for any specific project is unsuccessful or the project creator terminates the campaign early, the funds are restored to the user's app wallet.
                </div>
                <div className="termsTextDescription">
                  App users also have access to a wallet that displays earnings, and there are other pages that users can select complete to disclose further information.
                </div>
                <div className="termsTextDescription">
                  <strong>Environmental Creators</strong> – Utilise the social media site to enable creators to establish environmental initiatives, share information, publish updates, and respond to messages.
                </div>
                <div className="termsTextDescription">
                  Environmental creators can establish personalized crowdfunding campaigns and provide periodic updates to backers to demonstrate the project's development. Users can also provide feedback on projects.
                </div>
                <div className="termsTextDescription">
                  <strong>Advertisers</strong> – Directly upload advertising to serve to users of Enviromates.
                </div>
                <div className="termsTextDescription">
                  <strong>Brands/Organisations</strong> – Can submit ready-to-launch projects that once vetted will be available for others to select and crowdfund. Brands and organization are also able to create projects and become environmental creators.
                </div>
                <div className="termsTextDescription">
                  We make every attempt to display the colors, features, specs, and specifics of everything offered on the website as accurately as possible. However, we cannot guarantee that the colors, features, specs, and details will be accurate, complete, reliable, current, or error-free, and your electronic display may not reflect the actual colors and details.
                </div>
                <div className="termsTextTitle">PRICING</div>
                <div className="termsTextDescription">
                  There are no fees or costs associated with using the app. The listing of environmental projects is free. There is no cost associated with using the browser. Only a percentage of advertising revenue and eco-cashback will be retained by the company to pay salaries and invest in continued R&D to improve the service to everyone. 
                </div>
                <div className="termsTextTitle">REFUND POLICY</div>
                <div className="termsTextDescription">
                  <strong>Enviromates is not a store and we do not issue refunds.</strong> When you back an environmental project you do so on the basis you are supporting an environmental creator’s project and pledged as a donation. 
                </div>
                <div className="termsTextDescription">
                  When a project is successfully backed the environmental creator is responsible for completing the project to the best of their abilities and to provide feedback to supporters what happens. Enviromates does not guarantee the quality of an environmental creators' work. 
                </div>
                <div className="termsTextDescription">
                  Once a pledge is made it is not possible to cancel. If the environmental creator cancels the project before they reach their target or is not ultimately not successful the hearts pledged will be returned back to the user's app wallet.
                </div>
                <div className="termsTextTitle">PROHIBITED ACTIVITIES</div>
                <div className="termsTextDescription">
                  <strong>Enviromates is not a store and we do not issue refunds.</strong> When you back an environmental project you do so on the basis you are supporting an environmental creator’s project and pledged as a donation. 
                </div>
                <div className="termsTextDescription">
                  You agree, as a user of the Site, not to:
                  <ol type="a">
                    <li>Systematically retrieve data or other content from the Site for the purpose of creating or compiling, directly or indirectly, a collection, compilation, database, or directory without our explicit consent.</li>
                    <li>Make any unauthorized use of the Site, such as gathering usernames and/or email addresses of users using electronic or other means for the purpose of sending unsolicited email, or creating user accounts through automated means or under false pretenses.</li>
                    <li>Use a purchasing agent or buying agent to make purchases on the Site.</li>
                    <li>Utilize the Site to promote or offer to sell products or services.</li>
                    <li>Bypass, deactivate, or otherwise interfere with security-related aspects of the Site, including features that prevent or restrict the use or copying of any Content or enforce usage limitations on the Site and/or its Content.</li>
                    <li>engage in illegal framing or linking to the Site.</li>
                    <li>Trick, defraud, or mislead us or other users, including in an attempt to obtain sensitive account information such as user passwords; make improper use of our support services; or submit fraudulent claims of misconduct or abuse.</li>
                    <li>No automated use of the system is permitted, including the use of scripts to send comments or messages, data mining, robots, or similar data collection and extraction techniques.</li>
                    <li>Interfere with, disrupt, or impose an unreasonable load on the Website or the networks or services connected to the Website.</li>
                    <li>attempt to assume the identity of another user or person, or use another user's username.</li>
                    <li>sell or transfer your profile in some other way.</li>
                    <li>Use any information obtained from the Site to harass, abuse, or otherwise cause harm to another user.</li>
                    <li>You may not use the Site as part of any effort to compete with us or utilize the Site and/or Content for any other revenue-generating or commercial purpose.</li>
                    <li>You may not decrypt, decompile, disassemble, or otherwise reverse-engineer any of the software comprising or otherwise constituting any portion of the Site.</li>
                    <li>attempt to circumvent any Site measures intended to prevent or limit access to the Site or any portion thereof.</li>
                    <li>You may not harass, irritate, intimidate, or threaten any of our employees or agents involved in supplying you with any portion of our website.</li>
                    <li>Remove the notice of copyright or other property rights from the Content.</li>
                    <li>You may not copy or modify the software on the Site, including but not limited to Flash, PHP, HTML, JavaScript, or other code.</li>
                    <li>upload or transmit (or attempt to upload or transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with the uninterrupted use and enjoyment of the Site by any party, or that modifies, impairs, disrupts, or interferes with the use, features, functions, operation, or maintenance of the Site.</li>
                    <li>upload or transmit (or attempt to upload or transmit) any material that acts as a passive or active information collection or transmission mechanism, including, but not limited to, clear graphics interchange formats ("gifs"), 11 pixels, web bugs, cookies, or other similar devices (sometimes known as "spyware" or "passive collection mechanisms" or "pcms").</li>
                    <li>Use, launch, develop, or distribute any automated system, including, but not limited to, any spider, robot, cheat utility, scraper, or offline reader that accesses the Site, or use or launch any unauthorized script or other software, except as may be the result of standard search engine or Internet browser usage.</li>
                    <li>Denigrate, taint, or otherwise harm us and/or the Site, in our opinion.</li>
                    <li>Use the Website in violation of any applicable laws or regulations.</li>
                  </ol>
                </div>
                <div className="termsTextTitle">USER GENERATED CONTRIBUTIONS</div>
                <div className="termsTextDescription">
                  The Site may invite you to participate in chat, blogs, message boards, online forums, and other features and may give you the chance to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or on the Site, such as but not limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or other material containing personal information (collectively, "Contributions").
                </div>
                <div className="termsTextDescription">
                  Other Site users and third-party websites may be able to view contributions. Any Contributions you send could therefore be considered non-confidential and non-proprietary. When you generate Contributions or make them available, you represent and warrant that:
                  <ol type="a">
                    <li>Creation, distribution, transmission, public display or performance, as well as accessing, downloading, or copying of your Contributions, do not and will not violate the intellectual rights of any third party, including but not limited to copyright, patent, trademark, trade secret, or moral rights.</li>
                    <li>You are the creator and owner of your Contributions, or you have the appropriate licenses, rights, consents, releases, and permissions to use and authorize us, the Site, and other Site users to use your Contributions in any way permitted by the Site and these Terms of Service.</li>
                    <li>You have the written approval, release, and/or authorization of each and every identifiable individual in your Contributions to enable inclusion and use of your Contributions in any manner authorized by the Site and these Terms of Service.</li>
                    <li>Your Contributions are neither fake nor wrong.</li>
                    <li>Your Contributions are not unsolicited or unlawful advertising, promotional materials, pyramid schemes, chain letters, spam, or mass mailings.</li>
                    <li>Your Contributions must not be obscene, lewd, lascivious, filthy, violent, harassing, libelous, or otherwise inappropriate (as determined by us).</li>
                    <li>Your Contributions do not mock, degrade, intimidate, or abuse anyone.</li>
                    <li>Your contributions do not advocate for the violent overthrow of any government or incite, urge, or threaten physical damage against another individual.</li>
                    <li>Your Contributions comply with all applicable laws, rules, and regulations.</li>
                    <li>your Contributions do not breach the privacy or publicity rights of any third party.</li>
                    <li>your Contributions do not contain any content that solicits personal information from anybody under the age of 18 or exploits anyone under the age of 18 in a sexual or violent manner.</li>
                    <li>your Contributions do not violate any federal or state legislation prohibiting child pornography, or otherwise intended to protect the health or well-being of minors;</li>
                    <li>your Contributions do not include any offensive comments that are connected to race, country origin, gender, sexual preference, or physical impairment.</li>
                    <li>Your Contributions do not otherwise violate any aspect of these Terms of Service or any relevant law or regulation, nor do they link to content that does.</li>
                  </ol>
                </div>
                <div className="termsTextDescription">
                  Any use of the Site in violation of the aforementioned may result in termination or suspension of your rights to use the Site. 
                </div>
                <div className="termsTextTitle">CONTRIBUTION LICENSE</div>
                <div className="termsTextDescription">
                  You automatically grant us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right and license to host, use, copy, reproduce, disclose, sell, resell, publish your Contributions on the Site or make them accessible to the Site by linking your account on the Site to any of your social networking accounts. You also represent and warrant that you have the right to grant this license. Any media channels and any media formats are acceptable for use and distribution. 
                </div>
                <div className="termsTextDescription">
                  This license includes our use of your name, company name, and franchise name, as applicable, as well as any trademarks, service marks, trade names, logos, and personal and commercial images you supply. You renounce all moral rights to your Contributions, and you warrant that no moral rights have been asserted against them.
                </div>
                <div className="termsTextDescription">
                  We make no claim to ownership of your Contributions. You retain complete ownership of all of your Contributions and associated intellectual property rights and other proprietary rights. We are not responsible for any claims or representations made in your Contributions in any area of the Site.
                </div>
                <div className="termsTextDescription">
                  You are entirely responsible for your Contributions to the Site, and you expressly agree to absolve us of any liability and to refrain from bringing any legal action against us in connection with your Contributions.  
                </div>
                <div className="termsTextDescription">
                  We reserve the right, in our sole and absolute discretion, to (1) edit, redact, or otherwise modify any Contributions; (2) re-categorize any Contributions to place them in more appropriate locations on the Site; and (3) pre-screen or delete any Contributions at any time and for any reason, without notice. We are under no responsibility to oversee your Contributions. 
                </div>
                <div className="termsTextTitle">GUIDELINES FOR REVIEWS</div>
                <div className="termsTextDescription">
                  We may provide locations on the Site where you can leave comments or ratings. The following requirements must be met while publishing a review:
                  <ol type="a">
                    <li>You should have direct knowledge with the individual or organization being examined;</li>
                    <li>Your reviews should not contain profanity or language that is abusive, racial, insulting, or hateful;</li>
                    <li>Your evaluations should not contain any references that are biased based on religion, ethnicity, gender, national origin, age, marital status, sexual orientation, or disability;</li>
                    <li>Your reviews should not contain unlawful activity references;</li>
                    <li>When publishing unfavorable evaluations, you should not be linked with competitors.</li>
                    <li>You should not draw any inferences on the legality of conduct;</li>
                    <li>you are not permitted to publish any false or misleading statements;</li>
                    <li>You are not permitted to create a campaign urging others to publish reviews, regardless of whether they are positive or negative.</li>
                  </ol>
                  We have the right to approve, reject, or remove reviews at our discretion. We are under no responsibility whatsoever to moderate or remove reviews, even if someone finds them offensive or false. We do not endorse reviews, nor do they necessarily reflect our opinions or those of our affiliates or partners.
                </div>
                <div className="termsTextDescription">
                  We assume no responsibility for any review or any claims, liabilities, or losses that may emerge from any review. By submitting a review, you offer us a perpetual, non-exclusive, worldwide, royalty-free, fully-paid, assignable, and sublicensable right and license to reproduce, edit, translate, transmit by any means, display, perform, and/or distribute any and all review-related content.
                </div>
                <div className="termsTextTitle">MOBILE APPLICATION LICENSE</div>
                <div className="termsTextSubTitle">Use License</div>
                <div className="termsTextDescription">
                  If you access the Site through a mobile application, we grant you a limited, revocable, non-exclusive, and non-transferable license to install and use the mobile application on wireless electronic devices that you own or control, and to access and use the mobile application on such devices strictly in accordance with the terms and conditions of this mobile application license set forth in these Terms of Service. 
                </div>
                <div className="termsTextDescription">
                  You must not:
                  <ol type="a">
                    <li>decompile, reverse engineer, disassemble, or attempt to derive the application's source code;</li>
                    <li>make any changes, adaptations, improvements, translations, or derivative works to the application;</li>
                    <li>violate any applicable laws, rules, or regulations relating to your access to or use of the application;</li>
                    <li>remove, modify, or conceal any proprietary notice (including notices of copyright or trademark) displayed by us or the application's licensors;</li>
                    <li>Use the application for any revenue-generating, commercial, or other unintended purpose.</li>
                    <li>provide the application through a network or other environment that permits simultaneous access or use by numerous devices or users;</li>
                    <li>Create a product, service, or software that directly or indirectly competes with or is an alternative for the application.</li>
                    <li>Use the program to send automated queries to any website or unsolicited commercial e-mail;</li>
                    <li>Use any of our proprietary information, interfaces, or other intellectual property in the design, development, manufacturing, licensing, or distribution of apps, accessories, or devices for use with the application.</li>
                  </ol>
                </div>
                <div className="termsTextSubTitle">Apple Devices</div>
                <div className="termsTextDescription">
                  When you access the Site through a mobile application downloaded from the Apple Store ("App Distributor"), the following conditions apply:
                  <ol type="a">
                    <li>The license we grant you for our mobile app is restricted to a non-transferable license to use it on a device running the Apple iOS, as appropriate, and in accordance with the usage guidelines described in the terms of service of the App Distributor;</li>
                    <li>You acknowledge that the App Distributor has no obligation whatsoever to provide any maintenance and support services with respect to the mobile application, and that we are responsible for providing any such services as specified in the terms and conditions of this mobile application license contained in these Terms of Use or as otherwise required by applicable law.</li>
                    <li>If the mobile application does not meet any applicable warranty, you may contact the relevant app distributor, and that distributor may, in accordance with its terms and policies, refund any money paid for the mobile application's purchase; however, to the fullest extent permitted by applicable law, the applicable app distributor will have no other warranty obligation with respect to the mobile application;</li>
                    <li>You affirm and guarantee that you do not reside in a nation under U.S. embargo or one that the government has designated as "terrorist supporting," and that your name does not appear on any list of individuals or entities that the government has restricted or prohibited;</li>
                    <li>When using the mobile application, you must adhere to any applicable third-party terms of agreement; for instance, if you use a VoIP application, you must not violate their wireless data service agreement;</li>
                    <li>You acknowledge and agree that the App Distributors are third-party beneficiaries of the terms and conditions in this mobile application license contained in these Terms of Use. As such, you acknowledge and agree that each App Distributor will have the right (and will be deemed to have accepted the right) to enforce the terms and conditions in this mobile application license contained in these Terms of Use against you.</li>
                  </ol>
                </div>
                <div className="termsTextTitle">SOCIAL MEDIA</div>
                <div className="termsTextDescription">
                  As part of the functionality of the Site, you may link your account with online accounts you have with third-party service providers (each such account, a "Third-Party Account"). To do this, you can either: provide your Third-Party Account login information through the Site; or grant us access to your Third-Party Account, as permitted by the applicable terms and conditions that govern your use of each Third-Party Account.
                </div>
                <div className="termsTextDescription">
                  You affirm and warrant that you have the right to provide us with your third-party account login information and/or access to your third-party account, without violating any of the rules governing your use of the relevant third-party account, without charging us any fees, and without putting any restrictions on our use of the account by the third-party service provider.
                </div>
                <div className="termsTextDescription">
                  By giving us permission to access any Third-Party Accounts, you agree that we may access, make available, and store (as appropriate) any content you have provided to and stored in your Third-Party Account (the "Social Network Content") so that it is accessible on and through the Site via your account, including without limitation any friend lists. You also agree that we may submit to and receive from your Third-Party Account additional information to the extent you are informed when we do so. 
                </div>
                <div className="termsTextDescription">
                  Depending on the Third-Party Accounts you choose and the privacy settings on those Third-Party Accounts, personally identifiable information that you submit to your Third-Party Accounts may be accessible on and through your account on the Site. Please be aware that if a Third-Party Account or associated service becomes unavailable, or if our access to such Third-Party Account is terminated by the third-party service provider, then Social Network Content may no longer be accessible via the Site.
                </div>
                <div className="termsTextDescription">
                  You will always have the option to discontinue the link between your Site account and your Third-Party Accounts. PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY THE AGREEMENT(S) YOU HAVE ENTERED INTO WITH SUCH THIRD-PARTY SERVICE PROVIDERS.
                </div>
                <div className="termsTextDescription">
                  We make no effort to check any Social Network Content, including for accuracy, legality, or non-infringement, and we are not responsible for any Social Network Content. You agree that we may access your email address book associated with a Third-Party Account and your contacts list stored on your mobile device or tablet computer for the express purpose of identifying and notifying you of those contacts who have also registered to use the Site.
                </div>
                <div className="termsTextDescription">
                  You may deactivate the link between the Site and your Third-Party Account by contacting us using the details shown below, or by accessing your account settings (if applicable). We will endeavour to remove any information saved on our servers that was accessed through a Third-Party Account, with the exception of your username and profile picture.
                </div>
                <div className="termsTextTitle">SUBMISSIONS</div>
                <div className="termsTextDescription">
                  You understand and agree that any questions, comments, suggestions, ideas, feedback, or other information you give to us on the Site ("Submissions") are non-confidential and become our sole property. We shall own all rights, including intellectual property rights, and shall be entitled to the unlimited use and dissemination of these Submissions for any lawful purpose, commercial or otherwise, without acknowledgment or payment to you.
                  <br/>You relinquish all moral rights to such Submissions and warrant that such Submissions are your original work or that you have the right to submit them. You agree that we shall not be liable for any alleged or actual infringement or misappropriation of any proprietary right in connection with your Submissions. 
                </div>
                <div className="termsTextTitle">THIRD-PARTY WEBSITES AND CONTENT</div>
                <div className="termsTextDescription">
                  Links to other websites ("Third-Party Websites"), as well as articles, photos, text, graphics, pictures, designs, music, sound, video, information, applications, software, and other content or items belonging to or originating from third parties, may be found on the Site or you may be sent via the Site ("Third-Party Content").
                </div>
                <div className="termsTextDescription">
                  We do not review, monitor, or check the accuracy, suitability, or completeness of any such Third-Party Websites or Third-Party Content, and we do not assume any liability or responsibility for any such Third-Party Websites or Third-Party Content that is posted on, made available through, or installed from the Site, including the accuracy, offensiveness, opinions, reliability, privacy practices, or other guidelines of or contained in the Third-Party Websites or the Third-Party Content.
                </div>
                <div className="termsTextDescription">
                  No approval or endorsement by us is implied by the inclusion of, linking to, or allowing the use or installation of any Third-Party Websites or any Third-Party Content. You do so at your own risk and should be aware that these Terms of Use no longer apply if you choose to exit the Site, access Third-Party Websites, or use or install any Third-Party Content.
                </div>
                <div className="termsTextDescription">
                  If you visit to any website through the Site or use or install any programs from the Site, you should examine the appropriate terms and policies, including privacy and data gathering practices. We accept no liability for any transactions you make through Third-Party Websites since they are solely between you and the relevant third party. Any purchases you make through Third-Party Websites will be through other websites and from other businesses.
                </div>
                <div className="termsTextDescription">
                  You accept and agree that we do not support the goods or services advertised on Third-Party Websites, and you agree to indemnify us for any damage resulting from your purchase of such goods or services. Furthermore, you agree to indemnify us from any losses you may incur or injury you may suffer connected with or resulting from any contact with third-party websites or any third-party content. 
                </div>
                <div className="termsTextTitle">ADVERTISERS</div>
                <div className="termsTextDescription">
                  In some places of the site we permit advertisers to show their ads and other content. If you are an advertiser, you are entirely responsible for any advertisements you post on the site, as well as any services you offer or goods you promote.
                </div>
                <div className="termsTextDescription">
                  Additionally, in your capacity as an advertiser, you warrant and represent that you have all necessary rights, including but not limited to intellectual property, publicity, and contractual rights, to post advertisements on the Site.
                </div>
                <div className="termsTextDescription">
                  Advertisers acknowledge and agree that the provisions of our Digital Millennium Copyright Act ("DMCA") Notice and Policy, as described below, apply to such advertisements. Advertisers also acknowledge and agree that there will be no refunds or other forms of remuneration for DMCA takedown-related issues. We have no other interaction with advertisers other than to provide the platform for such adverts.   
                </div>
                <div className="termsTextTitle">SITE MANAGEMENT</div>
                <div className="termsTextDescription">
                  Although we are not obligated to, we reserve the right to:
                </div>
                <ol className="ol" type="a">
                  <li>check the website for any violations of these terms and conditions;</li>
                  <li>anyone who, in our sole judgement, violates the law or these Terms of Use will be subject to appropriate legal action, which may include but is not limited to reporting the offending user to police enforcement;</li>
                  <li>Any of your contributions or any portion thereof may be rejected, denied access to, limited in availability, or disabled (to the degree technologically possible) at our sole discretion;</li>
                  <li>All files and content that are excessively large or otherwise burdensome to our systems may be removed from the Site or otherwise disabled at our sole discretion and without restriction, notification, or liability;</li>
                  <li>otherwise take care of the Site in a way that promotes its proper operation while safeguarding our rights and property.</li>
                </ol>
                <div className="termsTextTitle">PRIVACY POLICY</div>
                <div className="termsTextDescription">
                  Data security and privacy are important to us. Please read the Site's posted Privacy Statement by clicking [HERE]. You consent to be governed by our Privacy Policy, which is incorporated into these Terms of Use, by using the Site. Please be aware that United Kingdon  is where the website is hosted.
                </div>
                <div className="termsTextDescription">
                  By continuing to use the Site, you are sending your data to United Kingdom and you hereby give your express consent for such data to be sent to and processed in United kingdom if you are accessing the Site from the European Union, Asia, or any other region of the world where applicable laws regarding the collection, use, or disclosure of personal data differ from applicable laws in England.
                </div>
                <div className="termsTextDescription">
                  Furthermore, we do not knowingly market to children or accept, request, or solicit information from them.
                </div>
                <div className="termsTextTitle">DIGITAL MILLENNIUM COPYRIGHT ACT (DMCA) NOTICE AND POLICY</div>
                <div className="termsTextSubTitle">Notifications</div>
                <div className="termsTextDescription">
                  We honor other people's rights to their intellectual property. Please immediately notify our Designated Copyright Agent using the contact information provided below (a "Notification") if you think any content on or accessible via the Site violates any copyright you are the owner of or otherwise have control over.
                </div>
                <div className="termsTextDescription">
                  The individual who posted or stored the content mentioned in your notification will receive a copy of it. Please be aware that if you make serious misrepresentations in a Notification, you may be held accountable for damages under federal law. Therefore, you should think about first consulting an attorney if you are unsure if any content on the Site or one of its links violates your copyright.
                </div>
                <div className="termsTextSubTitle">Counter Notification</div>
                <div className="termsTextDescription">
                  You can send us/our Designated Copyright Agent a written counter notification (a "Counter Notification") if you think your own copyrighted content was taken from the Site due to an error or misidentification. The contact information is provided below.
                </div>
                <div className="termsTextDescription">
                  If you send us a legitimate, written Counter Notification, we will reinstate the material that has been removed or disabled unless we first receive notification from the party who filed the Notification informing us that such party has initiated legal action to prevent you from engaging in infringement-related activity.
                </div>
                <div className="termsTextDescription">
                  Please be aware that you might be held accountable for damages, including costs and attorney's fees, if you materially misrepresent that the content was disabled or removed by mistake or misidentification. Filing a false Counter Notification constitutes perjury.
                </div>
                <div className="termsTextDescription">
                  Attn: Copyright Agent
                  <br/>Yamu Ltd
                  <br/>61c Rivington st
                  <br/>London
                  <br/>EC2A 3QQ
                </div>
                <div className="termsTextTitle">COPYRIGHT INFRINGEMENTS</div>
                <div className="termsTextDescription">
                  We honor other people's rights to their intellectual property. Please contact us straight away using the details listed below (a "Notification") if you feel that any content on or accessible via the Site violates any copyright you may have. The individual who posted or stored the content mentioned in your notification will receive a copy of it.
                </div>
                <div className="termsTextDescription">
                  Please be aware that if you make serious misrepresentations in a Notification, you may be held accountable for damages under federal law. Therefore, you should think about first consulting an attorney if you are unsure if any content on the Site or one of its links violates your copyright.
                </div>
                <div className="termsTextTitle">CORRECTIONS</div>
                <div className="termsTextDescription">
                  The Site may contain typographical mistakes, inaccuracies, or omissions that may relate to the descriptions, prices, availability, or other information. We retain the right to alter or update the information on the Site at any time, without prior notice, in order to address any mistakes, inaccuracies, or omissions.
                </div>
                <div className="termsTextTitle">DISCLAIMER</div>
                <div className="termsTextDescription">
                  The website is made accessible "as is" and "as available." You acknowledge that using the website's services is done at your own risk. We disclaim all warranties, explicit or implicit, in relation to the site and your use of it, including, without limitation, implied warranties of merchantability, fitness for a particular purpose, and non-infringement, to the maximum extent permissible by law.
                  <br/>We will not accept duty or responsibility for any errors or omissions in the site's material, the content of any websites connected to this one, and we make no guarantees or claims
                </div>
                <ol className="ol" type="a">
                  <li>regarding its correctness or completeness.</li>
                  <li>omissions, faults, or inaccuracies in the contents and content</li>
                  <li>any type of physical harm or material loss brought on by your use of the site or access to it,</li>
                  <li>any unlawful access to or use of our secure systems, including any unauthorized access to or use of any personal information, financial information,</li>
                  <li>if there is a halt to transmission to or from the location,</li>
                  <li>any bugs, viruses, trojan horses, or similar items that any third party might send to or through the website, and/or </li>
                  <li>any mistakes or omissions in the materials and content, as well as for any loss or harm of any kind brought on by the use of any materials or content posted, communicated, or otherwise made accessible via the website.</li>
                </ol>
                <div className="termsTextDescription">
                  Any product or service advertised or offered by a third party through the website, any linked website, any website or mobile application featured in any banner ad, or in any other manner, is not warranted, endorsed, guaranteed, or assumed by us to be of any quality, and we have no involvement in, and take no responsibility for, the monitoring of, any transaction between you and any third-party suppliers of products or services. You should use caution when necessary and use your best judgment when buying a good or service, regardless of the venue or location.
                </div>
                <div className="termsTextTitle">LIMITATIONS OF LIABILITY</div>
                <div className="termsTextDescription">
                  Even if we have been informed of the possibility of such damages, in no event will we or our directors, employees, or agents be liable to you or any third party for any direct, indirect, consequential, exemplary, incidental, special, or punitive damages, including lost profit, lost revenue, loss of data, or other damages arising from your use of the website. Despite anything to the contrary in this document, our liability to you for any reason and regardless of the form of the action will always be limited to the lesser of $100 or the amount you paid, if any, to us in the 12 months prior to the cause of action occurring. Limitations on implied warranties and the exclusion or restriction of certain damages are prohibited by some state laws. If these laws apply to you, some or all of the aforementioned limits or disclaimers may not be applicable to you, and you could even be entitled to more rights.
                </div>
                <div className="termsTextTitle">INDEMNIFICATION</div>
                <div className="termsTextDescription">
                  You promise to protect us, our subsidiaries, affiliates, and each of our officers, agents, business partners, and staff members from and against any loss, damage, responsibility, claim, or demand made by any third party owing to or resulting from:
                </div>
                <ol className="ol" type="a">
                  <li>your contributions;</li>
                  <li>using the website;</li>
                  <li>violates these terms and conditions;</li>
                  <li>any violation of the promises and representations you've made in these terms of use;</li>
                  <li>your infringement of another party's rights, particularly those related to intellectual property, or</li>
                  <li>any overtly detrimental behavior you display toward another site user with whom you associated there. </li>
                </ol>
                <div className="termsTextDescription">
                  Despite the aforementioned, we retain the right to assume the exclusive defense and control of any matter for which you are obligated to indemnify us, and you agree to assist us in the defense of such claims at your expense. As soon as we become aware of any such claim, action, or process that is covered by this indemnity, we shall make a reasonable effort to let you know about it. 
                </div>
                <div className="termsTextTitle">EXTERNAL LINKS DISCLAIMER</div>
                <div className="termsTextDescription">
                  The Site  may contain (or you may be sent through the Site  links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising). Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability or completeness by us.
                </div>
                <div className="termsTextDescription">
                  We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information offered by third-party websites linked through the site or any website or feature linked in any banner or other advertising. We will not be a party to or in any way be responsible for monitoring any transaction between you and third-party providers of products or services.
                </div>
                <div className="termsTextTitle">PROFESSIONAL DISCLAIMER</div>
                <div className="termsTextDescription">
                  The Site cannot and does not contain medical/legal/fitness/health/other advice. The legal/medical/fitness/health/other information is provided for general informational and educational purposes only and is not a substitute for professional advice.
                </div>
                <div className="termsTextDescription">
                  Accordingly, before taking any actions based upon such information, we encourage you to consult with the appropriate professionals. We do not provide any kind of medical/legal/fitness/health/other advice. The use or reliance of any information contained on this site  is solely at your own risk.
                </div>
                <div className="termsTextTitle">AFFILIATES DISCLAIMER</div>
                <div className="termsTextDescription">
                  The Site  may contain links to affiliate websites, and we receive an affiliate commission for any purchases made by you on the affiliate website using such links. Our affiliates include Brandreward and Awin,
                </div>
                <div className="termsTextTitle">TESTIMONIALS DISCLAIMER</div>
                <div className="termsTextDescription">
                  The Site may contain testimonials by users of our products and/or services. These testimonials reflect the real-life experiences and opinions of such users. However, the experiences are personal to those particular users, and may not necessarily be representative of all users of our products and/or services. We do not claim, and you should not assume, that all users will have the same experiences. Your individual results may vary.
                </div>
                <div className="termsTextDescription">
                  The testimonials on the Site are submitted in various forms such as text, audio and/or video, and are reviewed by us before being posted. They appear on the Site verbatim as given by the users, except for the correction of grammar or typing errors. Some testimonials may have been shortened for the sake of brevity where the full testimonial contained extraneous information not relevant to the general public. 
                </div>
                <div className="termsTextDescription">
                  The views and opinions contained in the testimonials belong solely to the individual user and do not reflect our views and opinions. We are not affiliated with users who provide testimonials, and users are not paid or otherwise compensated for their testimonials.
                </div>
                <div className="termsTextTitle">USER DATA</div>
                <div className="termsTextDescription">
                  For the purpose of maintaining the Site's performance, we will keep track of some of the information you send to the Site, as well as information on how you use the Site. Despite the fact that we regularly backup your data, you are solely in charge of any data you communicate or that pertains to any activity you conduct while using the Site.
                </div>
                <div className="termsTextDescription">
                  You acknowledge that we are not responsible for any loss or corruption of such data, and you hereby release us from any claims you may have about such loss or corruption.
                </div>
                <div className="termsTextTitle">TERM AND TERMINATION</div>
                <div className="termsTextDescription">
                  While you use the Site, these Terms of Use will continue to be in full force and effect. Without limiting any other provision of these terms of use, we reserve the right to, in our sole discretion and without notice or liability, refuse access to and use of the site to anyone for any reason or for no reason at all, including without limitation for breach of any representation, warranty, or covenant contained in these terms of use or of any applicable law or regulation, including blocking specific IP addresses. In any time, without prior notice, and at our sole discretion, we may stop allowing you to use or participate in the site, or we may delete your account together with any material or information you have provided. 
                </div>
                <div className="termsTextDescription">
                  You are forbidden from registering and setting up a new account in the name of any third person, even if you could be acting on their behalf, even if we terminate or suspend your account for any reason.
                </div>
                <div className="termsTextDescription">
                  We reserve the right to pursue appropriate legal action, including without limitation seeking civil, criminal, and injunctive remedy, in addition to canceling or suspending your account.
                </div>
                <div className="termsTextTitle">MODIFICATIONS AND INTERRUPTIONS</div>
                <div className="termsTextDescription">
                  The Site's contents are subject to alteration, modification, or removal at any time and without prior notice for any reason. We are not obligated to update any material on our website, though. Additionally, we retain the right to change or stop offering the Site altogether at any time without prior notice.
                </div>
                <div className="termsTextDescription">
                  Any time the Site is altered, its price is raised, it is suspended, or it is discontinued, we won't be held responsible to you or any other person.
                </div>
                <div className="termsTextDescription">
                  The availability of the Site cannot be guaranteed at all times. We could encounter hardware, software, or other issues or need to carry out maintenance on the Site, which might cause disruptions, delays, or mistakes. Without providing you with prior notice, we retain the right to alter, suspend, or cancel the Site at any time and for any reason.
                </div>
                <div className="termsTextDescription">
                  You acknowledge and agree that we shall not be responsible or liable in any way for any loss, harm, or inconvenience resulting from your inability to access or use the Site during any outage or discontinuation of the Site. Nothing in these Terms of Use shall be deemed to oblige us to provide any corrections, updates, or releases in connection with the Site's upkeep and support.
                </div>
                <div className="termsTextTitle">GOVERNING LAW</div>
                <div className="termsTextDescription">
                  These Terms of Use and your use of the Site are governed by and construed in accordance with the laws of the United Kingdom without regard to its conflict of law principles. 
                </div>
                <div className="termsTextTitle">DISPUTE RESOLUTION</div>
                <div className="termsTextDescription">
                  The Parties agree to first attempt to negotiate any Dispute (aside from those Disputes expressly provided below) informally for at least 60 days before commencing arbitration in order to expedite resolution and manage the costs of any dispute, controversy, or claim related to these terms of use (each a "Dispute" and collectively, the "Disputes"). Following written notification from one party to the other, such informal negotiations begin.
                </div>
                <div className="termsTextDescription">
                  Except for those disputes that are specifically excluded herein, disputes between the Parties that cannot be settled via informal dialogue shall be ultimately and solely settled by binding arbitration. YOU UNDERSTAND THAT IN THE ABSENCE OF THIS PROVISION, YOU WOULD BE ENTITLED TO THE RIGHT TO SUE IN COURT.
                </div>
                <div className="termsTextDescription">
                  The arbitration must start and proceed in accordance with the relevant arbitration legislation.
                  The Parties hereby agree to, and waive any defenses of lack of personal jurisdiction and forum non conveniens with respect to venue and jurisdiction in the courts in London, in the event that a dispute is resolved via litigation rather than arbitration.
                </div>
                <div className="termsTextDescription">
                  Any dispute involving the Site that is raised by any Party must never be filed more than a year after the cause of action first materialized. A court of competent jurisdiction within the courts listed for jurisdiction above will resolve any disputes falling within this provision if it is determined that any part of it is unlawful or unenforceable, and the Parties agree to submit to the personal jurisdiction of such court. If this provision is determined to be unlawful or unenforceable, neither Party will choose to arbitrate any disputes falling within that portion of this provision.
                </div>
                <div className="termsTextDescription">
                  The Parties concur that any arbitration will only cover the particular disputes between the Parties. There is no right or authority for any dispute to be brought in a purported representative capacity on behalf of the general public or any other individuals, and no arbitration shall be joined with any other proceeding to the fullest extent permitted by law. There is also no right or authority for any dispute to be arbitrated on a class-action basis or to use class-action procedures.
                </div>
                <div className="termsTextDescription">
                  The Parties concur that the following Disputes are not covered by the aforementioned clauses regarding binding arbitration and informal negotiations:
                </div>
                <ol className="ol" type="a">
                  <li>any Disputes involving the enforcement, protection, or validity of a Party's intellectual property rights;</li>
                  <li>any Conflict involving, or resulting from, claims of Theft, Piracy, Privacy Invasion, or Unauthorized Use; and</li>
                  <li>any demand for an injunction. </li>
                </ol>
                <div className="termsTextDescription">
                  If any part of this clause is found to be unlawful or unenforceable, neither Party will choose to arbitrate any disputes that fall under that part of the clause; instead, those disputes will be resolved by a court of competent jurisdiction, and the Parties consent to the personal jurisdiction of that court.
                </div>
                <div className="termsTextTitle">ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</div>
                <div className="termsTextDescription">
                  Electronic communications include messages sent to us via email, website visits, and online form submissions. You agree that all agreements, notices, disclosures, and other communications that we give to you electronically through email and on the Site fulfill any legal need that such communications be in writing. You also consent to receiving electronic communications from us. THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AS WELL AS THE ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE, ARE HEREBY AGREEED TO BY YOU. 
                </div>
                <div className="termsTextDescription">
                  You hereby waive any rights or obligations you may have under any statutes, rules, regulations, ordinances, or other legislation in any area that call for an original signature, the delivery of paper documents, their storage, or the right to payment or credit by methods other than electronic ones. 
                </div>
                <div className="termsTextTitle">MISCELLANEOUS</div>
                <div className="termsTextDescription">
                  The only agreements and understandings between you and us are these Terms of Use and any other policies or operating guidelines we put on the website or in relation to the website. No right or provision of these Terms of Use shall be deemed to have been waived by our failure to exert or enforce such right or provision.
                </div>
                <div className="termsTextDescription">
                  To the largest extent permitted by law, these Terms of Use are in effect. All of our rights and duties are transferable at any time. Any loss, damage, delay, or failure to act brought on by a factor outside of our reasonable control shall not be our responsibility or liability. 
                </div>
                <div className="termsTextDescription">
                  If any term or portion of a term of these Terms of Use is found to be unlawful, invalid, or unenforceable, that term or portion is deemed severable from these Terms of Use and has no bearing on the legality or enforceability of any other terms that remain in place. These Terms of Use and your use of the Site do not establish any joint venture, partnership, employment relationship, or agency between you and us.
                </div>
                <div className="termsTextDescription">
                  You acknowledge that the fact that we wrote these terms of use should not be used against us. You hereby waive any and all objections you may have to these Terms of Use's electronic format and the parties' failure to execute them by hand.
                </div>
                <div className="termsTextTitle">CONTACT US</div>
                <div className="termsTextDescription">
                  In order to resolve a complaint regarding the Site or to receive further information regarding use of the Site, please contact us at:
                </div>
                <div className="termsTextDescription">
                  Yamu Ltd<br/>
                  61c Rivington St<br/>
                  London<br/>
                  EC2A 3QQ
                </div>
              </div>
            </div>
            <div style={{flex: 0.4}}/>
          </div>
          <div className="advStore" style={{marginTop: 300}}>
            <div className="advStoreTitle">
              <div className="advStoreTitleText">Join the revolution and help</div>
              <div className="advStoreTitleText">repair our planet from the</div>
              <div className="advStoreTitleText">grassroots up</div>
            </div>
            <div className="advStoreButtons">
              <AppStoreButton/>
              <GoogleStoreButton style={{marginLeft: 20, marginTop: 0}}/>
            </div>
          </div>
          <div className="seperator"/>
          <Footer/>
        </div>
      </div>
    );
  }
}

export default Terms;
