import React, { Component } from "react";

import "video-react/dist/video-react.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from "@fortawesome/free-solid-svg-icons";

import Header from "../components/header";
import Footer from "../components/footer";

import AppStoreButton from "../components/buttons/appstore";
import GoogleStoreButton from "../components/buttons/googlestore";

import "../../App.css";
import "./styles.css";

import backgroundImage from "../../res/images/img-home-background.png";
//import thumbupLeftImage from "../../res/images/img-global-thumbup-left.png";
//import thumbupRightImage from "../../res/images/img-global-thumbup-right.png";
import imageMia from "../../res/images/mia.png";
import imageSimon from "../../res/images/simon.png";

import {ReactComponent as HappyEmoji} from "../../res/images/img-global-emoji-happy.svg";

const BACKGROUND_SIZE = {
  height: 3286,
  width: 2861,
};

const SCREEN_HEIGHT = 2300;
const SCREEN_WIDTH = 1440;
const SCREEN_ASPECTRATIO = BACKGROUND_SIZE.width / BACKGROUND_SIZE.height;

const MOBILE_SCREEN_HEIGHT = 1780;
const MOBILE_SCREEN_WIDTH = 1440;
const MOBILE_SCREEN_ASPECTRATIO = MOBILE_SCREEN_WIDTH / MOBILE_SCREEN_HEIGHT;

const BACKGROUND_MARGINTOP = 0;
const VIDEO_WIDTH = 333;

class Mission extends Component {
  constructor(props) {
    super(props);

    var screenWidth = window.innerWidth;

    this.state = {
      screenWidth: screenWidth,
      participants: [],
      availableCount: 0,
    };
  }

  componentDidMount = async () => {
    window.addEventListener("resize", this.onWindowResized);
    await this.refresh();
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.onWindowResized);
  }

  refresh = async () => {
  }

  onWindowResized = () => {
    var screenWidth = window.innerWidth;
    this.setState({
      screenWidth: screenWidth,
    });
  }

  renderParticipant = (participant) => {
    return (
      <div className="missionParticipant">
        <FontAwesomeIcon className="missionParticipantIcon" icon={faUser}/>
      </div>
    );
  }

  renderMobileParticipant = (participant) => {
    return (
      <div className="missionMobileParticipant">
        <FontAwesomeIcon className="missionMobileParticipantIcon" icon={faUser}/>
      </div>
    );
  }

  renderMobile = () => {
    // body size
    var screenWidth = this.state.screenWidth - this.props.scrollbarWidth;
    var marginTop = BACKGROUND_MARGINTOP / (BACKGROUND_SIZE.width / this.state.screenWidth);
    var screenHeight = MOBILE_SCREEN_HEIGHT * (screenWidth / MOBILE_SCREEN_WIDTH);
    screenHeight = MOBILE_SCREEN_HEIGHT / MOBILE_SCREEN_ASPECTRATIO; //screenWidth / SCREEN_ASPECTRATIO - marginTop;

    // body margin
    var bodyMarginHorizontal = (screenWidth - VIDEO_WIDTH * 4) / 2;

    // participants width
    var contentWidth = screenWidth - 20;
    var availableCount = Math.floor((contentWidth + 10) / 42);
    var participantCount = availableCount - 1 + 8;
    var morePariticipantCount = participantCount - availableCount + 1;

    var participants = [];
    for (var i = 0; i < availableCount - 1; i++) {
      participants.push({
        name: "",
      });
    }

    return (
      <div className="App page">
        <Header
          // backgroundImage={backgroundImage}
          // backgroundMarginTop={marginTop}
          isMobile={true}
          screen="mission"
          screenSize={{
            height: screenWidth / SCREEN_ASPECTRATIO,
            width: screenWidth,
          }}
        />
        <div
          className="body"
          style={{
            backgroundColor: "rgb(0, 95, 176)",
            backgroundImage:`url(${backgroundImage})`,
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            height: screenHeight,
            width: screenWidth,
          }}
        >
          <div className="missionMobileTitle">Mission</div>
          <div className="missionMobileSubTitleText" style={{marginTop: 50}}>Welcome to</div>
          <div className="missionMobileSubTitle">
            <div className="missionMobileSubTitleText">Enviromates!</div>
            <HappyEmoji className="missionMobileSubTitleEmoji"/>
          </div>
          <div className="missionMobileDescription">
            With the planet at a critical turning point Enviromates was created to be a platform where individuals and organisations with passion and different skillsets can connect and build teams to crowdfund and scale the best environmental projects globally.
          </div>
          <div className="missionMobileDescription" style={{letterSpacing: 0}}>
            If we can create a thriving ecosystem with funding built-in and remove the many barriers that have slowed the wider uptake of collaboration on environmental ambitions we can make crowdfunding simple to launch, fast to deploy and incredibly rewarding to be part of.
          </div>
          <div className="missionMobileMargaret">
            “Never doubt that a small group of thoughtful, committed citizens can change the world; indeed, it’s the only thing that ever has.”
          </div>
          <div className="missionMobileMargaretName">— Margaret Mead</div>
          <div className="missionMobileDescription">
            The Enviromates social platform creates new ways for individuals and organisations to build their influence and allow people across the globe to launch and replicate projects easily. Existing eco-projects can utilise features to simplify their relationship with supporters and tap into the Enviromates community to secure ongoing financial support and help maximise their environmental impact for the long term.
          </div>
          <div className="missionMobileDescription">
            Enviromates has planted the seed but to make it grow will need love and support and we hope you will join us on that journey. Every step will make us stronger but only our united energy will succeed so join us and help repair our planet from the grassroots up!
          </div>
          <div className="missionMobileDoThis">Lets do this!</div>
          <div className="missionMobileDoThisContent">
            <img className="missionDoThisContentThumb" src={imageMia} width={180}/>
            <div className="missionMobileDoThisContentMia">Mia<br/>&<br/>Simon</div>
            <img className="missionDoThisContentThumb" src={imageSimon} width={180}/>
          </div>
          <div className="missionMobileStore" style={{marginTop: 262}}>
            <div className="missionMobileStoreTitle">
              <div className="missionMobileStoreTitleText">
                Join the revolution and help<br/>repair our planet from the<br/>grassroots up
              </div>
            </div>
            <div className="missionMobileStoreButtons">
              <AppStoreButton isMobile={true}/>
              <GoogleStoreButton isMobile={true} style={{marginLeft: 10, marginTop: 0}}/>
            </div>
          </div>
          <div className="seperator"/>
          <Footer isMobile={true}/>
        </div>
      </div>
    );
  }

  render = () => {
    if (this.state.screenWidth < 768) {
      return this.renderMobile();
    }

    // body size
    var screenWidth = this.state.screenWidth - this.props.scrollbarWidth;
    var marginTop = BACKGROUND_MARGINTOP / (BACKGROUND_SIZE.width / this.state.screenWidth);
    var screenHeight = SCREEN_HEIGHT * (screenWidth / SCREEN_WIDTH);
    screenHeight = SCREEN_HEIGHT / SCREEN_ASPECTRATIO; //screenWidth / SCREEN_ASPECTRATIO - marginTop;

    // body margin
    var bodyMarginHorizontal = (screenWidth - VIDEO_WIDTH * 4) / 2;

    // participants width
    var contentWidth = (screenWidth - 35 * 2);
    if (contentWidth > 534 + 801) {
      contentWidth = 534 + 801;
    }
    contentWidth -= 142;

    var availableCount = Math.floor((contentWidth + 10) / 50);
    var participantCount = availableCount - 1 + 8;
    var morePariticipantCount = participantCount - availableCount + 1;

    var participants = [];
    for (var i = 0; i < availableCount - 1; i++) {
      participants.push({
        name: "",
      });
    }

    return (
      <div className="App page">
        <Header
          // backgroundImage={backgroundImage}
          // backgroundMarginTop={marginTop}
          screen="mission"
          screenSize={{
            height: screenWidth / SCREEN_ASPECTRATIO,
            width: screenWidth,
          }}
        />
        <div
          className="body"
          style={{
            backgroundColor: "rgb(0, 95, 176)",
            backgroundImage:`url(${backgroundImage})`,
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            height: screenHeight,
            width: screenWidth,
          }}
        >
          <div className="missionText">Mission</div>
          <div className="missionSubTitle">
            <div className="missionSubTitleText">Welcome to Enviromates!</div>
            <HappyEmoji className="missionSubTitleEmoji"/>
          </div>
          <div className="missionDescription">
            With the planet at a critical turning point Enviromates was created to be a platform where individuals and organisations with passion and different skillsets can connect and build teams to crowdfund and scale the best environmental projects globally.
          </div>
          <div className="missionDescription" style={{letterSpacing: -1}}>
            If we can create a thriving ecosystem with funding built-in and remove the many barriers that have slowed the wider uptake of collaboration on environmental ambitions we can make crowdfunding simple to launch, fast to deploy and incredibly rewarding to be part of.
          </div>
          <div className="missionMargaret">
            “Never doubt that a small group of thoughtful, committed citizens can change the world; indeed, it’s the only thing that ever has.”
          </div>
          <div className="missionMargaretName">— Margaret Mead</div>
          <div className="missionDescription">
            The Enviromates social platform creates new ways for individuals and organisations to build their influence and allow people across the globe to launch and replicate projects easily. Existing eco-projects can utilise features to simplify their relationship with supporters and tap into the Enviromates community to secure ongoing financial support and help maximise their environmental impact for the long term.
          </div>
          <div className="missionDescription">
            Enviromates has planted the seed but to make it grow will need love and support and we hope you will join us on that journey. Every step will make us stronger but only our united energy will succeed so join us and help repair our planet from the grassroots up!
          </div>
          <div className="missionDoThisContent">
            <img className="missionDoThisContentThumb" src={imageMia} width={300}/>
            <div className="missionContextTextContainer">
              <div className="missionDoThis">Lets do this!</div>
              <div className="missionDoThisContentMia">Mia & Simon</div>
            </div>
            <img className="missionDoThisContentThumb" src={imageSimon} width={300}/>
          </div>
          <div className="advStore" style={{marginTop: 230}}>
            <div className="advStoreTitle">
              <div className="advStoreTitleText">Join the revolution and help</div>
              <div className="advStoreTitleText">repair our planet from the</div>
              <div className="advStoreTitleText">grassroots up</div>
            </div>
            <div className="advStoreButtons">
              <AppStoreButton/>
              <GoogleStoreButton style={{marginLeft: 20, marginTop: 0}}/>
            </div>
          </div>
          <div className="seperator"/>
          <Footer/>
        </div>
      </div>
    );
  }
}

export default Mission;
