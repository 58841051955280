import React, { Component } from "react";

import "./styles.css";

class GreenButton extends Component {
  constructor(props) {
    super(props);
  }

  onClick = () => {
    if (this.props.onClick == undefined || this.props.onClick == null) {
      return;
    }

    this.props.onClick();
  }

  renderMobile = () => {
    return (
      <div className="mobileBtnGreen" style={this.props.style} onClick={this.onClick}>
        <div className="mobileBtnGreenText" style={this.props.textStyle}>{this.props.title}</div>
      </div>
    );
  }

  render = () => {
    if (this.props.isMobile == true) {
      return this.renderMobile();
    }

    return (
      <div className="btnGreen" style={this.props.style} onClick={this.onClick}>
        <div className="btnGreenText" style={this.props.titleStyle}>{this.props.title}</div>
      </div>
    );
  }
}

export default GreenButton;
