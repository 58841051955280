import emailjs from "@emailjs/browser";

const EMAILJS_SERVICEID = "service_pbwt7os";
const EMAILJS_TEMPLATE_CONTACT = "template_cfw2bwv";
const EMAILJS_PUBLICKEY = "4xub28EgIhsLL2dEI";

export class EmailEngine {
  static sendContactEmail = async (data) => {
    try {
      var ret = await emailjs.send(
        EMAILJS_SERVICEID,
        EMAILJS_TEMPLATE_CONTACT,
        {
          name: data.name,
          email: data.email,
          message: data.message,
        },
        EMAILJS_PUBLICKEY
      );

      return {
        success: true,
      };
    } catch(error) {
      console.log("[EmailEngine] sendContactEmail() -> error: ", error);
      return {
        success: false,
        error: error.message,
      };
    }
  }
}
