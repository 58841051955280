import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";

import "video-react/dist/video-react.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from "@fortawesome/free-solid-svg-icons";

import Header from "../components/header";
import Footer from "../components/footer";

import AppStoreButton from "../components/buttons/appstore";
import GoogleStoreButton from "../components/buttons/googlestore";
import GreenButton from "../components/buttons/green";

import "../../App.css";
import "./styles.css";

import backgroundImage from "../../res/images/img-home-background.png";

import { ReactComponent as BeeHappyLeft } from "../../res/images/img-global-bee-happy-left.svg";
import { ReactComponent as BeeHeartRight } from "../../res/images/img-global-bee-heart-right.svg";

const BACKGROUND_SIZE = {
  height: 3286,
  width: 2861,
};

const SCREEN_HEIGHT = 2480;
const SCREEN_WIDTH = 1440;
const SCREEN_ASPECTRATIO = BACKGROUND_SIZE.width / BACKGROUND_SIZE.height;

const MOBILE_SCREEN_HEIGHT = 2140;
const MOBILE_SCREEN_WIDTH = 1440;
const MOBILE_SCREEN_ASPECTRATIO = MOBILE_SCREEN_WIDTH / MOBILE_SCREEN_HEIGHT;

const BACKGROUND_MARGINTOP = 0;
const VIDEO_WIDTH = 333;

class Challenges extends Component {
  constructor(props) {
    super(props);

    var screenWidth = window.innerWidth;

    this.state = {
      screenWidth: screenWidth,
      participants: [],
      availableCount: 0,
      showAppStoreModal: false,
    };
  }

  componentDidMount = async () => {
    window.addEventListener("resize", this.onWindowResized);
    await this.refresh();
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.onWindowResized);
  }

  refresh = async () => {
  }

  onWindowResized = () => {
    var screenWidth = window.innerWidth;
    this.setState({
      screenWidth: screenWidth,
    });
  }

  onShowAppStoreModal = () => {
    this.setState({
      showAppStoreModal: true,
    });
  }

  renderParticipant = (participant) => {
    return (
      <div className="chalParticipant">
        <FontAwesomeIcon className="chalParticipantIcon" icon={faUser}/>
      </div>
    );
  }

  renderMobileParticipant = (participant) => {
    return (
      <div className="chalMobileParticipant">
        <FontAwesomeIcon className="chalMobileParticipantIcon" icon={faUser}/>
      </div>
    );
  }

  renderMobile = () => {
    // body size
    var screenWidth = this.state.screenWidth - this.props.scrollbarWidth;
    var marginTop = BACKGROUND_MARGINTOP / (BACKGROUND_SIZE.width / this.state.screenWidth);
    var screenHeight = MOBILE_SCREEN_HEIGHT * (screenWidth / MOBILE_SCREEN_WIDTH);
    screenHeight = MOBILE_SCREEN_HEIGHT / MOBILE_SCREEN_ASPECTRATIO; //screenWidth / SCREEN_ASPECTRATIO - marginTop;

    // body margin
    var bodyMarginHorizontal = (screenWidth - VIDEO_WIDTH * 4) / 2;

    // participants width
    var contentWidth = screenWidth - 20;
    var availableCount = Math.floor((contentWidth + 10) / 42);
    var participantCount = availableCount - 1 + 8;
    var morePariticipantCount = participantCount - availableCount + 1;

    var participants = [];
    for (var i = 0; i < availableCount - 1; i++) {
      participants.push({
        name: "",
      });
    }

    return (
      <div className="App page">
        <Header
          // backgroundImage={backgroundImage}
          // backgroundMarginTop={marginTop}
          isMobile={true}
          screen="challenges"
          screenSize={{
            height: screenWidth / SCREEN_ASPECTRATIO,
            width: screenWidth,
          }}
        />
        <div
          className="body"
          style={{
            backgroundColor: "rgb(0, 95, 176)",
            backgroundImage:`url(${backgroundImage})`,
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            height: screenHeight,
            width: screenWidth,
          }}
        >
          <div className="chalMobileTitle">
            <BeeHeartRight className="chalMobileTitleBee"/>
            <div className="chalMobileTitleText" style={{fontSize: 80, marginTop: -120}}>Join our</div>
            <div className="chalMobileTitleText">Global Challenges</div>
            <div className="chalMobileTitleDescription">
              <div className="chalMobileTitleDescriptionText">
                Join existing teams or create your own and be part of a global grassroots movement to help tackle the planets environmental challenges for the long-term and be supported through the Enviromates community with love and a little eco earnings to keep you going.
              </div>
            </div>
          </div>
          <div className="chalMobileBigBee">
            <div className="chalMobileBigBeeBeeHappy">
              <BeeHappyLeft className="chalMobileBigBeeBeeHappyImage"/>
            </div>
            <div className="chalMobileBigBeeTitle">
              <div className="chalMobileBigBeeTitleText" style={{fontSize: 50}}>The</div>
              <div className="chalMobileBigBeeTitleText">Big Bee<br/>challenge</div>
            </div>
            <GreenButton isMobile={true} style={{marginTop: 40}} title="Let's do this!" onClick={this.onShowAppStoreModal}/>
            <div className="chalMobileBigBeeDescription">
              Bee populations are declining and are essential to our future. Join the Big Bee Challenge and help recover Bee populations and support wild flower meadow planting. Pick one of the curated Bee project through the app and invite some friends to join and go it alone. The Enviromates community will help you crowdfund to secure the funding. Update your followers and lets work together to make a long-term difference to our most important pollinator.
            </div>
            <div className="chalMobileBigBeeQuickTitle">
              <div className="chalMobileBigBeeQuickTitleText">Quickfacts</div>
            </div>
            <div className="chalMobileBigBeeInfo">
              <div className="chalMobileBigBeeInfoPercent">90%</div>
              <div className="chalMobileBigBeeInfoPercentDescription">
                fall in the estimated<br/>number of Bees since 1962
              </div>
            </div>
            <div className="chalMobileBigBeeInfo">
              <div className="chalMobileBigBeeInfoPercent">66%</div>
              <div className="chalMobileBigBeeInfoPercentDescription">
                of all the world’s crops<br/>are pollinated by Bees
              </div>
            </div>
            <div className="chalMobileBigBeeInfo">
              <div className="chalMobileBigBeeInfoPercent">85%</div>
              <div className="chalMobileBigBeeInfoPercentDescription">
                of the worlds flowering<br/>plants are pollinated by Bees
              </div>
            </div>
            <div className="chalMobileParticipants">
              <div className="chalMobileParticipantsText">Participants</div>
              <div className="chalMobileParticipantList">
                { participants.map((participant, index) => this.renderMobileParticipant(participant)) }
                { morePariticipantCount > 0 && 
                  <div className="chalMobileParticipantMore">+{morePariticipantCount}</div>
                }
              </div>
            </div>
          </div>
          <div className="chalMobileBigBee" style={{marginTop: 200}}>
            <div className="chalMobileBigBeeTitle">
              <div className="chalMobileBigBeeTitleText">The<br/>Beach Clean<br/>challenge</div>
            </div>
            <GreenButton isMobile={true} style={{marginTop: 40}} title="Let's do this!" onClick={this.onShowAppStoreModal}/>
            <div className="chalMobileBigBeeDescription">
              Join an existing beach clean team or create your own (others will join you). Pick a stretch of coastline, collect rubbish left behind or washed up with the tide and help stop it reaching our oceans. Post what you find to your followers and let the Enviromate community shower you with support and help grow your eco earnings to keep this going for the long-term. 
            </div>
            <div className="chalMobileBigBeeQuickTitle">
              <div className="chalMobileBigBeeQuickTitleText">Quickfacts</div>
            </div>
            <div className="chalMobileBigBeeInfo">
              <div className="chalMobileBigBeeInfoPercent">100M</div>
              <div className="chalMobileBigBeeInfoPercentDescription">
                marine animals die<br/>each year from plastic<br/>waste alone
              </div>
            </div>
            <div className="chalMobileBigBeeInfo">
              <div className="chalMobileBigBeeInfoPercent">11M</div>
              <div className="chalMobileBigBeeInfoPercentDescription">
                Metric tonnes enter the<br/>oceans every day
              </div>
            </div>
            <div className="chalMobileBigBeeInfo">
              <div className="chalMobileBigBeeInfoPercent">15%</div>
              <div className="chalMobileBigBeeInfoPercentDescription">
                of all ocean plastics come<br/>from our beaches
              </div>
            </div>
            <div className="chalMobileParticipants">
              <div className="chalMobileParticipantsText">Participants</div>
              <div className="chalMobileParticipantList">
                { participants.map((participant, index) => this.renderMobileParticipant(participant)) }
                { morePariticipantCount > 0 && 
                  <div className="chalMobileParticipantMore">+{morePariticipantCount}</div>
                }
              </div>
            </div>
          </div>
          <div className="chalMobileStore" style={{marginTop: 262}}>
            <div className="chalMobileStoreTitle">
              <div className="chalMobileStoreTitleText">
                Join the revolution and help<br/>repair our planet from the<br/>grassroots up
              </div>
            </div>
            <div className="chalMobileStoreButtons">
              <AppStoreButton isMobile={true}/>
              <GoogleStoreButton isMobile={true} style={{marginLeft: 10, marginTop: 0}}/>
            </div>
          </div>
          <div className="seperator"/>
          <Footer isMobile={true}/>
        </div>

        <Modal
          centered
          dialogClassName="modbileAppStoreModal"
          onHide={() => this.setState({showAppStoreModal: false})}
          show={this.state.showAppStoreModal}
        >
          <Modal.Header className="modbileAppStoreModalHeader" closeButton>
          </Modal.Header>
          <Modal.Body className="modbileAppStoreModalBody">
            <div className="modbileAppStoreModalBodyTitle">
              <div className="modbileAppStoreModalBodyTitleText">
                Join the revolution and help repair our planet from the grassroots up
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="modbileAppStoreModalFooter">
            <AppStoreButton isMobile={true}/>
            <GoogleStoreButton isMobile={true} style={{marginTop: 10,}}/>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  render = () => {
    if (this.state.screenWidth < 768) {
      return this.renderMobile();
    }

    // body size
    var screenWidth = this.state.screenWidth - this.props.scrollbarWidth;
    var marginTop = BACKGROUND_MARGINTOP / (BACKGROUND_SIZE.width / this.state.screenWidth);
    var screenHeight = SCREEN_HEIGHT * (screenWidth / SCREEN_WIDTH);
    screenHeight = SCREEN_HEIGHT / SCREEN_ASPECTRATIO; //screenWidth / SCREEN_ASPECTRATIO - marginTop;

    // body margin
    var bodyMarginHorizontal = (screenWidth - VIDEO_WIDTH * 4) / 2;

    // participants width
    var contentWidth = (screenWidth - 35 * 2);
    if (contentWidth > 534 + 801) {
      contentWidth = 534 + 801;
    }
    contentWidth -= 142;

    var availableCount = Math.floor((contentWidth + 10) / 50);
    var participantCount = availableCount - 1 + 8;
    var morePariticipantCount = participantCount - availableCount + 1;

    var participants = [];
    for (var i = 0; i < availableCount - 1; i++) {
      participants.push({
        name: "",
      });
    }

    return (
      <div className="App page">
        <Header
          // backgroundImage={backgroundImage}
          // backgroundMarginTop={marginTop}
          screen="challenges"
          screenSize={{
            height: screenWidth / SCREEN_ASPECTRATIO,
            width: screenWidth,
          }}
        />
        <div
          className="body"
          style={{
            backgroundColor: "rgb(0, 95, 176)",
            backgroundImage:`url(${backgroundImage})`,
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            height: screenHeight,
            width: screenWidth,
          }}
        >
          <div className="chalTitle">
            <BeeHeartRight className="chalTitleBee"/>
            <div className="chalTitleTextSmall" style={{marginTop: -80}}>Join our</div>
            <div className="chalTitleText">Global Challenges</div>
            <div className="chalTitleDescription">
              <div className="chalTitleDescriptionText">
                Join existing teams or create your own and be part of a global grassroots movement to help tackle the planets environmental challenges for the long-term and be supported through the Enviromates community with love and a little eco earnings to keep you going.
              </div>
            </div>
          </div>
          <div className="chalBigBee">
            <div className="chalBigBeeRow">
              <div className="chalBigBeeLeft">
                <BeeHappyLeft className="chalBigBeeLeftBeeHappyImage"/>
                <div className="chalBigBeeTitle">
                  <div className="chalBigBeeTitleTextSmall">The</div>
                  <div className="chalBigBeeTitleText">Big Bee<br/>challenge</div>
                </div>
              </div>
              <div className="chalBigBeeRight" style={{alignItems: "flex-end"}}>
                <GreenButton title="Let's do this!" onClick={this.onShowAppStoreModal}/>
              </div>
            </div>
            <div className="chalBigBeeRow" style={{marginTop: 25}}>
              <div className="chalBigBeeLeft">
                <div className="chalBigBeeDescription">
                  Bee populations are declining and are essential to our future. Join the Big Bee Challenge and help recover Bee populations and support wild flower meadow planting. Pick one of the curated Bee project through the app and invite some friends to join and go it alone. The Enviromates community will help you crowdfund to secure the funding. Update your followers and lets work together to make a long-term difference to our most important pollinator.
                </div>
              </div>
              <div className="chalBigBeeRight">
                <div className="chalBigBeeRightTitle">
                  <div className="chalBigBeeRightTitleText">Quickfacts</div>
                </div>
                <div className="chalBigBeeRightRow">
                  <div className="chalBigBeeRightCol">
                    <div className="chalBigBeeRightPercent">90%</div>
                    <div className="chalBigBeeRightPercentDescription">
                      fall in the estimated<br/>number of Bees since 1962
                    </div>
                  </div>
                  <div className="chalBigBeeRightCol">
                    <div className="chalBigBeeRightPercent">66%</div>
                    <div className="chalBigBeeRightPercentDescription">
                      of all the world’s crops<br/>are pollinated by Bees
                    </div>
                  </div>
                  <div className="chalBigBeeRightCol">
                    <div className="chalBigBeeRightPercent">85%</div>
                    <div className="chalBigBeeRightPercentDescription">
                      of the worlds flowering<br/>plants are pollinated by Bees
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="chalParticipants">
              <div className="chalParticipantsText">Participants</div>
              <div className="chalParticipantList">
                { participants.map((participant, index) => this.renderParticipant(participant)) }
                { morePariticipantCount > 0 && 
                  <div className="chalParticipantMore">+{morePariticipantCount}</div>
                }
              </div>
            </div>
          </div>
            <div className="chalBigBee" style={{marginTop: 304}}>
              <div className="chalBigBeeRow">
                <div className="chalBigBeeLeft">
                  <div className="chalBigBeeTitle" style={{justifyContent: "center"}}>
                    <div className="chalBigBeeTitleTextSmall">The</div>
                    <div className="chalBigBeeTitleText">Beach Clean<br/>challenge</div>
                  </div>
                </div>
                <div className="chalBigBeeRight" style={{alignItems: "flex-end"}}>
                  <GreenButton title="Let's do this!" onClick={this.onShowAppStoreModal}/>
                </div>
              </div>
              <div className="chalBigBeeRow" style={{marginTop: 25}}>
                <div className="chalBigBeeLeft">
                  <div className="chalBigBeeDescription">
                    Join an existing beach clean team or create your own (others will join you). Pick a stretch of coastline, collect rubbish left behind or washed up with the tide and help stop it reaching our oceans. Post what you find to your followers and let the Enviromate community shower you with support and help grow your eco earnings to keep this going for the long-term. 
                  </div>
                </div>
                <div className="chalBigBeeRight">
                  <div className="chalBigBeeRightTitle">
                    <div className="chalBigBeeRightTitleText">Quickfacts</div>
                  </div>
                  <div className="chalBigBeeRightRow">
                    <div className="chalBigBeeRightCol">
                      <div className="chalBigBeeRightPercent">100M</div>
                      <div className="chalBigBeeRightPercentDescription">
                        marine animals die<br/>each year from plastic<br/>waste alone
                      </div>
                    </div>
                    <div className="chalBigBeeRightCol">
                      <div className="chalBigBeeRightPercent">11M</div>
                      <div className="chalBigBeeRightPercentDescription">
                        Metric tonnes enter the<br/>oceans every day
                      </div>
                    </div>
                    <div className="chalBigBeeRightCol">
                      <div className="chalBigBeeRightPercent">15%</div>
                      <div className="chalBigBeeRightPercentDescription">
                        of all ocean plastics come<br/>from our beaches
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="chalParticipants">
                <div className="chalParticipantsText">Participants</div>
                <div className="chalParticipantList">
                  { participants.map((participant, index) => this.renderParticipant(participant)) }
                  { morePariticipantCount > 0 && 
                    <div className="chalParticipantMore">+{morePariticipantCount}</div>
                  }
                </div>
              </div>
            </div>
            <div className="advStore" style={{marginTop: 282}}>
              <div className="advStoreTitle">
                <div className="advStoreTitleText">Join the revolution and help</div>
                <div className="advStoreTitleText">repair our planet from the</div>
                <div className="advStoreTitleText">grassroots up</div>
              </div>
              <div className="advStoreButtons">
                <AppStoreButton/>
                <GoogleStoreButton style={{marginLeft: 20, marginTop: 0}}/>
              </div>
            </div>
            <div className="seperator"/>
            <Footer/>
          </div>

          <Modal
            centered
            dialogClassName="appStoreModal"
            onHide={() => this.setState({showAppStoreModal: false})}
            show={this.state.showAppStoreModal}
          >
            <Modal.Header className="appStoreModalHeader" closeButton>
            </Modal.Header>
            <Modal.Body className="appStoreModalBody">
              <div className="appStoreModalBodyTitle">
                <div className="appStoreModalBodyTitleText">
                  Join the revolution and help repair<br/>our planet from the grassroots up
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="appStoreModalFooter">
              <AppStoreButton/>
              <GoogleStoreButton style={{marginTop: 0, marginLeft: 20, marginRight: 0}}/>
            </Modal.Footer>
          </Modal>
      </div>
    );
  }
}

export default Challenges;
