import React, { Component } from "react";
import axios from 'axios';
import qs from 'qs';

import "../../App.css";
import "./paypal.css";

class PaypalAuth extends Component {
  constructor(props) {
    super(props);

    var code = "";
    try {
      code = this.props.searchParams.get("code");
    } catch(error) {}

    this.state = {
      code: code,
      isAuthorizing: true,
      userInfo: null,
    };
  }

  componentDidMount = async () => {
    await this.refresh();
  }

  focusScreen = async () => {
    await this.refresh();
  }

  refresh = async () => {
    var userInfo = null;
    var code = this.state.code;

    if (code != null) {
      var accessToken = await this.getAccessToken(code);
      if (accessToken != "") {
        userInfo = await this.getUserInfo(accessToken);
      }
    }

    this.setState({
      isAuthorizing: false,
      userInfo: userInfo,
    });
  }

  getAccessToken = async (code) => {
    var accessToken = "";
    try {
      var data = qs.stringify({
        'grant_type': 'authorization_code',
        'code': code 
      });

      var config = {
        method: 'post',
        url: 'https://api-m.sandbox.paypal.com/v1/oauth2/token',
        headers: {
          'Authorization': 'Basic QWNvMUR3d1NDX1BZeXdQeUdYSURxbHl4bUd4TVE1NGx5Q1J3em1ycXV6SHRsbWZzMHFKZmVqQXNOOXJieWRoLWtldDlpbm1yOGFSQUxTWXo6RUxJNm1FaFBEU0RQaEI1RUNUZVZPWmg1QlNzLUhMU084eDVFcTM2aWM5bTdINFRxYzZJTF80bWxEVENoNDh3ME9Nd0x3ZWktNS1SOUQzNlE=', 
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data : data
      };

      var response = await axios(config);
      var json = response.data;
      if (json.access_token) {
        accessToken = json.access_token;
      }
    } catch(error) {
      console.log("[Auth] getAccessToken() -> error: " + error.message);
    }

    return accessToken;
  }

  getUserInfo = async (accessToken) => {
    var userInfo = null;
    try {
      var data = qs.stringify({});
      var config = {
        method: 'get',
        url: 'https://api-m.sandbox.paypal.com/v1/identity/oauth2/userinfo?schema=paypalv1.1',
        headers: {
          'Authorization': 'Bearer ' + accessToken
        },
        data : data
      };

      var response = await axios(config);
      userInfo = response.data;
    } catch(error) {
      console.log("[Auth] getUserInfo() -> error: " + error.message);
    }

    return userInfo;
  }

  render = () => {
    if (this.state.code == null) {
      return (
        <div className="App">
          <div className="PaypalContent" id='lippButton'></div>
        </div>
      );
    }

    if (this.state.isAuthorizing) {
      return (
        <div className="App">
          <div className="PaypalContent">
            <div className="PaypalText">Please wait while authorizing your paypal account...</div>
          </div>
        </div>
      );
    }

    if (this.state.userInfo == null) {
      return (
        <div className="App">
          <div className="PaypalContent">
            <div className="PaypalText">
              Failed to authorize your paypal account.
              <br /><br />
              <a id="aReturn" href="https://yamu-dev.web.app/paypal/auth">Please try again.</a>
            </div>
          </div>
        </div>
      );
    }

    // tell app
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify(this.state.userInfo));
    }

    return (
      <div className="App">
        <div className="PaypalContent">
          <div className="PaypalText">Your paypal account has been successfully connected.</div>
        </div>
      </div>
    );
  }
}

export default PaypalAuth;
