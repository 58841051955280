import React, { Component } from "react";

import { Link } from "react-router-dom"; 

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple } from "@fortawesome/free-brands-svg-icons";

import "./styles.css";

class AppStoreButton extends Component {
  constructor(props) {
    super(props);
  }

  renderMobile = () => {
    return (
      <a href="https://apps.apple.com/gb/app/enviromates-earth/id1523677820" className="btnAppStoreLink" target="_blank" rel="noopener noreferrer">
        <div className="mobileBtnAppStore" style={this.props.style}>
          <FontAwesomeIcon className="mobileBtnAppStoreIcon" icon={faApple}/>
          <div className="mobileBtnAppStoreTitle">
            <div className="mobileBtnAppStoreTitleText">Download on the</div>
            <div className="mobileBtnAppStoreTitleStore" style={{marginTop: -5}}>App Store</div>
          </div>
        </div>
      </a>
    );
  }

  render = () => {
    if (this.props.isMobile == true) {
      return this.renderMobile();
    }

    return (
      <a href="https://apps.apple.com/gb/app/enviromates-earth/id1523677820" className="btnAppStoreLink" target="_blank" rel="noopener noreferrer">
        <div className="btnAppStore" style={this.props.style}>
          <FontAwesomeIcon className="btnAppStoreIcon" icon={faApple}/>
          <div className="btnAppStoreTitle">
            <div className="btnAppStoreTitleText">Download on the</div>
            <div className="btnAppStoreTitleStore">App Store</div>
          </div>
        </div>
      </a>
    );
  }
}

export default AppStoreButton;
