import React, { Component } from "react";

import "video-react/dist/video-react.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from "@fortawesome/free-solid-svg-icons";

import Header from "../components/header";
import Footer from "../components/footer";

import AppStoreButton from "../components/buttons/appstore";
import GoogleStoreButton from "../components/buttons/googlestore";

import "../../App.css";
import "./styles.css";

import backgroundImage from "../../res/images/img-home-background.png";

const BACKGROUND_SIZE = {
  height: 6686,
  width: 2861,
};

const SCREEN_HEIGHT = 1920;
const SCREEN_WIDTH = 1440;
const SCREEN_ASPECTRATIO = BACKGROUND_SIZE.width / BACKGROUND_SIZE.height;

const MOBILE_SCREEN_HEIGHT = 2590;
const MOBILE_SCREEN_WIDTH = 1440;
const MOBILE_SCREEN_ASPECTRATIO = MOBILE_SCREEN_WIDTH / MOBILE_SCREEN_HEIGHT;

const BACKGROUND_MARGINTOP = 0;
const VIDEO_WIDTH = 333;

class Cookie extends Component {
  constructor(props) {
    super(props);

    var screenWidth = window.innerWidth;

    this.state = {
      screenWidth: screenWidth,
      participants: [],
      availableCount: 0,
    };
  }

  componentDidMount = async () => {
    window.addEventListener("resize", this.onWindowResized);
    await this.refresh();
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.onWindowResized);
  }

  refresh = async () => {
  }

  onWindowResized = () => {
    var screenWidth = window.innerWidth;
    this.setState({
      screenWidth: screenWidth,
    });
  }

  renderMobile = () => {
    // body size
    var screenWidth = this.state.screenWidth - this.props.scrollbarWidth;
    var marginTop = BACKGROUND_MARGINTOP / (BACKGROUND_SIZE.width / this.state.screenWidth);
    var screenHeight = MOBILE_SCREEN_HEIGHT * (screenWidth / MOBILE_SCREEN_WIDTH);
    screenHeight = MOBILE_SCREEN_HEIGHT / MOBILE_SCREEN_ASPECTRATIO; //screenWidth / SCREEN_ASPECTRATIO - marginTop;

    // body margin
    var bodyMarginHorizontal = (screenWidth - VIDEO_WIDTH * 4) / 2;

    // participants width
    var contentWidth = screenWidth - 20;
    var availableCount = Math.floor((contentWidth + 10) / 42);
    var participantCount = availableCount - 1 + 8;
    var morePariticipantCount = participantCount - availableCount + 1;

    var participants = [];
    for (var i = 0; i < availableCount - 1; i++) {
      participants.push({
        name: "",
      });
    }

    return (
      <div className="App page">
        <Header
          // backgroundImage={backgroundImage}
          // backgroundMarginTop={marginTop}
          isMobile={true}
          screen="cookie"
          screenSize={{
            height: screenWidth / SCREEN_ASPECTRATIO,
            width: screenWidth,
          }}
        />
        <div
          className="body"
          style={{
            backgroundColor: "rgb(0, 95, 176)",
            backgroundImage:`url(${backgroundImage})`,
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            height: screenHeight,
            width: screenWidth,
          }}
        >
          <div className="cookieMobileBody">
            <div className="cookieMobileTitle">Cookie Policy</div>
            <div className="cookieMobileDescription">
              Enviromates strives to create a transparent and honest narrative with its users and wherever possible to phase out the use of cookies overtime and work towards a new digital future protecting your privacy but helping to repair our planet.
            </div>
            <div className="cookieMobileDescription">
              We have much work to do and we will update our cookie policy as we can.
            </div>
            <div className="cookieMobileDescription">
              Last updated [8th December 2022]
            </div>
            <hr className="hr"/>
            <div className="cookieMobileSubTitle">INTRODUCTION</div>
            <div className="cookieMobileDescription">
              Enviromates (“we” or “us” or “our”) may use cookies, web beacons, tracking pixels, and other tracking technologies when you visit our website http://www.enviromates.earth, including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the “Site”) to help customize the Site and improve your experience. 
            </div>
            <div className="cookieMobileDescription">
              We reserve the right to make changes to this Cookie Policy at any time and for any reason. We will alert you about any changes by updating the “Last Updated” date of this Cookie Policy. Any changes or modifications will be effective immediately upon posting the updated Cookie Policy on the Site, and you waive the right to receive specific notice of each such change or modification. 
            </div>
            <div className="cookieMobileDescription">
              You are encouraged to periodically review this Cookie Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Cookie Policy by your continued use of the Site after the date such revised Cookie Policy is posted. 
            </div>
            <div className="cookieMobileSubTitle">USE OF COOKIES</div>
            <div className="cookieMobileDescription">
              A “cookie” is a string of information which assigns you a unique identifier that we store on your computer. Your browser then provides that unique identifier to use each time you submit a query to the Site. We use cookies on the Site to, among other things, keep track of services you have used, record registration information, record your user preferences, keep you logged into the Site, facilitate purchase procedures, and track the pages you visit. Cookies help us understand how the Site is being used and improve your user experience. 
            </div>
            <div className="cookieMobileSubTitle">TYPES OF COOKIES</div>
            <div className="cookieMobileDescription">
              The following types of cookies may be used when you visit the Site:
            </div>
            <div className="cookieMobileSubTitleSub">Advertising Cookies</div>
            <div className="cookieMobileDescription">
              Advertising cookies are placed on your computer by advertisers and ad servers in order to display advertisements and that are most likely to be of interest to you and also to help us correctly track sales back to you so we can award eco-cashback payments. These cookies allow advertisers and ad servers to gather information about your visits to the Site and other websites, alternate the ads sent to a specific computer, and track how often an ad has been viewed and by whom. 
            </div>
            <div className="cookieMobileSubTitleSub">Analytics Cookies</div>
            <div className="cookieMobileDescription">
              Analytics cookies monitor how users reached the Site, and how they interact with and move around once on the Site. These cookies let us know what features on the Site are working the best and what features on the Site can be improved. 
            </div>
            <div className="cookieMobileSubTitleSub">Our Cookies</div>
            <div className="cookieMobileDescription">
              Our cookies are “first-party cookies”, and can be either permanent or temporary. These are necessary cookies, without which the Site won't work properly or be able to provide certain features and functionalities. Some of these may be manually disabled in your browser, but may affect the functionality of the Site.
            </div>
            <div className="cookieMobileSubTitleSub">Personalization Cookies</div>
            <div className="cookieMobileDescription">
              Personalization cookies are used to recognize repeat visitors to the Site. We use these cookies to record your browsing history, the pages you have visited, and your settings and preferences each time you visit the Site. 
            </div>
            <div className="cookieMobileSubTitleSub">Security Cookies</div>
            <div className="cookieMobileDescription">
              Security cookies help identify and prevent security risks. We use these cookies to authenticate users and protect user data from unauthorized parties.
            </div>
            <div className="cookieMobileSubTitleSub">Site Management Cookies</div>
            <div className="cookieMobileDescription">
              Site management cookies are used to maintain your identity or session on the Site so that you are not logged off unexpectedly, and any information you enter is retained from page to page. These cookies cannot be turned off individually, but you can disable all cookies in your browser.
            </div>
            <div className="cookieMobileSubTitleSub">Third-Party Cookies</div>
            <div className="cookieMobileDescription">
              Third-party cookies may be place on your computer when you visit the Site by companies that run certain services we offer. These cookies allow the third parties to gather and track certain information about you. These cookies can be manually disabled in your browser but might disable the ability to track sales and hamper efforts to connect eco-cashback payments to the correct user. 
            </div>
            <div className="cookieMobileSubTitle">CONTROL OF COOKIES</div>
            <div className="cookieMobileDescription">
              Most browsers are set to accept cookies by default. However, you can remove or reject cookies in your browser’s settings. Please be aware that such action could affect the availability and functionality of the Site. 
            </div>
            <div className="cookieMobileDescription">
              For more information on how to control cookies, check your browser or device’s settings for how you can control or reject cookies, or visit the following links:
            </div>
            <div className="cookieMobileDescription">
              <a href="#" className="cookieMobileDescriptionLink">Apple Safari</a>
              <a href="#" className="cookieMobileDescriptionLink">Google Chrome</a>
              <a href="#" className="cookieMobileDescriptionLink">Microsoft Edge</a>
              <a href="#" className="cookieMobileDescriptionLink">Microsoft Internet Explorer</a>
              <a href="#" className="cookieMobileDescriptionLink">Mozilla Firefox</a>
              <a href="#" className="cookieMobileDescriptionLink">Opera</a>
              <a href="#" className="cookieMobileDescriptionLink">Android (Chrome)</a>
              <a href="#" className="cookieMobileDescriptionLink">Blackberry</a>
              <a href="#" className="cookieMobileDescriptionLink">iPhone or iPad (Chrome)</a>
              <a href="#" className="cookieMobileDescriptionLink">iPhone or iPad (Safari)</a>
            </div>
            <div className="cookieMobileDescription">
              In addition, you may opt-out of some third-party cookies through the Network Advertising Initiative’s Opt-Out Tool.
            </div>
            <div className="cookieMobileSubTitle">OTHER TRACKING TECHNOLOGIES</div>
            <div className="cookieMobileDescription">
              In addition to cookies, we may use web beacons, pixel tags, and other tracking technologies on the Site to help customize the Site and improve your experience. A “web beacon” or “pixel tag” is tiny object or image embedded in a web page or email. They are used to track the number of users who have visited particular pages and viewed emails, and acquire other statistical data. They collect only a limited set of data, such as a cookie number, time and date of page or email view, and a description of the page or email on which they reside. Web beacons and pixel tags cannot be declined. However, you can limit their use by controlling the cookies that interact with them.
            </div>
            <div className="cookieMobileSubTitle">PRIVACY POLICY</div>
            <div className="cookieMobileDescription">
              For more information about how we use information collected by cookies and other tracking technologies, please refer to our Privacy Policy [CLICK HERE] posted on the Site. This Cookie Policy is part of and is incorporated into our Privacy Policy. By using the Site, you agree to be bound by this Cookie Policy and our Privacy Policy.
            </div>
            <div className="cookieMobileSubTitle">CONTACT US</div>
            <div className="cookieMobileDescription">
              If you have questions or comments about this Cookie Policy, please contact us at:
            </div>
            <div className="cookieMobileDescription">
              Yamu Ltd<br/>
              61c Rivington St<br/>
              London<br/>
              EC2A 3QQ
            </div>
          </div>
          <div className="cookieMobileStore" style={{marginTop: 262}}>
            <div className="cookieMobileStoreTitle">
              <div className="cookieMobileStoreTitleText">
                Join the revolution and help<br/>repair our planet from the<br/>grassroots up
              </div>
            </div>
            <div className="cookieMobileStoreButtons">
              <AppStoreButton isMobile={true}/>
              <GoogleStoreButton isMobile={true} style={{marginLeft: 10, marginTop: 0}}/>
            </div>
          </div>
          <div className="seperator"/>
          <Footer isMobile={true}/>
        </div>
      </div>
    );
  }

  render = () => {
    if (this.state.screenWidth < 768) {
      return this.renderMobile();
    }

    // body size
    var screenWidth = this.state.screenWidth - this.props.scrollbarWidth;
    var marginTop = BACKGROUND_MARGINTOP / (BACKGROUND_SIZE.width / this.state.screenWidth);
    var screenHeight = SCREEN_HEIGHT * (screenWidth / SCREEN_WIDTH);
    screenHeight = SCREEN_HEIGHT / SCREEN_ASPECTRATIO; //screenWidth / SCREEN_ASPECTRATIO - marginTop;

    // body margin
    var bodyMarginHorizontal = (screenWidth - VIDEO_WIDTH * 4) / 2;

    // participants width
    var contentWidth = (screenWidth - 35 * 2);
    if (contentWidth > 534 + 801) {
        contentWidth = 534 + 801;
    }
    contentWidth -= 142;

    var availableCount = Math.floor((contentWidth + 10) / 50);
    var participantCount = availableCount - 1 + 8;
    var morePariticipantCount = participantCount - availableCount + 1;

    var participants = [];
    for (var i = 0; i < availableCount - 1; i++) {
      participants.push({
        name: "",
      });
    }

    return (
      <div className="App page">
        <Header
          // backgroundImage={backgroundImage}
          // backgroundMarginTop={marginTop}
          screen="cookie"
          screenSize={{
            height: screenWidth / SCREEN_ASPECTRATIO,
            width: screenWidth,
          }}
        />
        <div
          className="body"
          style={{
            backgroundColor: "rgb(0, 95, 176)",
            backgroundImage:`url(${backgroundImage})`,
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            height: screenHeight,
            width: screenWidth,
          }}
        >
          <div className="cookieTitle">Enviromates Cookie Policy</div>
          <div className="cookieBody">
            <div className="cookieContent">
              <div className="cookieContentBody">
                <div className="cookieTextDescription">
                  Enviromates strives to create a transparent and honest narrative with its users and wherever possible to phase out the use of cookies overtime and work towards a new digital future protecting your privacy but helping to repair our planet.
                </div>
                <div className="cookieTextDescription">
                  We have much work to do and we will update our cookie policy as we can.
                </div>
                <div className="cookieTextDescription">
                  Last updated [8th December 2022]
                </div>
                <hr className="hr"/>
                <div className="cookieTextTitle">INTRODUCTION</div>
                <div className="cookieTextDescription">
                  Enviromates (“we” or “us” or “our”) may use cookies, web beacons, tracking pixels, and other tracking technologies when you visit our website http://www.enviromates.earth, including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the “Site”) to help customize the Site and improve your experience. 
                </div>
                <div className="cookieTextDescription">
                  We reserve the right to make changes to this Cookie Policy at any time and for any reason. We will alert you about any changes by updating the “Last Updated” date of this Cookie Policy. Any changes or modifications will be effective immediately upon posting the updated Cookie Policy on the Site, and you waive the right to receive specific notice of each such change or modification. 
                </div>
                <div className="cookieTextDescription">
                  You are encouraged to periodically review this Cookie Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Cookie Policy by your continued use of the Site after the date such revised Cookie Policy is posted. 
                </div>
                <div className="cookieTextTitle">USE OF COOKIES</div>
                <div className="cookieTextDescription">
                  A “cookie” is a string of information which assigns you a unique identifier that we store on your computer. Your browser then provides that unique identifier to use each time you submit a query to the Site. We use cookies on the Site to, among other things, keep track of services you have used, record registration information, record your user preferences, keep you logged into the Site, facilitate purchase procedures, and track the pages you visit. Cookies help us understand how the Site is being used and improve your user experience. 
                </div>
                <div className="cookieTextTitle">TYPES OF COOKIES</div>
                <div className="cookieTextDescription">
                  The following types of cookies may be used when you visit the Site:
                </div>
                <div className="cookieTextSubTitle">Advertising Cookies</div>
                <div className="cookieTextDescription">
                  Advertising cookies are placed on your computer by advertisers and ad servers in order to display advertisements and that are most likely to be of interest to you and also to help us correctly track sales back to you so we can award eco-cashback payments. These cookies allow advertisers and ad servers to gather information about your visits to the Site and other websites, alternate the ads sent to a specific computer, and track how often an ad has been viewed and by whom. 
                </div>
                <div className="cookieTextSubTitle">Analytics Cookies</div>
                <div className="cookieTextDescription">
                  Analytics cookies monitor how users reached the Site, and how they interact with and move around once on the Site. These cookies let us know what features on the Site are working the best and what features on the Site can be improved. 
                </div>
                <div className="cookieTextSubTitle">Our Cookies</div>
                <div className="cookieTextDescription">
                  Our cookies are “first-party cookies”, and can be either permanent or temporary. These are necessary cookies, without which the Site won't work properly or be able to provide certain features and functionalities. Some of these may be manually disabled in your browser, but may affect the functionality of the Site.
                </div>
                <div className="cookieTextSubTitle">Personalization Cookies</div>
                <div className="cookieTextDescription">
                  Personalization cookies are used to recognize repeat visitors to the Site. We use these cookies to record your browsing history, the pages you have visited, and your settings and preferences each time you visit the Site. 
                </div>
                <div className="cookieTextSubTitle">Security Cookies</div>
                <div className="cookieTextDescription">
                  Security cookies help identify and prevent security risks. We use these cookies to authenticate users and protect user data from unauthorized parties.
                </div>
                <div className="cookieTextSubTitle">Site Management Cookies</div>
                <div className="cookieTextDescription">
                  Site management cookies are used to maintain your identity or session on the Site so that you are not logged off unexpectedly, and any information you enter is retained from page to page. These cookies cannot be turned off individually, but you can disable all cookies in your browser.
                </div>
                <div className="cookieTextSubTitle">Third-Party Cookies</div>
                <div className="cookieTextDescription">
                  Third-party cookies may be place on your computer when you visit the Site by companies that run certain services we offer. These cookies allow the third parties to gather and track certain information about you. These cookies can be manually disabled in your browser but might disable the ability to track sales and hamper efforts to connect eco-cashback payments to the correct user. 
                </div>
                <div className="cookieTextTitle">CONTROL OF COOKIES</div>
                <div className="cookieTextDescription">
                  Most browsers are set to accept cookies by default. However, you can remove or reject cookies in your browser’s settings. Please be aware that such action could affect the availability and functionality of the Site. 
                </div>
                <div className="cookieTextDescription">
                  For more information on how to control cookies, check your browser or device’s settings for how you can control or reject cookies, or visit the following links:
                </div>
                <div className="cookieTextDescription">
                  <a href="#" className="cookieTextDescriptionLink">Apple Safari</a>
                  <a href="#" className="cookieTextDescriptionLink">Google Chrome</a>
                  <a href="#" className="cookieTextDescriptionLink">Microsoft Edge</a>
                  <a href="#" className="cookieTextDescriptionLink">Microsoft Internet Explorer</a>
                  <a href="#" className="cookieTextDescriptionLink">Mozilla Firefox</a>
                  <a href="#" className="cookieTextDescriptionLink">Opera</a>
                  <a href="#" className="cookieTextDescriptionLink">Android (Chrome)</a>
                  <a href="#" className="cookieTextDescriptionLink">Blackberry</a>
                  <a href="#" className="cookieTextDescriptionLink">iPhone or iPad (Chrome)</a>
                  <a href="#" className="cookieTextDescriptionLink">iPhone or iPad (Safari)</a>
                </div>
                <div className="cookieTextDescription">
                  In addition, you may opt-out of some third-party cookies through the Network Advertising Initiative’s Opt-Out Tool.
                </div>
                <div className="cookieTextTitle">OTHER TRACKING TECHNOLOGIES</div>
                <div className="cookieTextDescription">
                  In addition to cookies, we may use web beacons, pixel tags, and other tracking technologies on the Site to help customize the Site and improve your experience. A “web beacon” or “pixel tag” is tiny object or image embedded in a web page or email. They are used to track the number of users who have visited particular pages and viewed emails, and acquire other statistical data. They collect only a limited set of data, such as a cookie number, time and date of page or email view, and a description of the page or email on which they reside. Web beacons and pixel tags cannot be declined. However, you can limit their use by controlling the cookies that interact with them.
                </div>
                <div className="cookieTextTitle">PRIVACY POLICY</div>
                <div className="cookieTextDescription">
                  For more information about how we use information collected by cookies and other tracking technologies, please refer to our Privacy Policy [CLICK HERE] posted on the Site. This Cookie Policy is part of and is incorporated into our Privacy Policy. By using the Site, you agree to be bound by this Cookie Policy and our Privacy Policy.
                </div>
                <div className="cookieTextTitle">CONTACT US</div>
                <div className="cookieTextDescription">
                  If you have questions or comments about this Cookie Policy, please contact us at:
                </div>
                <div className="cookieTextDescription">
                  Yamu Ltd<br/>
                  61c Rivington St<br/>
                  London<br/>
                  EC2A 3QQ
                </div>
              </div>
            </div>
            <div style={{flex: 0.4}}/>
          </div>
          <div className="advStore" style={{marginTop: 300}}>
            <div className="advStoreTitle">
              <div className="advStoreTitleText">Join the revolution and help</div>
              <div className="advStoreTitleText">repair our planet from the</div>
              <div className="advStoreTitleText">grassroots up</div>
            </div>
            <div className="advStoreButtons">
              <AppStoreButton/>
              <GoogleStoreButton style={{marginLeft: 20, marginTop: 0}}/>
            </div>
          </div>
          <div className="seperator"/>
          <Footer/>
        </div>
      </div>
    );
  }
}

export default Cookie;
